import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModalStore = defineStore('modal', () => {
  const modals = ref([]);

  const addModal = (component, props = {}, onClose = null) => {
    const id = Date.now();
    modals.value.push({ id, component, props, onClose, show: true });
    return id;
  };

  const removeModal = (id) => {
    modals.value = modals.value.filter((modal) => modal.id !== id);
  };

  const closeModal = (id) => {
    const modal = modals.value.find((modal) => modal.id === id);
    if (modal) {
      modal.show = false;
      setTimeout(() => {
        removeModal(id);
      }, 300); // Match this to the transition duration
    }
  };

  return {
    modals,
    addModal,
    removeModal,
    closeModal,
  };
});
