<template>
  <div class="login-page">
    <div class="login-page__main">
      <div class="login-page__wrap">
        <h2 class="login-page__title">Welcome back!</h2>
        <p class="mt-2">
          Don't have an account?
          <router-link to="/register">Create today!</router-link>
        </p>
        <form @submit.prevent="login" v-if="useAuth" class="login-page__form mt-8">
          <TextInput
            :id="inputs.email.id"
            :label="inputs.email.label"
            v-model="inputs.email.binding"
            type="email"
          />
          <TextInput
            :id="inputs.password.id"
            :label="inputs.password.label"
            v-model="inputs.password.binding"
            type="password"
          />
          <div @click="resetPassword()" class="login-page__forgot-password">Forgot password?</div>
          <Btn
            type="submit"
            :loading="isLoading"
            @click="login"
            variant="secondary"
            icon="user"
            class="width-100 mt-2"
            text="Sign in"
          />
        </form>
      </div>
    </div>
    <div class="login-page__aside">
      <div class="login-page__aside__content">
        <img src="@/assets/img/auth-visual@2x.png" alt="FormFlow" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';
  import { auth } from '@/services/firebase';
  import { sendPasswordResetEmail } from 'firebase/auth';
  import { useUserStore } from '@/store/userStore';

  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    components: {
      TextInput,
      Btn,
    },
    setup() {
      const router = useRouter();
      const userStore = useUserStore();
      const useAuth = import.meta.env.VITE_USE_AUTH === 'true';

      const isLoading = ref(false);
      // Using a composable for validation
      const inputs = ref({
        email: {
          id: 'email',
          label: 'Email address',
          binding: '',
          error: '',
        },
        password: {
          id: 'password',
          label: 'Password title',
          binding: '',
          error: '',
        },
      });

      const login = async () => {
        isLoading.value = true;
        try {
          // const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
          // userStore.user = userCredential.user;
          await userStore.login(inputs.value.email.binding, inputs.value.password.binding);
          console.log(userStore.user);
          router.push('/all-flows');
        } catch (error) {
          isLoading.value = false;
          alert(error.message);
        }
      };

      const resetPassword = async () => {
        try {
          await sendPasswordResetEmail(auth, inputs.value.email.binding);
          alert('Password reset link sent to email.');
        } catch (error) {
          alert(error.message);
        }
      };

      const navigateToFormBuilder = () => {
        router.push('/form-builder');
      };

      return {
        inputs,
        isLoading,
        login,
        resetPassword,
        useAuth,
        navigateToFormBuilder,
        Btn,
        TextInput,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .login-page {
    display: flex;
    min-height: 100vh;
    &__main {
      width: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 100%;
      background-color: #ffffff;
      p a {
        text-decoration: underline;
      }
    }
    &__form {
      max-width: 100%;
      width: 400px;
    }
    &__aside {
      width: 50%;
      min-height: 100%;
      background-color: #092220;
      background: linear-gradient(49.61deg, #092220 46.86%, #1b4743 101.41%);
      padding: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-width: 400px;
      }
    }
    &__forgot-password {
      margin-bottom: 1rem;
      text-align: right;
      cursor: pointer;
    }
  }
</style>
