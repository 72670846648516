<template>
  <div class="automation-setup-modal">
    <TextInput v-model="config.name" label="Integration Name" placeholder="Enter automation name" />
    <ToggleInput
      v-model="config.isActive"
      label="Active"
      id="is-active-toggle"
      :description="`${automation.name} automation is ${config.isActive ? 'active' : 'inactive'}`"
      :disabled="!automation.id"
    />

    <template v-if="automation.type === 'zapier'">
      <div class="form-group">
        <TextInput
          v-model="config.settings.webhook_url"
          label="Webhook URL"
          placeholder="Enter your Zapier webhook URL"
        />
      </div>
    </template>

    <template v-else-if="automation.type === 'webhook'">
      <div class="form-group">
        <TextInput
          v-model="config.settings.webhook_url"
          label="Webhook URL"
          placeholder="Enter your webhook URL"
        />
      </div>
    </template>
    <div class="modal__actions">
      <Btn
        v-if="automation.id"
        text="Delete Integration"
        @click="deleteIntegration"
        variant="danger"
        class="mr-2"
      />
      <Btn
        :text="automation.id ? 'Save Changes' : 'Add Integration'"
        @click="handleSubmit"
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { useModalStore } from '@/store/modalStore';
  import TextInput from '@/components/inputs/TextInput.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'IntegrationSetupModal',

    components: {
      TextInput,
      ToggleInput,
      Btn,
    },

    emits: ['submit', 'delete-automation'],

    props: {
      automation: {
        type: Object,
        required: true,
      },
    },

    setup(props, { emit }) {
      const modalStore = useModalStore();
      const config = ref({
        id: null,
        organizationId: null,
        name: '',
        type: '',
        settings: {},
        isActive: false,
      });

      // Helper function to get default settings based on type
      const getDefaultSettings = (type) => {
        switch (type) {
          case 'zapier':
            return { webhook_url: '' };
          case 'webhook':
            return { webhook_url: '' };
          case 'slack':
            return {
              channel_id: '',
              bot_token: '',
            };
          case 'email':
            return {
              templateId: null,
            };
          default:
            return {};
        }
      };

      watch(
        () => props.automation,
        () => {
          config.value = {
            id: props.automation.id || null,
            organizationId: props.automation.organizationId || null,
            name: props.automation.name || '',
            type: props.automation.type || '',
            settings: {
              ...getDefaultSettings(props.automation.type),
              ...props.automation.settings,
            },
            isActive: props.automation.isActive ?? false,
          };
        },
        { immediate: true }
      );

      const handleSubmit = () => {
        emit('submit', {
          ...config.value,
          settings: { ...config.value.settings },
        });
      };

      const deleteIntegration = () => {
        const modalId = modalStore.addModal(null, {
          title: 'Delete automation',
          description:
            'Are you sure you want to delete this automation? This action cannot be undone.',
          status: 'danger',
          onSubmit: async () => {
            emit('delete-automation', props.automation.id);
            modalStore.closeModal(modalId);
          },
        });
      };

      return {
        config,
        handleSubmit,
        deleteIntegration,
      };
    },
  };
</script>

<style scoped lang="scss">
  .automation-setup-modal {
  }
  .modal__actions {
    display: flex;
    justify-content: space-between;

    .btn:only-child {
      margin-left: auto;
    }
  }
</style>
