<template>
  <div class="submission-view">
    <div class="container">
      <PageHeader
        title="Submissions"
        description="View and manage form submissions in a table or pipeline view"
      />

      <div class="submission-view__controls">
        <Tabs v-model="viewMode">
          <Tab name="table" title="Table View">
            <TableView
              :submissions="submissions"
              :loading="loading"
              @row-click="openSubmissionModal"
            />
          </Tab>
          <Tab name="kanban" title="Pipeline View">
            <KanbanView
              :submissions="groupedSubmissions"
              :statuses="kanbanStatuses"
              @card-click="openSubmissionModal"
              @status-change="handleStatusChange"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import { useModalStore } from '@/store/modalStore';
  import SubmissionDetails from '@/components/crm/submissionDetails.vue';
  import Tabs from '@/components/partials/Tabs/Tabs.vue';
  import Tab from '@/components/partials/Tabs/Tab.vue';
  import TableView from '@/components/crm/TableView.vue';
  import KanbanView from '@/components/crm/KanbanView.vue';
  import PageHeader from '@/components/partials/PageHeader.vue';

  export default {
    components: {
      Tabs,
      Tab,
      TableView,
      KanbanView,
      PageHeader,
    },
    setup() {
      const route = useRoute();
      const formStore = useFormStore();
      const modalStore = useModalStore();
      const formId = route.params.id;

      // State
      const submissions = ref([]);
      const viewMode = ref('kanban');
      const loading = ref(true);

      // Constants
      const viewOptions = [
        { label: 'Table View', value: 'table' },
        { label: 'Pipeline View', value: 'kanban' },
      ];

      const kanbanStatuses = [
        { value: 'new', label: 'New' },
        { value: 'in_progress', label: 'In Progress' },
        { value: 'completed', label: 'Completed' },
      ];

      // Computed
      const groupedSubmissions = computed(() => {
        const groups = {};
        kanbanStatuses.forEach((status) => {
          groups[status.value] =
            status.value === 'new'
              ? submissions.value.filter((sub) => !sub.status || sub.status === 'new')
              : submissions.value.filter((sub) => sub.status === status.value);
        });
        return groups;
      });

      // Methods
      const loadSubmissions = async () => {
        loading.value = true;
        try {
          const response = await formStore.getFormSubmissions(formId);
          submissions.value = Array.isArray(response.submissions) ? response.submissions : [];
        } catch (error) {
          console.error('Failed to load submissions:', error);
          submissions.value = [];
        } finally {
          loading.value = false;
        }
      };

      const openSubmissionModal = (submission) => {
        modalStore.addModal(SubmissionDetails, {
          title: 'Submission Details',
          submission,
        });
      };

      const handleStatusChange = async (submission, newStatus) => {
        // Store old status for rollback if needed
        const oldStatus = submission.status;

        // Update locally first
        submission.status = newStatus;
        try {
          await formStore.updateSubmissionStatus(submission.id, newStatus);
        } catch (error) {
          console.error('Failed to update status:', error);
          // Rollback on error
          submission.status = oldStatus;
          await loadSubmissions();
        }
      };

      onMounted(loadSubmissions);

      return {
        viewMode,
        viewOptions,
        submissions,
        loading,
        kanbanStatuses,
        groupedSubmissions,
        openSubmissionModal,
        handleStatusChange,
      };
    },
  };
</script>

<style scoped lang="scss">
  .submission-view {
    &__controls {
      margin-bottom: 20px;
    }
  }
</style>
