<template>
  <div class="form-card">
    <h2 class="form-card__title">
      <router-link :to="`/flow/${form.id}`">{{ form.name }}</router-link>
    </h2>
    <div class="form-card__subtitle">
      {{ formattedDate }}
    </div>
    <div class="form-card__actions">
      <Btn text="Edit flow" @click="router.push(`/flow/${form.id}`)" variant="secondary" />
      <BaseIcon name="trash" @click="deleteForm" size="12" class="form-card__delete" />
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import { useModalStore } from '@/store/modalStore';
  import useDayJs from '@/utils/useDayJs';
  import Btn from '@/components/partials/Btn.vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  export default {
    name: 'FormCard',
    components: {
      Btn,
      BaseIcon,
    },
    props: {
      form: {
        type: String,
      },
    },
    setup(props) {
      const router = useRouter();
      const dayjs = useDayJs();
      const formattedDate = ref(
        dayjs(
          props.form.createdAt._seconds * 1000 + props.form.createdAt._nanoseconds / 1e6
        ).format('D MMMM YYYY')
      );
      const formStore = useFormStore();
      const modalStore = useModalStore();

      const deleteForm = () => {
        const modalId = modalStore.addModal(null, {
          title: 'Delete flow',
          description: 'Are you sure you want to delete this flow? This action cannot be undone.',
          status: 'danger',
          onSubmit: async () => {
            await formStore.deleteForm(props.form.id);
            modalStore.closeModal(modalId);
          },
        });
      };

      return {
        formattedDate,
        router,
        deleteForm,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .form-card {
    position: relative;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    &__title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
    &__subtitle {
      color: var(--slate-500);
    }
    &__actions {
      display: flex;
      // justify-content: flex-end;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;
    }
    &__delete {
      color: var(--slate-500);
      cursor: pointer;
      @include hover {
        color: var(--red-500);
      }
    }
  }
</style>
