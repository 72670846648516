<template>
  <div class="form-page">
    <div class="form-page__heading">
      <h3>{{ page.title }}</h3>
    </div>
    <div
      v-for="(block, blockIndex) in page.blocks"
      :key="block.id"
      class="form-page__block"
      :class="{ 'form-page__block--active': isBlockActive(blockIndex) }"
      @click="setActiveBlock(blockIndex)"
    >
      <div class="form-page__block-heading">
        <h4 v-if="formSettings?.displayPageTitle">{{ block.title }}</h4>
        <div class="form-page__block-actions">
          <BaseIcon
            name="filter"
            @click.stop="openConditionsModal(block)"
            size="16"
            class="form-page__block-action"
            :class="{ 'form-page__block-action--active': block.conditions?.rules?.length }"
          />
          <BaseIcon
            name="trash"
            @click="removeBlock(blockIndex)"
            size="16"
            class="form-page__block-action"
          />
        </div>
      </div>
      <div v-if="block.conditions?.rules?.length" class="form-page__block-conditions">
        <Message
          :message="`Hidden unless ${block.conditions.operator === 'any' ? 'any' : 'all'} conditions are met`"
        />
      </div>
      <div class="form-page__content">
        <div class="funnel">
          <div
            v-for="(element, elementIndex) in block.elements"
            :key="element.id"
            @click="setActiveElement(blockIndex, elementIndex)"
            class="sf-element"
            :class="{ 'sf-element--active': isElementActive(blockIndex, elementIndex) }"
          >
            <component
              :is="getElementComponent(element.type)"
              v-bind="element.props"
              v-model="element.props.answer"
              @update:modelValue="(value) => handleInput(element, value, blockIndex, elementIndex)"
            ></component>

            <div class="sf-element__actions">
              <BaseIcon
                name="trash"
                @click.stop="removeElement(blockIndex, elementIndex)"
                size="16"
              />
            </div>
          </div>
          <div
            v-if="isBlockActive(blockIndex) && !activeElement"
            class="form-page__element-placeholder"
          >
            <div class="sf-element sf-element--active">
              <p>Select an element from the sidebar to fill</p>
            </div>
          </div>
          <div class="form-page__add" v-else>
            <div class="form-page__add-btn" @click="addNewElement()">
              <BaseIcon name="plus" size="16" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-page__add-block">
      <button class="form-page__add-btn" @click="addNewBlock">
        <BaseIcon name="plus" size="16" />
      </button>
    </div>
  </div>
</template>

<script>
  import { useFormStore } from '@/store/formStore';
  import { computed } from 'vue';
  import { useModalStore } from '@/store/modalStore';
  import BlockConditionsModalForm from '@/components/editor/settings/BlockConditionsModalForm.vue';

  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import EmailElement from '@/components/form/elements/EmailElement.vue';
  import SingleChoiceElement from '@/components/form/elements/SingleChoiceElement.vue';
  import MultiChoiceElement from '@/components/form/elements/MultiChoiceElement.vue';
  import ShortAnswerElement from '@/components/form/elements/ShortAnswerElement.vue';
  import NameElement from '@/components/form/elements/NameElement.vue';
  import NumberElement from '@/components/form/elements/NumberElement.vue';
  import PhoneElement from '@/components/form/elements/PhoneElement.vue';

  import Message from '@/components/partials/Message.vue';

  export default {
    name: 'FormPage',

    components: {
      BaseIcon,
      Btn,
      EmailElement,
      SingleChoiceElement,
      MultiChoiceElement,
      ShortAnswerElement,
      NameElement,
      NumberElement,
      PhoneElement,
      BlockConditionsModalForm,
      Message,
    },

    props: {
      page: {
        type: Object,
        required: true,
      },
      activeElementIndex: {
        type: Number,
        default: null,
      },
      formSettings: {
        type: Object,
        default: null,
      },
    },

    emits: [
      'remove-element',
      'remove-block',
      'set-active-element',
      'add-new-element',
      'add-new-block',
      'edit-element-settings',
      'element-input',
    ],

    setup(props, { emit }) {
      const formStore = useFormStore();
      const modalStore = useModalStore();

      const activeBlockIndex = computed(() => formStore.activeBlockIndex);
      const activePage = computed(() => formStore.activePage);
      const activeElement = computed(() => formStore.activeElement);

      const getElementComponent = (type) => {
        const components = {
          email: EmailElement,
          'single-choice': SingleChoiceElement,
          'multiple-choice': MultiChoiceElement,
          name: NameElement,
          number: NumberElement,
          phone: PhoneElement,
          'short-answer': ShortAnswerElement,
        };
        return components[type] || null;
      };

      const isBlockActive = (blockIndex) => {
        return activePage.value?.id === props.page.id && blockIndex === activeBlockIndex.value;
      };

      const isElementActive = (blockIndex, elementIndex) => {
        return isBlockActive(blockIndex) && elementIndex === props.activeElementIndex;
      };

      const setActiveElement = (blockIndex, elementIndex) => {
        emit('set-active-element', blockIndex, elementIndex);
      };

      const removeElement = (blockIndex, elementIndex) => {
        emit('remove-element', blockIndex, elementIndex);
      };

      const removeBlock = (blockIndex) => {
        emit('remove-block', blockIndex);
      };

      const setActiveBlock = (blockIndex) => {
        formStore.setActiveBlock(blockIndex);
      };

      const addNewElement = (blockIndex) => {
        formStore.unsetElements();
      };

      const addNewBlock = () => {
        emit('add-new-block');
      };

      const handleInput = (element, value, blockIndex, elementIndex) => {
        element.props.answer = value;

        emit('element-input', {
          blockIndex,
          elementIndex,
          element,
          value,
        });

        switch (element.props.logic) {
          case 'next_page':
            // Logic to jump to the next page
            break;
          case 'specific_page':
            // Logic to jump to a specific page based on value
            break;
          case 'no_click_behavior':
          default:
            break;
        }
      };

      const openConditionsModal = (block) => {
        const modalId = modalStore.addModal(BlockConditionsModalForm, {
          title: 'Block visibility conditions',
          description: 'Set conditions for when this block should be shown 🎯',
          block: block,
          size: 'lg',
          onSubmit: async (conditions) => {
            block.conditions = conditions;
            // Save form after updating conditions
            await formStore.saveCurrentForm();
            modalStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Condition errors:', errors);
          },
        });
      };

      return {
        getElementComponent,
        isBlockActive,
        isElementActive,
        setActiveElement,
        activeElement,
        removeElement,
        removeBlock,
        addNewElement,
        addNewBlock,
        handleInput,
        activeBlockIndex,
        setActiveBlock,
        openConditionsModal,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';

  .form-page {
    &__heading {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }

    &__block {
      margin-bottom: 2rem;
      border: 1px solid var(--slate-200);
      border-radius: 0.5rem;
      &--active {
        border-color: var(--brand-500);
        transform: scale(1.025);
      }
      &:not(&--active) {
        cursor: pointer;

        &:hover {
          transform: scale(1.025);
        }
      }
      transition: transform 0.2s ease;
    }

    &__block-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background-color: var(--slate-100);
      border: 1px solid var(--slate-200);
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      transition: transform 0.2s ease;
    }

    &__block-actions {
      display: flex;
      gap: 0.5rem;
    }

    &__block-action {
      cursor: pointer;
      opacity: 0.6;
      @include hover {
        opacity: 1;
      }
      &--active {
        color: var(--brand-600);
        opacity: 1;
        svg {
          fill: var(--brand-600);
        }
      }
    }

    &__content {
      position: relative;
      background-color: #ffffff;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      transition: transform 0.2s ease;
      background-color: var(--slate-50);
      .funnel {
        padding: 1.5rem 0.5rem;
      }
    }

    .sf-element {
      width: 100%;
      padding: 1rem;
      border: 2px dashed var(--slate-100);
      border-radius: 0.5rem;
      position: relative;
      margin-bottom: 1rem;

      @include hover {
        border-color: var(--slate-300);
      }

      &--active {
        border-color: var(--brand-500);
        // border-style: solid;

        @include hover {
          border-color: var(--brand-500);
        }

        .sf-element__actions {
          display: block;
        }
      }

      &__actions {
        position: absolute;
        top: 0;
        right: -0.5rem;
        transform: translateX(100%);
        background-color: var(--white);
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        padding: 1rem;
        display: none;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }

    &__add {
      padding: 1rem;
      display: flex;
      justify-content: center;
    }

    &__add-btn {
      width: 2rem;
      height: 2rem;
      border: 1px solid var(--slate-300);
      background-color: var(--slate-50);
      color: var(--slate-500);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      @include hover {
        background-color: var(--slate-100);
        border-color: var(--slate-400);
        color: var(--slate-700);
      }
    }

    &__add-block {
      margin-top: 2rem;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    &__add-block-btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      background-color: var(--slate-100);
      border-radius: 0.5rem;
      border: 1px solid var(--slate-300);
      cursor: pointer;

      @include hover {
        background-color: var(--slate-200);
      }
    }

    &__add-block-icon {
      opacity: 0.6;
    }

    &__element-placeholder {
      text-align: center;
    }
    &__block-conditions {
      padding: 0.5rem;
      background: #ffffff;
    }
  }
</style>
