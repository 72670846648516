<template>
  <div
    class="integration-card"
    :class="{ 'is-connected': integration.isConnected }"
    @click.stop="$emit('open-modal', integration)"
  >
    <div class="integration-card__content">
      <div class="integration-card__icon">
        <img :src="getIntegrationIcon(integration.type)" :alt="integration.name" />
      </div>
      <div class="integration-card__info">
        <h3 class="integration-card__title">{{ integration.name }}</h3>
      </div>
      <div
        class="integration-card__status"
        v-if="variant === 'global'"
        :class="{ 'integration-card__status--is-active': integration.isActive }"
      >
        {{ integration.isActive ? 'Active' : 'Disabled' }}
      </div>
      <div class="integration-card__toggle" v-else>
        <ToggleInput
          :id="`toggle-integration-${integration.id}`"
          :model-value="isActiveForForm"
          :disabled="isLoading"
          @update:model-value="toggleIntegration"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, ref } from 'vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  export default {
    name: 'IntegrationCard',
    emits: ['toggle'],
    components: {
      ToggleInput,
    },
    props: {
      integration: {
        type: Object,
        required: true,
      },
      variant: {
        type: String,
        default: 'global',
      },
      formId: {
        type: String,
        default: null,
      },
    },
    setup(props, { emit }) {
      const isLoading = ref(false);
      const pendingState = ref(null);

      const isActiveForForm = computed(() => {
        if (pendingState.value !== null) {
          return pendingState.value;
        }
        if (!props.formId) return props.integration.isActive;
        return props.integration.formConnections?.[props.formId]?.enabled ?? false;
      });

      const getIntegrationIcon = (type) => {
        return new URL(`../../assets/img/integrations/${type}.png`, import.meta.url).href;
      };

      const toggleIntegration = async (enabled) => {
        if (isLoading.value) return;

        try {
          // Immediately update UI
          pendingState.value = enabled;
          isLoading.value = true;
          // Emit the toggle event to parent component to handle the API call
          await emit('toggle', enabled);
          pendingState.value = null;
        } catch (error) {
          console.error('Failed to toggle integration:', error);
          pendingState.value = !value;
        } finally {
          isLoading.value = false;
        }
      };

      return { isLoading, toggleIntegration, isActiveForForm, getIntegrationIcon };
    },
  };
</script>

<style scoped lang="scss">
  .integration-card {
    background: white;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__icon {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid var(--slate-200);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 32px;
        height: 32px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__status {
      font-size: 14px;
      padding: 6px 10px;
      border-radius: 2rem;
      color: var(--slate-700);
      background-color: var(--slate-200);
      &--is-active {
        color: var(--green-500);
        background-color: var(--green-200);
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }

    &__info {
      flex: 1;
    }

    &__title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    &__description {
      font-size: 14px;
      color: var(--color-text-secondary);
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__status {
      font-size: 14px;
      color: var(--color-text-secondary);
    }
  }
</style>
