<template>
  <div class="form-viewer">
    <div v-if="form" class="form-viewer__container">
      <div class="funnel" :style="designStyles">
        <h1 v-if="form.settings?.displayFormTitle" class="flow__title">{{ form.name }}</h1>
        <template v-if="!isSubmitted">
          <PageTabs
            v-if="form.settings?.displayPageTabs"
            :pages="form.pages"
            :currentPageIndex="currentPageIndex"
            @update:currentPageIndex="handlePageNavigation"
          />
          <form @submit.prevent="handleSubmit">
            <FormPage
              v-if="form.pages[currentPageIndex]"
              :key="form.pages[currentPageIndex].id"
              :page="form.pages[currentPageIndex]"
              v-model:formData="formData"
              :errors="errors"
              :touched="touched"
              :formSettings="form.settings"
              :currentBlockIndex="currentBlockIndex"
              :isCurrentBlockVisible="isCurrentBlockVisible"
              @field-blur="handleFieldBlur"
            />
            <div class="form-viewer__buttons">
              <Btn
                v-if="currentPageIndex > 0"
                type="button"
                text="Previous"
                variant="secondary"
                @click="previousPage"
              />
              <Btn
                type="button"
                :text="isLastPage ? 'Submit' : 'Next'"
                variant="primary"
                @click="nextPage"
                :disabled="isSubmitting"
                :loading="isSubmitting"
              />
            </div>
          </form>
        </template>
        <template v-else>
          <div class="form-viewer__thank-you">Thank you for submitting the form!</div>
        </template>
      </div>
    </div>
    <div v-else class="form-viewer__loading">Loading form...</div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import { usePublicStore } from '@/store/publicStore';
  import { usePublicFormValidation } from '@/composables/usePublicFormValidation';
  import { useDesignStyles } from '@/composables/useDesignStyles';
  import { useRoute } from 'vue-router';
  import formIntegrationService from '@/services/formIntegrationService';
  import {
    evaluateBlockConditions,
    findNextValidBlockIndex,
    findPreviousValidBlockIndex,
  } from '@/utils/conditionsEvaluator';

  import Btn from '@/components/partials/Btn.vue';
  import FormPage from './FormPage.vue';
  import PageTabs from './partials/PageTabs.vue';

  export default {
    name: 'FormViewer',
    components: {
      Btn,
      FormPage,
      PageTabs,
    },
    props: {
      previewMode: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const publicStore = usePublicStore();

      const route = useRoute();
      const formId = route.params.id;
      const form = ref(null);

      const formData = ref({});
      const currentPageIndex = ref(0);
      const currentBlockIndex = ref(0);

      const isSubmitting = ref(false);
      const isSubmitted = ref(false);
      const { designStyles } = useDesignStyles();

      const { errors, touched, validatePage, validateBlock, markTouched, clearErrors } =
        usePublicFormValidation(form);

      const isLastPage = computed(() => {
        return form.value && currentPageIndex.value === form.value.pages.length - 1;
      });
      const isLastBlock = computed(() => {
        const currentPage = form.value.pages[currentPageIndex.value];
        return currentBlockIndex.value === currentPage.blocks.length - 1;
      });

      const handleFieldBlur = (elementId) => {
        markTouched(elementId);
      };

      const handlePageNavigation = async (newPageIndex) => {
        // Don't allow navigation to invalid indices
        if (newPageIndex < 0 || !form.value || newPageIndex >= form.value.pages.length) {
          return;
        }
        // If moving forward, validate current page
        if (newPageIndex > currentPageIndex.value) {
          if (!validatePage(currentPageIndex.value, formData.value)) {
            return;
          }

          // Track GTM data if enabled and moving forward
          if (!props.previewMode) {
            await formIntegrationService.trackClientEvent('form_page_change', {
              pageIndex: newPageIndex,
              fromPageIndex: currentPageIndex.value,
              toPageIndex: newPageIndex,
            });
          }
        }

        clearErrors();
        currentPageIndex.value = newPageIndex;
      };

      const nextPage = async () => {
        const currentPage = form.value.pages[currentPageIndex.value];
        const currentBlock = currentPage.blocks[currentBlockIndex.value];

        // First validate only the current block
        if (!validateBlock(currentBlockIndex.value, currentPageIndex.value, formData.value)) {
          return;
        }

        // Try to find next valid block in current page
        const nextBlockIndex = findNextValidBlockIndex(
          currentBlockIndex.value,
          currentPage.blocks,
          formData.value
        );
        if (nextBlockIndex !== null) {
          // Found a valid block in current page
          currentBlockIndex.value = nextBlockIndex;
          return;
        }

        // No more valid blocks in current page, validate entire page before moving on
        if (!validatePage(currentPageIndex.value, formData.value)) {
          return;
        }
        // If we're on the last page, submit the form
        if (isLastPage.value) {
          await handleSubmit();
          return;
        }

        // Move to next page
        const nextPageIndex = currentPageIndex.value + 1;
        if (nextPageIndex < form.value.pages.length) {
          // Reset block index and find first valid block in next page
          currentBlockIndex.value = -1; // Will be incremented to 0 by findNextValidBlockIndex
          const nextPage = form.value.pages[nextPageIndex];
          const firstValidBlock = findNextValidBlockIndex(-1, nextPage.blocks, formData.value);

          await handlePageNavigation(nextPageIndex);
          currentBlockIndex.value = firstValidBlock !== null ? firstValidBlock : 0;
        }
      };

      const previousPage = async () => {
        const currentPage = form.value.pages[currentPageIndex.value];

        // Try to find previous valid block in current page
        const prevBlockIndex = findPreviousValidBlockIndex(
          currentBlockIndex.value,
          currentPage.blocks,
          formData.value
        );

        if (prevBlockIndex !== null && prevBlockIndex >= 0) {
          // Found a valid block in current page
          currentBlockIndex.value = prevBlockIndex;
          return;
        }

        // No more valid blocks in current page, move to previous page
        const prevPageIndex = currentPageIndex.value - 1;
        if (prevPageIndex >= 0) {
          await handlePageNavigation(prevPageIndex);

          // Find the last valid block in the previous page
          const prevPage = form.value.pages[prevPageIndex];
          currentBlockIndex.value = prevPage.blocks.length; // Start from after the last block
          const lastValidBlock = findPreviousValidBlockIndex(
            currentBlockIndex.value,
            prevPage.blocks,
            formData.value
          );

          currentBlockIndex.value =
            lastValidBlock !== null ? lastValidBlock : prevPage.blocks.length - 1;
        }
      };

      const isCurrentBlockVisible = computed(() => {
        const currentPage = form.value.pages[currentPageIndex.value];
        const currentBlock = currentPage.blocks[currentBlockIndex.value];
        return evaluateBlockConditions(currentBlock, formData.value);
      });

      const handleSubmit = async () => {
        isSubmitting.value = true;

        if (!validatePage(currentPageIndex.value, formData.value)) {
          return;
        }
        try {
          const response = await publicStore.submitForm(formId, formData.value);
          if (!props.previewMode) {
            // Track submission
            await formIntegrationService.trackClientEvent('form_submission', {
              success: true,
              submissionId: response.data.id,
            });
          }
          if (response.status === 201 && response.data.success) {
            // Handle succesfull submission
            if (form.value?.settings?.submitAction === 'redirect') {
              window.location.href = form.value.settings.redirectUrl;
            } else {
              // TODO: Handle other submit actions
            }
            isSubmitted.value = true;
          } else {
            isSubmitting.value = false;
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          if (!props.previewMode) {
            await formIntegrationService.trackClientEvent('form_submission', {
              success: false,
              error: error.message,
            });
          }
          isSubmitting.value = false;
        }
      };

      onMounted(async () => {
        try {
          form.value = await publicStore.fetchFormById(formId);
          if (form.value) {
            if (!props.previewMode) {
              // Initialize the integrations such as GTM
              await formIntegrationService.initializeClientIntegrations(form.value);

              // Track page view
              await formIntegrationService.trackClientEvent('form_page_view');
            }
            form.value.pages.forEach((page) => {
              page.blocks.forEach((block) => {
                block.elements.forEach((element) => {
                  formData.value[element.id] = '';
                });
              });
            });
          }
        } catch (error) {
          console.error('Error loading form:', error);
        }
      });

      return {
        form,
        formData,
        currentPageIndex,
        currentBlockIndex,
        isLastPage,
        errors,
        touched,
        handleSubmit,
        nextPage,
        previousPage,
        handlePageNavigation,
        handleFieldBlur,
        designStyles,
        isSubmitting,
        isSubmitted,
        isCurrentBlockVisible,
      };
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/form/_designStyles.scss';

  // Keep only the form-viewer related styles
  .form-viewer {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;

    &__container {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &__buttons {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;

      &:has(> :nth-child(2)) {
        justify-content: space-between;
      }
    }

    &__loading {
      text-align: center;
      padding: 2rem;
      font-size: 1.2rem;
      color: #666;
    }
  }
  .funnel {
    padding: 2rem;
    &__title {
      font-size: 2rem;
    }
  }
</style>
