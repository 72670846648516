<template>
  <div class="form-editor">
    <EmptyState
      v-if="!form.pages?.length"
      title="No steps yet"
      description="Get started by adding your first step in the sidebar on the left."
      icon="duotone-page"
    >
      <Btn text="Add step" icon="plus" variant="secondary" @click="$emit('add-page')" />
    </EmptyState>
    <div v-if="activePage" class="form-editor__wrap" :style="designStyles">
      <FormPage
        :page="activePage"
        :activeElementIndex="activeElementIndex"
        @set-active-element="setActiveElement"
        @remove-element="removeElement"
        @add-new-element="addNewElement"
        @add-new-block="addNewBlock"
        @remove-block="removeBlock"
      />
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { useDesignStore } from '@/store/designStore';
  import FormPage from '@/components/editor/FormPage.vue';
  import EmptyState from '@/components/partials/EmptyState.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useDesignStyles } from '@/composables/useDesignStyles';

  export default {
    components: {
      FormPage,
      EmptyState,
      Btn,
    },
    props: {
      activePage: {
        type: Object,
      },
      activeElementIndex: {
        type: Number,
      },
    },
    emits: ['set-active-element', 'remove-element', 'add-page'],
    setup(props, { emit }) {
      const formStore = useFormStore();
      const designStore = useDesignStore();
      const { designStyles } = useDesignStyles();

      const form = computed(() => formStore.currentForm);
      console.log(form.value);

      const setActiveElement = (blockIndex, elementIndex) => {
        emit('set-active-element', blockIndex, elementIndex);
      };
      const removeElement = (blockIndex, elementIndex) => {
        emit('remove-element', blockIndex, elementIndex);
      };
      const addNewElement = () => {
        // Set element index to null so that the sidebar will become visible
        emit('set-active-element', null);
      };

      const addNewBlock = () => {
        formStore.addBlock();
      };
      const removeBlock = (blockIndex) => {
        formStore.removeBlock(blockIndex);
      };
      // const editElementSettings = (page, index) => {
      //   formStore.editElementSettings(page, index);
      // };

      return {
        setActiveElement,
        removeElement,
        addNewElement,
        form,
        designStyles,
        addNewBlock,
        removeBlock,
        // editElementSettings
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-editor {
    flex: 1;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    height: 100%;
    overflow-y: auto;
    &__wrap {
      width: 100%;
      max-width: 800px;
    }
  }

  .form-editor__page {
    margin-bottom: 1rem;
  }
</style>
