<template>
  <router-view />
  <ModalHandler />
</template>

<script>
import ModalHandler from '@/components/modal/ModalHandler.vue';
export default {
  name: 'App',
  components: {
    ModalHandler
  },
  setup() {
  }
};
</script>

<style>
.modal-backdrop-enter-active,
.modal-backdrop-leave-active {
  transition: opacity 0.3s ease;
}

.modal-backdrop-enter,
.modal-backdrop-leave-to {
  opacity: 0;
}

.modal-inner-enter-active,
.modal-inner-leave-active {
  transition: transform 0.3s ease;
}

.modal-inner-enter,
.modal-inner-leave-to {
  transform: translateY(100%);
}
</style>