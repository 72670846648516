<template>
  <div class="automations-view scroll-container">
    <div class="container">
      <PageHeader title="Automations" description="Automate your workflow with integrations">
        <template #actions>
          <Btn variant="primary" @click="createNewAutomation" :loading="isCreating">
            Add Automation
          </Btn>
        </template>
      </PageHeader>
      <!-- <div class="integrations-view__search">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search automations..."
        class="search-input"
      />
    </div> -->
      <EmptyState
        v-if="automations.length === 0"
        title="No automations yet"
        description="Get started by creating your first automation."
        icon="duotone-folders"
      >
        <Btn
          text="Create new automation"
          icon="plus"
          variant="secondary"
          @click="createNewAutomation"
        />
      </EmptyState>
      <div class="automations-view__list">
        <AutomationCard
          v-for="automation in filteredAutomations"
          :key="automation.id"
          :automation="automation"
          @click="router.push(`/automations/${automation.id}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import AutomationCard from '@/components/automations/AutomationCard.vue';
  import AutomationSetupModal from '@/components/automations/AutomationSetupModal.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useAutomationStore } from '@/store/automationStore';
  import { useModalStore } from '@/store/modalStore';
  import { useRouter } from 'vue-router';
  import EmptyState from '@/components/partials/EmptyState.vue';

  export default {
    name: 'AutomationsView',

    components: {
      PageHeader,
      AutomationCard,
      AutomationSetupModal,
      Btn,
      EmptyState,
    },

    setup() {
      const searchQuery = ref('');
      const automationStore = useAutomationStore();
      const isBuilderOpen = ref(false);
      const automations = computed(() => automationStore.automations);
      const currentAutomation = ref(null);
      const isCreating = ref(false);
      const router = useRouter();

      const filteredAutomations = computed(() => {
        if (!searchQuery.value.trim()) return automations.value;

        const query = searchQuery.value.toLowerCase();
        return automations.value.filter((automation) =>
          automation.name.toLowerCase().includes(query)
        );
      });

      // Fetch automations
      const fetchAutomations = async () => {
        try {
          await automationStore.fetchAutomations();
        } catch (error) {
          console.error('Failed to fetch automations:', error);
        }
      };

      const modalStore = useModalStore();
      const createNewAutomation = async () => {
        isCreating.value = true;
        try {
          // Create a new automation instance
          const newAutomation = await automationStore.createAutomation();
          if (newAutomation.status === 201) {
            currentAutomation.value = newAutomation.data.data;
            router.push(`/automations/${newAutomation.data.data.id}`);
          }
        } catch (error) {
          console.error('Failed to create automation:', error);
        } finally {
          isCreating.value = false;
        }
      };

      // Load automations on mount
      onMounted(() => {
        fetchAutomations();
      });

      return {
        searchQuery,
        automations,
        filteredAutomations,
        isBuilderOpen,
        currentAutomation,
        createNewAutomation,
        isCreating,
        router,
      };
    },
  };
</script>

<style scoped lang="scss">
  .automations-view {
    &__search {
      margin-bottom: 24px;

      .search-input {
        width: 100%;
        max-width: 400px;
        padding: 8px 16px;
        border: 1px solid var(--color-border);
        border-radius: 6px;
        font-size: 14px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
    }
  }
</style>
