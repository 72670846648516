<template>
  <div class="design-sidebar">
    <!-- Designs List View -->
    <div v-if="!isEditing" class="designs-list">
      <div class="designs-header">
        <h4 class="section-title">Saved Designs</h4>
      </div>
      <div class="designs-grid">
        <DesignPreset
          v-for="preset in savedDesigns"
          :key="preset.id"
          :preset="preset"
          :isActive="preset.id === currentDesignId"
          @set-active="applyDesign(preset)"
          @edit="editDesign(preset)"
          @delete="deleteDesign(preset.id)"
        />
      </div>
      <Btn
        text="Add new design"
        @click="startNewDesign"
        icon="plus"
        class="mt-4"
        variant="secondary"
      />
    </div>

    <!-- Design Editor View -->
    <div v-else class="design-editor">
      <div class="editor-header">
        <Btn text="Back" @click="cancelEdit" variant="outlined" />
        <Btn :text="saveText" @click="saveDesign" :disabled="!hasUnsavedChanges" />
      </div>

      <div class="section">
        <h4 class="section-title">Name</h4>
        <TextInput id="design-name" label="Name" v-model="design.name" @change="updateDesign" />
      </div>
      <Divider />
      <div class="section">
        <h4 class="section-title">Typography</h4>
        <FontPicker v-model="design.font" :fonts="availableFonts" @change="updateDesign" />
      </div>
      <Divider />
      <div class="section">
        <h3 class="section-title">Colors</h3>
        <div class="color-pickers">
          <div class="color-section">
            <h4 class="mt-2">Background</h4>
            <div class="color-group">
              <ColorPicker
                v-for="(color, key) in design.colors.background"
                :key="key"
                :label="`Background ${key}`"
                v-model="design.colors.background[key]"
                @update:modelValue="updateDesign"
              />
            </div>
          </div>

          <div class="color-section">
            <h4>Button</h4>
            <RadioGroup
              v-model="activeTabs.button"
              :options="[
                { label: 'Default', value: 'default' },
                { label: 'Hover', value: 'hover' },
              ]"
              @update:modelValue="(value) => (activeTabs.button = value)"
            />
            <div class="color-group">
              <ColorPicker
                v-for="(color, colorKey) in design.colors.button[activeTabs.button]"
                :key="colorKey"
                :label="`Button ${activeTabs.button} ${colorKey}`"
                v-model="design.colors.button[activeTabs.button][colorKey]"
                @update:modelValue="updateDesign"
              />
            </div>
          </div>

          <!-- Text Color -->
          <div class="color-section">
            <h4>Text color</h4>
            <div class="color-group">
              <ColorPicker
                label="Heading Color"
                v-model="design.colors.text.heading"
                @update:modelValue="updateDesign"
              />
              <ColorPicker
                label="Body Color"
                v-model="design.colors.text.body"
                @update:modelValue="updateDesign"
              />
            </div>
          </div>

          <!-- Choice Colors -->
          <div class="color-section">
            <h4>Choice</h4>
            <RadioGroup
              v-model="activeTabs.choice"
              :options="[
                { label: 'Default', value: 'default' },
                { label: 'Hover', value: 'hover' },
                { label: 'Active', value: 'active' },
              ]"
              @update:modelValue="(value) => (activeTabs.choice = value)"
            />
            <div class="color-group">
              <ColorPicker
                v-for="(color, colorKey) in design.colors.choice[activeTabs.choice]"
                :key="colorKey"
                :label="`Choice ${activeTabs.choice} ${colorKey}`"
                v-model="design.colors.choice[activeTabs.choice][colorKey]"
                @update:modelValue="updateDesign"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">Border Radius</h3>
        <RadioGroup
          v-model="design.borderRadius"
          :options="borderRadiusOptions"
          @change="updateDesign"
        />
      </div>

      <div class="section">
        <h3 class="section-title">Danger zone 😨</h3>
        <Btn
          text="Delete design"
          @click="deleteDesign"
          variant="danger"
          :loading="isDeleteing"
          :disabled="isDeleting"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, ref, computed, onMounted } from 'vue';
  import { useDesignStore } from '@/store/designStore';
  import { useFormStore } from '@/store/formStore';
  import { getDefaultDesign } from '@/utils/defaultDesign';

  import Btn from '@/components/partials/Btn.vue';
  import FontPicker from '@/components/inputs/FontPicker.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import ColorPicker from '@/components/inputs/ColorPicker.vue';
  import RadioGroup from '@/components/inputs/RadioGroup.vue';
  import DesignPreset from './design/DesignPreset.vue';
  import Divider from '@/components/partials/Divider.vue';

  export default {
    name: 'SidebarDesign',
    components: {
      Btn,
      FontPicker,
      TextInput,
      ColorPicker,
      RadioGroup,
      DesignPreset,
      Divider,
      RadioGroup,
    },

    setup() {
      const designStore = useDesignStore();
      const formStore = useFormStore();

      const isEditing = ref(false);
      const isNew = ref(false);
      const isDeleting = ref(false);

      const savedDesigns = computed(() => designStore.designs);
      const hasUnsavedChanges = computed(() => designStore.hasUnsavedChanges);
      const currentDesignId = computed(() => formStore.currentForm?.designId);

      const saveText = computed(() => {
        if (isNew.value) {
          return 'Create';
        }
        return hasUnsavedChanges.value ? 'Save' : 'Saved';
      });

      onMounted(async () => {
        try {
          await designStore.fetchDesigns();
        } catch (error) {
          console.error('Failed to fetch designs:', error);
        }
      });

      // Initialize design with default values
      const design = reactive(getDefaultDesign());

      const borderRadiusOptions = [
        { label: 'None', value: 'none' },
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'Full', value: 'full' },
      ];

      const activeTabs = reactive({
        button: 'default',
        choice: 'default',
      });

      const startNewDesign = () => {
        // Reset to default design and override specific properties
        Object.assign(design, {
          ...getDefaultDesign(),
          name: `Design ${designStore.designs.length + 1}`,
          category: 'custom',
        });
        isNew.value = true;
        isEditing.value = true;
      };

      const updateDesign = () => {
        designStore.updateDesign(design);
      };
      const editDesign = (preset) => {
        Object.assign(design, preset);
        formStore.setFormDesign(preset.id);
        isEditing.value = true;
      };

      const saveDesign = async () => {
        if (isNew.value) {
          await designStore.createDesign({
            ...design,
          });
        } else {
          await designStore.saveCurrentDesign();
        }
        isNew.value = false;
        isEditing.value = false;
      };

      const cancelEdit = () => {
        isEditing.value = false;
        isNew.value = false;
      };

      const applyDesign = async (preset) => {
        // Update and save the form with the new design
        await formStore.setFormDesign(preset.id);
      };

      const deleteDesign = async () => {
        isDeleting.value = true;
        await designStore.deleteDesign(design.id);
        isEditing.value = false;
        isDeleting.value = false;
      };

      return {
        design,
        borderRadiusOptions,
        savedDesigns,
        currentDesignId,
        isEditing,
        isNew,
        updateDesign,
        startNewDesign,
        editDesign,
        saveDesign,
        cancelEdit,
        applyDesign,
        deleteDesign,
        hasUnsavedChanges,
        saveText,
        isDeleting,
        currentForm: formStore.currentForm,
        activeTabs,
      };
    },
  };
</script>

<style scoped lang="scss">
  .design-sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;

    .design-editor {
      height: 100%;
      overflow-y: auto;
      flex: 1;
    }

    .designs-list {
      height: 100%;
      overflow-y: auto;
      flex: 1;
    }
    .section {
      margin-bottom: 2rem;

      &__title {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--slate-800);
        margin-bottom: 1rem;
      }
      .color-group {
        display: flex;
      }
    }

    .designs-header,
    .editor-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .designs-grid {
      display: grid;
      gap: 1rem;
    }

    .color-pickers {
      display: grid;
      gap: 1rem;
    }

    .btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s;

      &-primary {
        background-color: var(--color-primary);
        color: white;
        border: none;

        &:hover {
          opacity: 0.9;
        }
      }

      &-text {
        background: none;
        border: none;
        color: var(--color-text);

        &:hover {
          color: var(--color-primary);
        }
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
</style>
