export function validateInput(value, rules) {
    const errors = [];
    if(rules) {
        rules.forEach(rule => {
            const [ruleName, ruleValue] = rule.split(':');
            switch (ruleName) {
                case 'required':
                    if (!value) errors.push('This field is required.');
                    break;
                case 'maxLength':
                    if (value.length > parseInt(ruleValue)) errors.push(`Maximum length is ${ruleValue} characters.`);
                    break;
                case 'minLength':
                    if (value.length < parseInt(ruleValue)) errors.push(`Minimum length is ${ruleValue} characters.`);
                    break;
                case 'email':
                    // eslint-disable-next-line no-case-declarations
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailPattern.test(value)) errors.push('Invalid email address.');
                    break;
                case 'min':
                    if (Number(value) < parseInt(ruleValue)) errors.push(`Value must be at least ${ruleValue}.`);
                    break;
                case 'max':
                    if (Number(value) > parseInt(ruleValue)) errors.push(`Value must be no more than ${ruleValue}.`);
                    break;
                case 'requiredCheckbox':
                    if (!Array.isArray(value) || value.length === 0) {
                        errors.push('At least one option must be selected.');
                    }
                    break;
                // Add more cases for different rules as needed.
                default:
                break;
            }
        });
    }
    return errors.length > 0 ? errors.join(' ') : '';
}