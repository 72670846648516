import { ref } from 'vue';
import { evaluateBlockConditions } from '@/utils/conditionsEvaluator';
export function usePublicFormValidation(form) {
  const errors = ref({});
  const touched = ref({});

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateNumber = (value) => {
    return !isNaN(value) && value !== '' && value !== null;
  };

  const validatePhone = (phone) => {
    return /^\+?[\d\s-]{10,}$/.test(phone);
  };

  const validateField = (element, value) => {
    if (element.props.required) {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return 'This field is required';
        }
      } else if (
        !value ||
        (typeof value === 'string' && value.trim() === '') ||
        value.length === 0
      ) {
        return 'This field is required';
      }
    }

    if (value) {
      switch (element.type) {
        case 'email':
          return validateEmail(value) ? '' : 'Please enter a valid email';
        case 'number':
          return validateNumber(value) ? '' : 'Please enter a valid number';
        case 'phone':
          return validatePhone(value) ? '' : 'Please enter a valid phone number';
        case 'single-choice':
          return value ? '' : 'Please select an option';
        case 'multiple-choice':
          return value.length > 0 ? '' : 'Please select at least one option';
        // Add more validations as needed
      }
    }

    return '';
  };

  const validatePage = (pageIndex, formData) => {
    const currentPage = form.value.pages[pageIndex];
    let isValid = true;
    clearErrors();

    currentPage.blocks.forEach((block) => {
      // Skip validation if block has conditions and they evaluate to false
      if (block.conditions && !evaluateBlockConditions(block, formData)) {
        return;
      }

      block.elements.forEach((element) => {
        const value = formData[element.id];
        const error = validateField(element, value);
        if (error) {
          errors.value[element.id] = error;
          isValid = false;
        } else {
          delete errors.value[element.id];
        }
      });
    });

    return isValid;
  };

  const validateBlock = (blockIndex, pageIndex, formData) => {
    const page = form.value.pages[pageIndex];
    const block = page.blocks[blockIndex];

    // Skip validation if block is not visible
    if (!evaluateBlockConditions(block, formData)) {
      return true;
    }

    let isValid = true;

    block.elements.forEach((element) => {
      // Validate only this block's elements
      const elementErrors = validateField(element, formData[element.id]);
      if (elementErrors.length > 0) {
        errors.value[element.id] = elementErrors;
        touched.value[element.id] = true;
        isValid = false;
      }
    });

    return isValid;
  };

  const markTouched = (elementId) => {
    touched.value[elementId] = true;
  };

  const clearErrors = () => {
    errors.value = {};
  };

  return {
    errors,
    touched,
    validatePage,
    validateBlock,
    markTouched,
    clearErrors,
  };
}
