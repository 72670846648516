export const getDefaultDesign = () => ({
  id: null,
  name: '',
  font: 'Inter',
  colors: {
    background: {
      primary: '#E6EBFE',
      secondary: '#300430',
    },
    button: {
      default: {
        background: '#D03734',
        text: '#FFFFFF',
      },
      hover: {
        background: '#D03734',
        text: '#FFFFFF',
      },
    },
    text: {
      heading: '#300430',
      body: '#300430',
    },
    choice: {
      default: {
        background: '#4252AF',
        text: '#FFFFFF',
        border: '#4252AF',
      },
      hover: {
        background: '#4252AF',
        text: '#FFFFFF',
        border: '#4252AF',
      },
      active: {
        background: '#4252AF',
        text: '#FFFFFF',
        border: '#4252AF',
      },
    },
  },
  borderRadius: 'medium',
});
