<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError && touched,
      'form-group--has-success': !localError && touched,
    }"
  >
    <div class="sf-input sf-input--select">
      <label :for="id">{{ label }}</label>
      <select :id="id" :value="modelValue" @change="onChange" @blur="onBlur">
        <option
          v-for="font in fonts"
          :key="font.name"
          :value="font.name"
          :style="{ fontFamily: font.name }"
        >
          {{ font.name }}
        </option>
      </select>
      <span v-if="localError && touched" class="form-group__error">{{ localError }}</span>
    </div>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import { validateInput } from '@/utils/validation';

  // Pre-defined list of fonts with weights
  const FONTS = [
    { name: 'Inter', weights: [400, 500, 600, 700] },
    { name: 'Roboto', weights: [400, 500, 700] },
    { name: 'Open Sans', weights: [400, 600, 700] },
    { name: 'Lato', weights: [400, 700] },
    { name: 'Poppins', weights: [400, 500, 600, 700] },
    { name: 'Montserrat', weights: [400, 500, 600, 700] },
    { name: 'Source Sans Pro', weights: [400, 600, 700] },
    { name: 'Raleway', weights: [400, 500, 600, 700] },
    { name: 'Ubuntu', weights: [400, 500, 700] },
    { name: 'Playfair Display', weights: [400, 500, 600, 700] },
  ];

  export default {
    name: 'FontPicker',
    props: {
      id: {
        type: String,
        default: 'font-picker',
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);
      const fonts = ref(FONTS);

      const loadFonts = () => {
        try {
          // Load font styles for all predefined fonts
          const fontFamilies = fonts.value
            .map((font) => {
              const weights = font.weights.join(',');
              return `${font.name}:${weights}`;
            })
            .join('|');

          const link = document.createElement('link');
          link.href = `https://fonts.googleapis.com/css?family=${fontFamilies}&display=swap`;
          link.rel = 'stylesheet';
          document.head.appendChild(link);
        } catch (error) {
          console.error('Error loading fonts:', error);
          localError.value = 'Failed to load fonts';
        }
      };

      onMounted(() => {
        loadFonts();
      });

      const onChange = (event) => {
        touched.value = true;
        const selectedFont = fonts.value.find((f) => f.name === event.target.value);
        emit('update:modelValue', selectedFont.name);
        if (props.realTimeValidation) {
          validate();
        }
      };

      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        onChange,
        onBlur,
        validate,
        localError,
        touched,
        fonts,
      };
    },
  };
</script>

<style scoped>
  .sf-input--select select option {
    padding: 8px;
    font-size: 16px;
  }
</style>
