<template>
  <div class="insights-page">
    <div class="container">
      <PageHeader title="Insights" description="Insights about your forms"></PageHeader>
    </div>
  </div>
</template>

<script>
  import PageHeader from '@/components/partials/PageHeader.vue';
  export default {
    name: 'InsightsPage',
    components: { PageHeader },
    setup() {
      return {};
    },
  };
</script>

<style scoped lang="scss">
  .insights-page {
    // Your styles here
  }
</style>
