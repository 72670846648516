export const INPUT_FEATURES = {
    DESCRIPTION: 'description',
    LOGIC: 'logic',
    CHOICES: 'choices',
    AMOUNT: 'amount',
    PER_ROW: 'per_row'
  };
  
export const createInputConfig = (props, features = []) => {
    const config = {
      label: {
        id: 'label',
        label: 'Label',
        binding: props.element.props.label || '',
        error: '',
        rules: ['required', 'maxLength:255'],
        touched: false
      },
      required: {
        id: 'required',
        label: 'Answer is required',
        binding: props.element.props.required || false,
        error: '',
        touched: false
      }
    };
  
    // Add optional common features
    features.forEach(feature => {
      switch (feature) {
        case INPUT_FEATURES.DESCRIPTION:
          config.description = {
            id: 'description',
            label: 'Description',
            binding: props.element.props.description || '',
            error: '',
            rules: ['maxLength:500'],
            touched: false
          };
          break;
        case INPUT_FEATURES.PLACEHOLDER:
            config.placeholder = {
              id: 'placeholder',
              label: 'Placeholder Text',
              binding: props.element.props.placeholder || '',
              error: '',
              rules: ['maxLength:255'],
              touched: false
            };
            break;
        case INPUT_FEATURES.LOGIC:
          config.logic = {
            logicEnabled: {
              id: 'logic-enabled',
              label: 'Enable Logic',
              binding: props.element.props.logic?.logicEnabled || false,
              error: '',
              touched: false
            },
            logicType: {
              id: 'logic-type',
              label: 'Logic Type',
              binding: props.element.props.logic?.logicType || 'show_if',
              error: '',
              touched: false
            },
            conditionLogic: {
              id: 'condition-logic',
              label: 'Condition Logic',
              binding: props.element.props.logic?.conditionLogic || 'AND',
              error: '',
              touched: false
            }
          };
          break;
      }
    });
  
    return config;
};