<template>
  <span class="divider"></span>
</template>

<script>
  export default {
    name: 'Divider',
  };
</script>

<style scoped>
  .divider {
    display: block;
    height: 1px;
    background-color: var(--slate-200);
    margin: 1rem 0;
  }
</style>
