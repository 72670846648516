<template>
  <div class="form-block">
    <h2 v-if="formSettings?.displayBlockTitle" class="form-block__title">{{ block.title }}</h2>
    <FormElement
      v-for="element in block.elements"
      :key="element.id"
      :element="element"
      :formData="formData"
      :errors="errors[element.id]"
      @update:formData="$emit('update:formData', $event)"
    />
  </div>
</template>

<script>
  import FormElement from './FormElement.vue';

  export default {
    name: 'FormBlock',
    components: {
      FormElement,
    },
    props: {
      block: {
        type: Object,
        required: true,
      },
      formData: {
        type: Object,
        required: true,
      },
      formSettings: {
        type: Object,
        default: null,
      },
      errors: {
        type: Array,
        default: () => ({}),
      },
    },
    emits: ['update:formData'],
  };
</script>

<style scoped lang="scss">
  .form-block {
    margin-bottom: 1.5rem;
  }
</style>
