<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError && touched,
      'form-group--has-success': !localError && touched,
    }"
  >
    <div class="sf-input sf-input--radio">
      <label v-if="label" class="radio-group__label">{{ label }}</label>
      <div class="radio-group">
        <div class="radio-group__options">
          <div
            v-for="option in options"
            :key="option.value"
            class="radio-group__option"
            :class="{ 'radio-group__option--checked': modelValue === option.value }"
          >
            <label :for="`${id}-${option.value}`">
              <input
                type="radio"
                :id="`${id}-${option.value}`"
                :name="id"
                :value="option.value"
                :checked="modelValue === option.value"
                @change="onChange($event, option)"
                @blur="onBlur"
              />
              <span>{{ option.label }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <span v-if="localError && touched" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { validateInput } from '@/utils/validation';

  export default {
    name: 'RadioGroup',

    props: {
      id: {
        type: String,
        default: 'radio-group',
      },
      label: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
        validator: (value) => {
          return value.every(
            (option) => option.hasOwnProperty('label') && option.hasOwnProperty('value')
          );
        },
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update:modelValue', 'validate', 'change'],

    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const onChange = (event, option) => {
        touched.value = true;
        emit('update:modelValue', event.target.value);
        emit('change', option);
        if (props.realTimeValidation) {
          validate();
        }
      };

      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        onChange,
        onBlur,
        validate,
        localError,
        touched,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .radio-group {
    background-color: var(--slate-100);
    border-radius: 0.25rem;
    padding: 0.125rem;
    &__label {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--color-text);
      margin-bottom: 0.5rem;
    }

    &__options {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: 1fr;
      // grid-column-gap: 6px;
      // grid-row-gap: 6px;
      display: flex;
    }

    &__option {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      border-radius: 0.25rem;

      text-align: center;
      width: 100%;
      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        text-align: center;

        input[type='radio'] {
          appearance: none;
          width: 1.25rem;
          height: 1.25rem;
          border: 2px solid var(--color-border);
          border-radius: 50%;
          margin: 0;
          position: relative;
          cursor: pointer;
          position: absolute;
          opacity: 0;
          &:checked {
            border-color: var(--color-primary);

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 0.625rem;
              height: 0.625rem;
              background-color: var(--color-primary);
              border-radius: 50%;
            }
          }

          &:focus {
            outline: none;
            border-color: var(--color-primary);
            box-shadow: 0 0 0 2px var(--color-primary-light);
          }
        }

        span {
          font-size: 0.875rem;
          color: var(--color-text);
        }
      }
      &--checked {
        background-color: #ffffff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .form-group {
    margin-bottom: 1rem;

    &__error {
      display: block;
      color: var(--color-error);
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }

    &--has-error {
      .radio-group__option input[type='radio'] {
        border-color: var(--color-error);
      }
    }

    &--has-success {
      .radio-group__option input[type='radio'] {
        border-color: var(--color-success);
      }
    }
  }
</style>
