<template>
  <div class="page-tabs">
    <div class="page-tabs__container">
      <button
        v-for="(page, index) in pages"
        :key="index"
        class="page-tabs__tab"
        :class="{ 'page-tabs__tab--active': index === currentPageIndex }"
        @click="$emit('update:currentPageIndex', index)"
      >
        <span class="page-tabs__number">{{ index + 1 }}</span>
        <span class="page-tabs__title">{{ page.title }}</span>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageTabs',

    props: {
      pages: {
        type: Array,
        required: true,
      },
      currentPageIndex: {
        type: Number,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-tabs {
    width: 100%;
    margin-bottom: 1.5rem;

    &__container {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      padding: 0.25rem;
      justify-content: space-around;
    }

    &__tab {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      border: 1px solid var(--color-border);
      border-radius: 0.5rem;
      background: var(--color-background);
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: var(--color-background-hover);
      }

      &--active {
        .page-tabs__number {
          background: var(--brand-500);
          color: var(--white);
        }
        .page-tabs__title {
          color: var(--brand-500);
        }
      }
    }

    &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: var(--slate-200);
      color: var(--slate-800);
      font-size: 0.875rem;
    }

    &__title {
      font-size: 0.875rem;
      font-weight: 500;
      white-space: nowrap;
    }
  }
</style>
