/**
 * Evaluates a single condition based on the operation type
 */
const evaluateCondition = (condition, formData) => {
  const elementValue = formData[condition.elementId];
  const compareValue = condition.value;
  switch (condition.operation) {
    case 'equals':
      return Array.isArray(elementValue)
        ? elementValue.includes(compareValue)
        : elementValue === compareValue;
    case 'not_equals':
      return Array.isArray(elementValue)
        ? !elementValue.includes(compareValue)
        : elementValue !== compareValue;
    case 'contains':
      return Array.isArray(elementValue)
        ? elementValue.some((val) => String(val).includes(String(compareValue)))
        : String(elementValue).includes(String(compareValue));
    case 'greater_than':
      return Number(elementValue) > Number(compareValue);
    case 'less_than':
      return Number(elementValue) < Number(compareValue);
    default:
      return true;
  }
};

/**
 * Evaluates all conditions for a block based on operator type (any/all)
 */
export const evaluateBlockConditions = (block, formData) => {
  // Add safety check for undefined block
  if (!block) {
    return true;
  }

  // If no conditions exist, the block is always visible
  if (!block.conditions?.rules?.length) {
    return true;
  }

  const { operator, rules } = block.conditions;

  // Evaluate based on operator type
  return operator === 'any'
    ? rules.some((rule) => evaluateCondition(rule, formData))
    : rules.every((rule) => evaluateCondition(rule, formData));
};

/**
 * Finds the next valid block index based on conditions
 */
export const findNextValidBlockIndex = (currentIndex, blocks, formData) => {
  let nextIndex = currentIndex + 1;

  // Check remaining blocks in the current page
  while (nextIndex < blocks.length) {
    // A block is valid if it has no conditions or its conditions are met
    if (!blocks[nextIndex].conditions || evaluateBlockConditions(blocks[nextIndex], formData)) {
      return nextIndex;
    }
    nextIndex++;
  }

  // Return null if no valid blocks are found
  return null;
};

/**
 * Finds the previous valid block index based on conditions
 */
export const findPreviousValidBlockIndex = (currentIndex, blocks, formData) => {
  let prevIndex = currentIndex - 1;

  while (prevIndex >= 0) {
    if (evaluateBlockConditions(blocks[prevIndex], formData)) {
      return prevIndex;
    }
    prevIndex--;
  }

  return prevIndex;
};
