<template>
  <div class="automations-editor">
    <div class="container">
      <PageHeader title="Automation editor" description="Edit your automation">
        <template #actions>
          <Btn
            :disabled="!hasUnsavedChanges"
            @click="handleSaveAutomation"
            text="Save automation"
          />
        </template>
      </PageHeader>
    </div>
    <div class="automations-editor__view">
      <!-- v-if="isBuilderOpen" -->
      <AutomationBuilder
        v-if="currentAutomation"
        v-model="currentAutomation"
        @save="handleSaveAutomation"
        @cancel="closeBuilder"
      />
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useAutomationStore } from '@/store/automationStore';
  import AutomationBuilder from '@/components/automations/AutomationBuilder.vue';
  import { useRoute } from 'vue-router';

  export default {
    name: 'AutomationsView',

    components: {
      PageHeader,
      Btn,
      AutomationBuilder,
    },

    setup() {
      const automationStore = useAutomationStore();
      const originalAutomation = ref(null);

      const automation = computed(() => automationStore.currentAutomation);
      const currentAutomation = ref({
        name: '',
        trigger: null,
        steps: {},
        isActive: false,
      });
      const isSaving = ref(false);
      const route = useRoute();
      const automationId = computed(() => route.params.id);

      const hasUnsavedChanges = computed(() => {
        if (!originalAutomation.value) return false;
        return JSON.stringify(currentAutomation.value) !== JSON.stringify(originalAutomation.value);
      });

      const handleSaveAutomation = async () => {
        try {
          await automationStore.updateAutomation(currentAutomation.value);
          console.log('saved');
        } catch (error) {
          console.error('Failed to save automation:', error);
        }
      };

      // Load automations on mount
      onMounted(async () => {
        if (automationId.value) {
          const response = await automationStore.fetchAutomation(automationId.value);
          if (response) {
            currentAutomation.value = response;
            originalAutomation.value = response;
          }
        }
      });

      return {
        automation,
        handleSaveAutomation,
        isSaving,
        currentAutomation,
        originalAutomation,
        hasUnsavedChanges,
      };
    },
  };
</script>

<style scoped lang="scss">
  .automations-editor {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__view {
      flex-grow: 1;
      overflow: hidden;
    }
  }
</style>
