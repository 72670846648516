import { useIntegrationStore } from '@/store/integrationStore';
import { usePublicStore } from '@/store/publicStore';

const INTEGRATION_CATEGORIES = {
  analytics: ['google_tag_manager', 'facebook_pixel', 'linkedin_tag'],
  serverSide: ['webhook', 'email', 'zapier'],
};

class FormIntegrationService {
  static getActiveIntegrations(type = null) {
    const publicStore = usePublicStore();
    const integrations = publicStore.formIntegrations;

    if (type) {
      return integrations.filter((integration) =>
        INTEGRATION_CATEGORIES[type].includes(integration.type)
      );
    }

    return integrations;
  }

  static async initializeClientIntegrations(form) {
    // Cleanup any existing integrations
    this.cleanupIntegrations();

    const analyticsIntegrations = this.getActiveIntegrations('analytics');

    for (const integration of analyticsIntegrations) {
      await this.initializeAnalytics(integration);
    }
  }

  static async initializeAnalytics(integration) {
    const publicStore = usePublicStore();

    switch (integration.type) {
      case 'google_tag_manager':
        if (integration.settings?.containerId) {
          // Initialize GTM if not already initialized
          if (!window.dataLayer) {
            const gtmScript = document.createElement('script');
            gtmScript.innerHTML = `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${integration.settings.containerId}');
            `;
            document.head.appendChild(gtmScript);

            // Add noscript iframe
            const noscriptContainer = document.createElement('noscript');
            const gtmIframe = document.createElement('iframe');
            gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${integration.settings.containerId}`;
            gtmIframe.height = '0';
            gtmIframe.width = '0';
            gtmIframe.style.display = 'none';
            gtmIframe.style.visibility = 'hidden';

            noscriptContainer.appendChild(gtmIframe);
            document.body.insertBefore(noscriptContainer, document.body.firstChild);
          }
        }
        break;

      case 'facebook_pixel':
        if (integration.settings?.pixelId) {
          // Initialize Facebook Pixel if not already initialized
          if (!window.fbq) {
            const fbScript = document.createElement('script');
            fbScript.innerHTML = `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${integration.settings.pixelId}');
            `;
            document.head.appendChild(fbScript);
          }
        }
        break;

      case 'linkedin_tag':
        if (integration.settings?.partnerId) {
          // Initialize LinkedIn Tag if not already initialized
          if (!window._linkedin_data_partner_id) {
            const liScript = document.createElement('script');
            liScript.innerHTML = `
              _linkedin_partner_id = "${integration.settings.partnerId}";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            `;
            document.head.appendChild(liScript);

            const liScriptSrc = document.createElement('script');
            liScriptSrc.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
            document.body.appendChild(liScriptSrc);
          }
        }
        break;
    }
  }

  static async trackClientEvent(eventName, data = {}) {
    const analyticsIntegrations = this.getActiveIntegrations('analytics');

    for (const integration of analyticsIntegrations) {
      await this.trackAnalyticsEvent(integration, eventName, data);
    }
  }

  static async trackAnalyticsEvent(integration, eventName, data) {
    const publicStore = usePublicStore();
    const form = publicStore.currentForm;

    const eventData = {
      event: eventName,
      formId: form.id,
      formName: form.name,
      ...data,
    };

    switch (integration.type) {
      case 'google_tag_manager':
        if (window.dataLayer) {
          window.dataLayer.push(eventData);
        }
        break;

      case 'facebook_pixel':
        if (window.fbq) {
          window.fbq('track', eventName, eventData);
        }
        break;

      case 'linkedin_tag':
        if (window.lintrk) {
          window.lintrk('track', eventData);
        }
        break;
    }
  }

  static cleanupIntegrations() {
    // Remove GTM noscript iframe if it exists
    const gtmIframe = document.querySelector('noscript iframe[src*="googletagmanager"]');
    if (gtmIframe) {
      gtmIframe.parentElement.remove();
    }
  }

  // TODO: Possibility to trigger server integrations form client side
  //   static async triggerServerIntegrations(form, eventName, data) {
  //     try {
  //       const response = await fetch('/api/integrations/trigger', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           formId: form.id,
  //           eventName,
  //           data,
  //         }),
  //       });
  //       return await response.json();
  //     } catch (error) {
  //       console.error('Failed to trigger server integrations:', error);
  //     }
  //   }
}

export default FormIntegrationService;
