<template>
  <div class="toolbar">
    <div class="toolbar__inner">
      <h2 class="toolbar__title">
        {{ formName }}
      </h2>
      <nav class="toolbar__nav">
        <ul class="toolbar__nav-list">
          <li class="toolbar__link">
            <router-link :to="{ name: 'Edit', params: { id: $route.params.id } }">
              <BaseIcon name="edit" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link">
            <router-link :to="{ name: 'Settings', params: { id: $route.params.id } }">
              <BaseIcon name="settings" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link">
            <router-link :to="{ name: 'FormIntegrations', params: { id: $route.params.id } }">
              <BaseIcon name="cubes" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link">
            <router-link :to="{ name: 'Submissions', params: { id: $route.params.id } }">
              <BaseIcon name="crm" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link toolbar__link--has-btn">
            <Btn
              :text="form.published ? 'Published' : 'Publish'"
              icon="zap"
              @click="openPublishModal"
              :variant="form.published ? 'success' : 'primary'"
            />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
  import { useFormStore } from '@/store/formStore';
  import { computed } from 'vue';
  // import { useRouter } from 'vue-router';
  import PublishFunnelForm from '@/components/editor/PublishFunnelForm.vue';
  import { useModalStore } from '@/store/modalStore';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  export default {
    name: 'EditorToolbar',
    props: {
      formName: {
        type: String,
        default: '',
      },
    },
    components: {
      BaseIcon,
      Btn,
    },
    setup() {
      const formStore = useFormStore();
      const form = computed(() => formStore.currentForm);
      const modalStore = useModalStore();
      const openPublishModal = () => {
        const modalId = modalStore.addModal(PublishFunnelForm, {
          title: 'Get ready to publish your flow',
          description: 'Choose how you would like to publish your flow?',
          size: 'lg',
          onSubmit: async (data) => {
            modalStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      };

      return {
        openPublishModal,
        form,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .toolbar {
    width: 100%;
    position: relative;
    padding: 0 1rem;
    background-color: var(--white);
    &__title {
    }
    &__inner {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__nav {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &__nav-list {
      display: flex;
      align-items: center;
      gap: 1rem;
      li {
        a {
          padding: 7px 10px;
          border-radius: 0.5rem;
          border: 1px solid transparent;
          color: var(--slate-500);
          display: flex;
          align-items: center;
          gap: 0.5rem;
          @include hover {
            cursor: pointer;
            background-color: var(--slate-50);
          }
          &.router-link-exact-active {
            background-color: var(--slate-100);
            border-color: var(--slate-200);
            color: var(--slate-900);
          }
        }
        @include fontSize('md');
        font-weight: 500;
      }
    }
  }
</style>
