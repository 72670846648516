<template>
  <div class="create-funnel-form">
    <form @submit.prevent="handleSubmit">
      <TextInput
        :id="inputs.flow_title.id"
        :label="inputs.flow_title.label"
        v-model="inputs.flow_title.binding"
        :error="inputs.flow_title.error"
        :rules="inputs.flow_title.rules"
        @validate="onValidate('flow_title', $event)"
      />
      <span v-if="!isUsingAi" class="create-funnel-form__ai-toggle" @click="isUsingAi = true">
        Use AI
      </span>
      <TextAreaInput
        v-if="isUsingAi"
        class="mt-2"
        :id="inputs.description.id"
        label="Description"
        v-model="inputs.description.binding"
        :error="inputs.description.error"
        :rules="inputs.description.rules"
        :realTimeValidation="true"
        :placeholder="inputs.description.placeholder"
        @validate="onValidate('description', $event)"
      />
      <div class="create-funnel-form__buttons">
        <Btn type="submit" text="Let's go!" :loading="isLoading" variant="secondary" />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import Btn from '@/components/partials/Btn.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
  // import { validateText } from '@/utils/validation';
  import { useValidation } from '@/composables/useValidation';
  import { useFormStore } from '@/store/formStore';
  import { useRouter } from 'vue-router';

  export default {
    components: {
      Btn,
      TextInput,
      TextAreaInput,
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const formStore = useFormStore();
      const router = useRouter();
      const isUsingAi = ref(false);
      const isLoading = ref(false);
      // Using a composable for validation
      const { inputs, validateForm, onValidate } = useValidation({
        flow_title: {
          id: 'flow_title',
          label: 'Flow title',
          binding: '',
          error: '',
          rules: ['required', 'maxLength:255'],
        },
        description: {
          id: 'description',
          label: 'Description',
          placeholder:
            'This form collects feedback from customers about their recent experiences and gathers their contact details.',
          binding: '',
          error: '',
          rules: ['required'],
          conditionalValidation: () => isUsingAi.value,
        },
      });

      const handleSubmit = async () => {
        isLoading.value = true;
        const allFieldsValid = validateForm();

        if (allFieldsValid) {
          const formData = Object.keys(inputs.value).reduce((acc, key) => {
            acc[key] = inputs.value[key].binding;
            return acc;
          }, {});

          let createForm;
          if (isUsingAi.value) {
            createForm = await formStore.createFormWithAI(formData);
          } else {
            createForm = await formStore.createForm(formData);
          }

          router.push(`/flow/${createForm.data.id}`);
          props.onSubmit(formData);
        } else {
          const errors = Object.keys(inputs.value).reduce((acc, key) => {
            if (inputs.value[key].error) {
              acc[key] = inputs.value[key].error;
            }
            return acc;
          }, {});
          console.log('Form validation errors:', errors);
          props.onError(errors);
        }
        isLoading.value = false;
      };
      return {
        inputs,
        onValidate,
        handleSubmit,
        isLoading,
        isUsingAi,
      };
    },
  };
</script>

<style scoped lang="scss">
  .create-funnel-form {
    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
    &__ai-toggle {
      cursor: pointer;
      color: var(--brand-500);
      font-size: 0.75rem;
    }
  }
</style>
