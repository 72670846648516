<template>
  <div class="pages-editor">
    <draggable v-model="form.pages" @end="onDragEnd" item-key="id" :animation="200" class="pages">
      <template #item="{ element, index }">
        <transition-group name="list" tag="div">
          <div
            :key="element.id"
            class="page-item"
            :class="{ ['page-item--active']: activePageIndex === index }"
            @click="setActivePage(index)"
          >
            {{ element.title }}
            <div class="page-item__actions">
              <Btn
                @click="editPageSettings(index)"
                variant="plain"
                icon="edit"
                class="page-item__action"
              />
              <Btn variant="plain" icon="bars" class="page-item__action page-item__action--drag" />
            </div>
          </div>
        </transition-group>
      </template>
    </draggable>
    <Btn text="Add step" @click="addPage" class="mt-4" variant="secondary" />
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { useModalStore } from '@/store/modalStore';

  import draggable from 'vuedraggable';
  import Btn from '@/components/partials/Btn.vue';
  import PageSettingsModalForm from '@/components/editor/settings/PageSettingsModalForm.vue';

  export default {
    name: 'SidebarPages',
    components: {
      Btn,
      draggable,
    },
    props: {
      activePageIndex: {
        type: Number,
      },
    },
    emits: ['addPage', 'removePage', 'editPageSettings', 'setActivePage'],
    setup(props, { emit }) {
      const formStore = useFormStore();
      const modalStore = useModalStore();

      const form = computed(() => formStore.currentForm);

      const addPage = async () => {
        emit('add-page');
      };

      const editPageSettings = (index) => {
        const modalId = modalStore.addModal(PageSettingsModalForm, {
          title: 'Edit the step settings',
          pageIndex: index,
          onSubmit: async () => {
            modalStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      };

      const setActivePage = (index) => {
        emit('set-active-page', index);
      };

      // const setActivePage = (index) => emit('set-active-page', index);

      const onDragEnd = async () => {
        await formStore.saveCurrentForm();
      };

      return {
        form,
        addPage,
        // removePage,
        editPageSettings,
        onDragEnd,
        setActivePage,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .page-sidebar {
    width: 320px;
    padding: 1rem;
    background: var(--white);
    height: 100%;
    overflow-y: auto;
    .pages {
      .page-item {
        padding: 7px 10px;
        border-radius: 0.5rem;
        border: 1px solid transparent;
        color: var(--slate-500);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        margin: 0.25rem 0;
        @include hover {
          cursor: pointer;
          background-color: var(--slate-50);
        }
        &--active {
          background-color: var(--slate-100);
          border-color: var(--slate-200);
          color: var(--slate-900);
        }

        &__actions {
          display: flex;
        }
        &__action {
          &--drag {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            &:active {
              cursor: grabbing;
              cursor: -moz-grabbing;
              cursor: -webkit-grabbing;
            }
          }
        }
      }
    }
  }
</style>
