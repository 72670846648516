<template>
  <div class="publish-funnel-form">
    <form @submit.prevent="handleSubmit">
      <div class="publish-funnel-form__placement">
        <label>Placement method</label>
        <div class="publish-funnel-form__options">
          <div
            class="publish-funnel-form__option"
            :class="{
              active: selectedPlacement === 'corner',
              'publish-funnel-form__option--disabled': form.published,
            }"
            @click="selectPlacement('corner')"
            v-tippy="form.published ? 'First unpublish the form to make changes' : ''"
          >
            <img
              src="@/assets/img/corner.png"
              alt="Corner placement icon"
              class="publish-funnel-form__option-img"
            />
            <span>Corner</span>
          </div>
          <div
            class="publish-funnel-form__option"
            :class="{
              active: selectedPlacement === 'modal',
              'publish-funnel-form__option--disabled': form.published,
            }"
            @click="selectPlacement('modal')"
            v-tippy="form.published ? 'First unpublish the form to make changes' : ''"
          >
            <img
              src="@/assets/img/modal.png"
              alt="Modal placement icon"
              class="publish-funnel-form__option-img"
            />
            <span>Modal</span>
          </div>
          <div
            class="publish-funnel-form__option"
            :class="{
              active: selectedPlacement === 'inline',
              'publish-funnel-form__option--disabled': form.published,
            }"
            @click="selectPlacement('inline')"
            v-tippy="{ content: form.published ? 'First unpublish the form to make changes' : '' }"
          >
            <img
              src="@/assets/img/inline.png"
              alt="Inline placement icon"
              class="publish-funnel-form__option-img"
            />
            <span>Inline</span>
          </div>
        </div>
      </div>

      <div class="publish-funnel-form__link">
        <label>Your form link</label>
        <div class="publish-funnel-form__copy">
          <input
            type="text"
            readonly
            :value="form.published ? formLink : ''"
            :placeholder="form.published ? '' : 'Publish your form to get a shareable link'"
            ref="linkInput"
          />
          <Btn
            icon="copy"
            text="Copy"
            @click.prevent="copyLink"
            variant="secondary"
            :disabled="!form.published"
          />
        </div>
      </div>

      <div class="publish-funnel-form__buttons">
        <Btn
          type="submit"
          :text="form.published ? 'Unpublish' : 'Publish'"
          :loading="isLoading"
          :variant="form.published ? 'secondary' : 'success'"
          icon="zap"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import Btn from '@/components/partials/Btn.vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import { useFormStore } from '@/store/formStore';

  export default {
    components: {
      Btn,
      BaseIcon,
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const route = useRoute();
      const formStore = useFormStore();
      const form = computed(() => formStore.currentForm);

      const isLoading = ref(false);
      const copied = ref(false);
      const linkInput = ref(null);
      const selectedPlacement = ref(form.value?.settings?.placement || 'corner');

      const formLink = computed(() => {
        return `${window.location.origin}/funnel/${route.params.id}`;
      });

      const copyLink = () => {
        linkInput.value.select();
        document.execCommand('copy');
        copied.value = true;
        setTimeout(() => {
          copied.value = false;
        }, 2000);
      };

      const selectPlacement = (placement) => {
        if (form.value.published) {
          return;
        }
        selectedPlacement.value = placement;
      };

      const handleSubmit = async () => {
        isLoading.value = true;
        try {
          const updatedForm = {
            ...form.value,
            published: !form.value.published,
            settings: {
              ...form.value.settings,
              placement: selectedPlacement.value,
            },
          };
          const updateForm = await formStore.publishForm(updatedForm);
          form.value = updatedForm;
        } catch (error) {
          props.onError(error);
        }
        isLoading.value = false;
      };

      return {
        form,
        isLoading,
        copied,
        linkInput,
        formLink,
        selectedPlacement,
        copyLink,
        handleSubmit,
        selectPlacement,
      };
    },
  };
</script>

<style scoped lang="scss">
  .publish-funnel-form {
    &__placement {
      margin-bottom: 2rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
    }

    &__options {
      display: flex;
      gap: 1rem;
    }

    &__option {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px solid var(--slate-200);
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.2s;
      background-color: var(--slate-100);

      &:hover {
        border-color: var(--slate-300);
      }

      &.active {
        border-color: var(--brand-500);
        background-color: var(--slate-50);
      }

      span {
        font-size: 0.875rem;
      }
      &--disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    &__option-img {
      width: 100%;
      height: auto;
    }

    &__link {
      margin-bottom: 2rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
    }

    &__copy {
      display: flex;
      gap: 0.5rem;

      input {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid var(--slate-200);
        border-radius: 0.375rem;
        font-size: 0.875rem;
      }
    }

    &__buttons {
      display: flex;
    }
  }
</style>
