<template>
  <div class="select-integration-modal">
    <div class="integration-options">
      <div
        v-for="option in options"
        :key="option.id"
        class="integration-option"
        @click="selectOption(option)"
      >
        <div class="integration-option__icon">
          <img :src="getIntegrationIcon(option.type)" :alt="option.name" />
        </div>
        <h3>{{ option.name }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectIntegrationModal',
    props: {
      options: {
        type: Array,
        required: true,
      },
    },
    setup(props, { emit }) {
      const getIntegrationIcon = (type) => {
        return new URL(`../../assets/img/integrations/${type}.png`, import.meta.url).href;
      };
      const selectOption = (option) => {
        emit('submit', option);
      };

      return {
        selectOption,
        getIntegrationIcon,
      };
    },
  };
</script>

<style scoped lang="scss">
  .select-integration-modal {
    .integration-options {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .integration-option {
      padding: 20px;
      border: 1px solid var(--slate-200);
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover {
        // background-color: var(--slate-50);
        border-color: var(--brand-500);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
      }
      &__icon {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        img {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
        }
      }
    }
  }
</style>
