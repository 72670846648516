<template>
  <div class="sf-el short-answer-input">
    <div class="sf-short-answer">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      <input type="text" v-model="value" :placeholder="label" />
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'ShortAnswerElement',
    props: {
      label: String,
      description: String,
      name: String,
      modelValue: String,
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const value = ref(props.modelValue);
      watch(value, (newValue) => {
        emit('update:modelValue', newValue);
      });
      return {
        value,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .sf-short-answer {
  }
</style>
