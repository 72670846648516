<template>
  <div class="add-organization-form">
    <form @submit.prevent="handleSubmit">
      <TextInput
        :id="inputs.name.id"
        :label="inputs.name.label"
        v-model="inputs.name.binding"
        :error="inputs.name.error"
        :rules="inputs.name.rules"
        @validate="onValidate('name', $event)"
      />
      <div class="add-organization-form__buttons">
        <Btn type="submit" text="Create organization" :loading="isLoading" variant="secondary" />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import Btn from '@/components/partials/Btn.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  // import { validateText } from '@/utils/validation';
  import { useValidation } from '@/composables/useValidation';
  import { useUserStore } from '@/store/userStore';

  export default {
    components: {
      Btn,
      TextInput,
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const userStore = useUserStore();
      const isLoading = ref(false);
      // Using a composable for validation
      const { inputs, validateForm, onValidate } = useValidation({
        name: {
          id: 'name',
          label: 'Organization name',
          binding: '',
          error: '',
          rules: ['required', 'maxLength:255'],
        },
      });

      const handleSubmit = async () => {
        isLoading.value = true;
        const allFieldsValid = validateForm();

        if (allFieldsValid) {
          const formData = Object.keys(inputs.value).reduce((acc, key) => {
            acc[key] = inputs.value[key].binding;
            return acc;
          }, {});

          const newOrg = await userStore.createOrganization(formData);

          if (newOrg) {
            props.onSubmit(newOrg);
          }
        } else {
          const errors = Object.keys(inputs.value).reduce((acc, key) => {
            if (inputs.value[key].error) {
              acc[key] = inputs.value[key].error;
            }
            return acc;
          }, {});
          console.log('Form validation errors:', errors);
          props.onError(errors);
        }
        isLoading.value = false;
      };
      return {
        inputs,
        onValidate,
        handleSubmit,
        isLoading,
      };
    },
  };
</script>

<style scoped lang="scss">
  .add-organization-form {
    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }
</style>
