<template>
  <div class="choice-list">
    <label>{{ label }}</label>

    <div v-for="(choice, index) in choices" :key="choice.id" class="choice-list__item">
      <div class="choice-list__header">
        <span>{{ 'Choice ' + (index + 1) }}</span>
        <Btn @click="removeChoice(index)" icon="trash" variant="plain" />
      </div>

      <TextInput
        :id="`choice-${choice.id}-text`"
        :label="'Content'"
        v-model="choice.content"
        :error="choice.error"
        :rules="['required', 'max:100']"
        @validate="onValidateChoice(index, $event)"
      />

      <TextInput
        :id="`choice-${choice.id}-text`"
        :label="'Description'"
        v-model="choice.description"
        :error="choice.error"
        :rules="['max:100']"
        @validate="onValidateChoice(index, $event)"
      />

      <FileInput
        :id="`choice-${choice.id}-file`"
        :label="'Upload Image'"
        v-model="choice.image"
        :error="choice.error"
        :rules="[]"
        @validate="onValidateChoice(index, $event)"
      />

      <SelectInput
        v-if="mainLogic === 'specific_page'"
        :id="`choice-${choice.id}-logic`"
        :label="'Jump to page (when answer = ' + choice.content + ')'"
        v-model="choice.logic"
        :options="pageOptions"
        @validate="onValidateChoice(index, $event)"
      />
    </div>

    <Btn @click="addChoice" icon="plus">Add choice</Btn>
  </div>
</template>

<script>
  import { uid } from 'uid';
  import { ref, watch, computed } from 'vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import FileInput from '@/components/inputs/FileInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useFormStore } from '@/store/formStore';
  import { validateInput } from '@/utils/validation';

  export default {
    components: {
      TextInput,
      FileInput,
      SelectInput,
      Btn,
    },
    props: {
      label: {
        type: String,
        default: 'Choices',
      },
      modelValue: {
        type: Array,
        required: true,
      },
      mainLogic: {
        type: String,
        default: 'no_click_behavior',
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const formStore = useFormStore();
      const pageOptions = computed(() => {
        return formStore.currentForm.pages.map((page) => {
          return {
            value: page.id,
            label: page.title,
          };
        });
      });

      const choices = ref([...props.modelValue]);

      const addChoice = () => {
        choices.value.push({
          id: uid(),
          content: '',
          image: '',
          logic: '',
          error: '',
        });
        emit('update:modelValue', choices.value);
      };

      const removeChoice = (index) => {
        choices.value.splice(index, 1);
        emit('update:modelValue', choices.value);
      };

      const validateChoice = (index) => {
        const choice = choices.value[index];
        const errorMessage = validateInput(choice.content, ['required']);
        choice.error = errorMessage;
        return !errorMessage;
      };

      const onValidateChoice = (index, error) => {
        validateChoice(index);
        emit('validate', error);
      };

      watch(
        () => props.modelValue,
        (newValue) => {
          choices.value = [...newValue];
        }
      );

      watch(
        choices,
        (newValue) => {
          if (JSON.stringify(newValue) !== JSON.stringify(props.modelValue)) {
            emit('update:modelValue', newValue);
          }
        },
        { deep: true }
      );

      return {
        choices,
        addChoice,
        removeChoice,
        onValidateChoice,
        pageOptions,
      };
    },
  };
</script>

<style scoped lang="scss">
  .choice-list {
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 0.875rem;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 0.875rem;
    }
    &__item {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 0.5rem;
      background-color: var(--slate-100);
      box-shadow:
        0 0 #0000,
        0 0 #0000,
        0 1px 2px 0 rgb(0 0 0 / 0.05);
      border: 1px solid var(--slate-200);
    }
  }

  .uploaded-image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 10px;
  }
</style>
