import { defineStore } from 'pinia';
import axios from 'axios';
import { auth } from '@/services/firebase';
import { useFormStore } from '@/store/formStore';
import { useIntegrationStore } from '@/store/integrationStore';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from 'firebase/auth';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    userData: null,
    organizations: null,
    currentOrganization: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.user,
    currentOrganizationId: (state) => state.currentOrganization?.id,
    userRole: (state) => {
      if (!state.currentOrganization || !state.userData?.organizationMemberships) {
        return null;
      }
      const membership = state.userData.organizationMemberships.find(
        (m) => m.organizationId === state.currentOrganization.id
      );
      return membership?.role;
    },
  },
  actions: {
    async syncOrganizationContext(uid) {
      try {
        const idToken = await this.user.getIdToken();
        const response = await axios.post(
          '/auth/sync',
          { uid },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        this.organizations = response.data.organizations;
        this.currentOrganization = this.organizations.find(
          (org) => org.id === response.data.user.currentOrganizationId
        );
        return response.data;
      } catch (error) {
        console.error('Error syncing organization context:', error);
        throw error;
      }
    },
    async fetchUserData(uid) {
      try {
        const response = await axios.get('/user/' + uid);
        this.userData = response.data.user;
        this.organizations = response.data.organizations;
        // Find current organization based on user data
        // Ensure organizations exists and has items before trying to find
        this.currentOrganization =
          this.organizations?.length > 0
            ? this.organizations.find((org) => org.id === this.userData.currentOrganizationId) ||
              this.organizations[0]
            : null;

        // After successful login
        const formStore = useFormStore();
        await formStore.fetchForms();

        const integrationStore = useIntegrationStore();
        await integrationStore.fetchIntegrations();

        // Sync organization context with backend
        await this.syncOrganizationContext(uid);
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
    },
    async switchOrganization(organizationId) {
      try {
        console.log('Switching organization to:', organizationId);
        const idToken = await this.user.getIdToken();
        await axios.post(
          '/user/switch-organization',
          { organizationId },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        // Update local state
        this.currentOrganization = this.organizations.find((org) => org.id === organizationId);

        // Refresh the page to ensure all components are updated with new organization context
        window.location.reload();

        // Refresh user data to ensure everything is in sync
        await this.fetchUserData(this.user.uid);

        return this.currentOrganization;
      } catch (error) {
        console.error('Error switching organization:', error);
        throw error;
      }
    },
    async createOrganization(data) {
      const response = await axios.post('/organizations', data);
      return response.data;
    },
    init() {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          this.user = user;
          if (user) {
            await this.fetchUserData(user.uid);
          } else {
            this.userData = null;
            this.organizations = null;
            this.currentOrganization = null;
          }
          resolve();
        });
      });
    },

    async login(email, password) {
      try {
        const { user } = await signInWithEmailAndPassword(auth, email, password);
        this.user = user;
        await this.fetchUserData(user.uid);
        return user;
      } catch (error) {
        console.error('Error logging in:', error);
        throw error;
      }
    },

    async logout() {
      try {
        await auth.signOut();
        this.user = null;
        this.userData = null;
        this.organizations = null;
        this.currentOrganization = null;
      } catch (error) {
        console.error('Error logging out:', error);
        throw error;
      }
    },
  },
});

// export const useUserStore = defineStore('user', () => {
//   const state = reactive({
//     user: null,
//     organizations: null,
//     currentOrganization: null
//   });

//   const login = async ({ email, password }) => {
//     const userCredential = await auth.signInWithEmailAndPassword(email, password);
//     await this.fetchUserData(userCredential.user.uid);
//   };

//   const fetchUserData = async (uid) => {
//     try {
//       const response = await axios.get('/user/' + uid);
//       const { user: userData, organizations } = response.data;
//       // Set currentOrganization to the first organization in the list
//       state.user = userData;
//       state.organizations = organizations;
//       state.currentOrganization = organizations[0];
//       console.log("User data fetched");
//     } catch (err) {
//       console.error('Failed to fetch user data:', err);
//     }
//   }

//   return { user: state.user, login, fetchUserData };
// });
