export const INTEGRATION_TYPES = {
  webhook: {
    name: 'Webhook',
    canAutoTrigger: true, // Can be triggered automatically
    canUseAutomation: true, // Can be used in custom automations
    supportedTriggers: ['form_submission', 'status_change'],
    settingsSchema: {
      webhookUrl: { type: 'string', required: true, label: 'Webhook URL' },
    },
  },
  zapier: {
    name: 'Zapier',
    canAutoTrigger: true, // Can be triggered automatically
    canUseAutomation: true, // Can be used in custom automations
    supportedTriggers: ['form_submission', 'status_change'],
    settingsSchema: {
      webhookUrl: { type: 'string', required: true, label: 'Webhook URL' },
    },
  },
  google_tag_manager: {
    name: 'Google Tag Manager',
    canAutoTrigger: true, // Automatically tracks events
    canUseAutomation: false, // Cannot be used in custom automations
    autoTrackEvents: ['form_page_view', 'form_page_change', 'form_submission'],
    settingsSchema: {
      containerId: { type: 'string', required: true, label: 'Container ID' },
    },
  },
  facebook_pixel: {
    name: 'Meta Pixel (Facebook & Instagram)',
    canAutoTrigger: true, // Automatically tracks events
    canUseAutomation: false, // Cannot be used in custom automations
    autoTrackEvents: ['form_page_view', 'form_page_change', 'form_submission'],
    settingsSchema: {
      pixelId: { type: 'string', required: true, label: 'Pixel ID' },
    },
  },
  linkedin_tag: {
    name: 'LinkedIn Insight Tag',
    canAutoTrigger: true, // Automatically tracks events
    canUseAutomation: false, // Cannot be used in custom automations
    autoTrackEvents: ['form_page_view', 'form_submission'],
    settingsSchema: {
      partnerId: { type: 'string', required: true, label: 'Partner ID' },
    },
  },
};
