<template>
  <div class="all-flows-page">
    <div class="container">
      <FormList />
    </div>
  </div>
</template>

<script>
  // import FormList from '@/components/dashboard/FormList.vue';
  import FormList from '@/components/dashboard/FormList.vue';

  export default {
    components: { FormList },
    setup() {},
  };
</script>

<style scoped lang="scss">
  .all-flows-page {
    // Your styles here
  }
</style>
