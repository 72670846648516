import { useUserStore } from '@/store/userStore';
import { useModalStore } from '@/store/modalStore';
import { auth, getCurrentUser } from '@/services/firebase';
import { sendEmailVerification } from 'firebase/auth';

/**
 * Authentication middleware for Vue Router
 * @param {import('vue-router').RouteLocationNormalized} to - Target route
 * @param {import('vue-router').RouteLocationNormalized} from - Current route
 * @param {import('vue-router').NavigationGuardNext} next - Navigation guard function
 */
export async function checkAuth(to, from, next) {
  const userStore = useUserStore();
  const modalStore = useModalStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Allow access to non-auth routes
  if (!requiresAuth) {
    next();
    return;
  }

  // Initialize user store if needed
  if (!userStore.user) {
    userStore.init();
  }

  // Check if user is authenticated
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    console.log('User not authenticated, redirecting to login...');
    next('/login');
    return;
  }

  // Check email verification
  if (!currentUser.emailVerified) {
    // Allow users less than 1 day old to proceed without verification
    const accountAge = Date.now() - currentUser.metadata.creationTime;
    const oneDayInMs = 24 * 60 * 60 * 1000;
    if (accountAge > oneDayInMs) {
      const modalId = modalStore.addModal(null, {
        title: 'Email Verification Required',
        description: 'Please verify your email address to continue using the application.',
        submitText: 'Resend verification email',
        onSubmit: async () => {
          try {
            modalStore.closeModal(modalId);
            const sentModalId = modalStore.addModal(null, {
              title: 'Verification Email Sent',
              description: 'Please check your inbox for the verification link.',
            });
            await sendEmailVerification(currentUser);
          } catch (error) {
            modalStore.addModal(null, {
              title: 'Error',
              description: error.message,
            });
          }
        },
        onClose: async () => {
          await auth.signOut();
          await userStore.logout();
          next('/login');
        },
      });
    }
    next();
    return;
  }

  // Ensure user data is loaded
  if (!userStore.userData) {
    try {
      await userStore.fetchUserData(currentUser.uid); // Add 'await' here
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      next('/login');
      return;
    }
  }
  // Allow access to protected route
  next();
}
