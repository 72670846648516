<template>
  <div class="select-action-modal">
    <div class="action-options">
      <div
        v-for="action in [
          {
            label: 'Task',
            icon: 'bullseye',
            value: 'task',
          },
          { label: 'Delay', icon: 'calendar-clock', value: 'delay' },
          { label: 'Condition', icon: 'split', value: 'condition' },
        ]"
        :key="action.value"
        class="action-option"
        @click="selectAction(action.value)"
      >
        <div class="action-option__icon">
          <BaseIcon :name="action.icon" size="24" />
        </div>
        <h3>{{ action.label }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/partials/BaseIcon.vue';

  export default {
    name: 'SelectActionModal',
    components: {
      BaseIcon,
    },
    setup(props, { emit }) {
      const selectAction = (action) => {
        emit('submit', action);
      };

      return {
        selectAction,
      };
    },
  };
</script>

<style scoped lang="scss">
  .select-action-modal {
    .action-options {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .action-option {
      padding: 20px;
      border: 1px solid var(--slate-200);
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover {
        // background-color: var(--slate-50);
        border-color: var(--brand-500);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
      }
      &__icon {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: var(--slate-50);
      }
    }
  }
</style>
