<template>
  <div class="welcome-modal">
    <div class="welcome-modal__video">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ"
        title="Welcome Video"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <div class="welcome-modal__content">
      <p>Welcome to SmartFlow! We're excited to help you get started.</p>
    </div>

    <div class="modal__actions">
      <Btn text="Knowledge Base" @click="goToKnowledgeBase" variant="secondary" class="mr-2" />
      <Btn text="Get Started" @click="proceed" variant="primary" />
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useModalStore } from '@/store/modalStore';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'WelcomeModal',

    components: {
      Btn,
    },
    props: {
      onClose: {
        type: Function,
        required: true,
      },
    },
    setup(props, { emit }) {
      const router = useRouter();
      const modalStore = useModalStore();

      const goToKnowledgeBase = () => {
        router.push('/knowledge-base');
        props.onClose();
      };

      const proceed = () => {
        router.push('/all-flows');
        props.onClose();
      };

      return {
        goToKnowledgeBase,
        proceed,
      };
    },
  };
</script>

<style scoped lang="scss">
  .welcome-modal {
    text-align: center;
    padding: 2rem 0 0 0;

    &__title {
      margin-bottom: 2rem;
      font-size: 2rem;
      font-weight: bold;
    }

    &__video {
      margin-bottom: 2rem;

      iframe {
        max-width: 100%;
      }
    }

    &__content {
      margin-bottom: 2rem;
    }
  }

  .modal__actions {
    display: flex;
    justify-content: space-between;
  }
</style>
