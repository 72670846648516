import { defineStore } from 'pinia';
import { getForm } from '@/services/firebaseUtils';
import { formService } from '@/services/formService';

import { useUserStore } from './userStore';
import { useDesignStore } from './designStore';

// import { watch } from 'vue';
import axios from 'axios';
import { debounce } from 'lodash';
import { uid } from 'uid';

export const useFormStore = defineStore('form', {
  state: () => ({
    forms: [],
    currentForm: {
      id: null,
      name: '',
      pages: [],
      settings: {},
      designId: null,
      enabledIntegrations: [],
    },
    inputEditing: null,
    activePageIndex: null,
    activeBlockIndex: null,
    activeElementIndex: null,
    formIntegrations: {},
  }),
  getters: {
    pages(state) {
      return state.currentForm ? state.currentForm.pages : [];
    },
    activePage(state) {
      if (state.currentForm && state.activePageIndex !== null) {
        return state.currentForm.pages[state.activePageIndex];
      }
      return null;
    },
    activeBlock(state) {
      if (state.currentForm && state.activeBlockIndex !== null) {
        return state.currentForm.pages[state.activePageIndex].blocks[state.activeBlockIndex];
      }
      return null;
    },
    activeElement(state) {
      if (
        state.currentForm &&
        state.activePageIndex !== null &&
        state.activeBlockIndex !== null &&
        state.activeElementIndex !== null
      ) {
        return state.currentForm.pages[state.activePageIndex].blocks[state.activeBlockIndex]
          .elements[state.activeElementIndex];
      }
      return null;
    },
    // Get all integrations for a specific form
    getFormIntegrations: (state) => (formId) => {
      return state.formIntegrations[formId] || {};
    },
    isIntegrationEnabled: (state) => (integrationId) => {
      return state.currentForm.enabledIntegrations?.includes(integrationId) || false;
    },
  },

  actions: {
    async addPage() {
      try {
        const newPage = {
          id: uid(),
          title: `Step ${this.currentForm.pages.length + 1}`,
          blocks: [
            {
              id: uid(),
              title: 'Block 1',
              elements: [],
            },
          ],
        };
        if (this.currentForm) {
          this.currentForm.pages.push(newPage);
        }
        await this.saveCurrentForm();
        return { status: 'success', message: 'Step added successfully', newPage };
      } catch (error) {
        console.error('Failed to add page:', error);
        return { status: 'error', message: 'Failed to add page', error };
      }
    },

    async removePage(pageIndex) {
      try {
        if (this.currentForm) {
          this.currentForm.pages.splice(pageIndex, 1);
          // Adjust activePageIndex if necessary
          if (pageIndex === this.activePageIndex) {
            this.activePageIndex = this.currentForm.pages.length > 0 ? 0 : null; // Set to the first page or null if no pages left
          } else if (pageIndex < this.activePageIndex) {
            this.activePageIndex--;
          }
          await this.saveCurrentForm();
          return { status: 'success', message: 'Step removed successfully' };
        }
      } catch (error) {
        console.error('Failed to remove page:', error);
        return { status: 'error', message: 'Failed to remove page', error };
      }
    },

    // Add new action for blocks
    async addBlock() {
      try {
        const newBlock = {
          id: uid(),
          title: `Block ${this.currentForm.pages[this.activePageIndex].blocks.length + 1}`,
          elements: [],
        };
        this.currentForm.pages[this.activePageIndex].blocks.push(newBlock);
        await this.saveCurrentForm();
        return { status: 'success', message: 'Block added successfully', newBlock };
      } catch (error) {
        console.error('Failed to add block:', error);
        return { status: 'error', message: 'Failed to add block', error };
      }
    },
    // Add new action to remove block
    async removeBlock(blockIndex) {
      try {
        this.currentForm.pages[this.activePageIndex].blocks.splice(blockIndex, 1);
        await this.saveCurrentForm();
        return { status: 'success', message: 'Block removed successfully' };
      } catch (error) {
        console.error('Failed to remove block:', error);
        return { status: 'error', message: 'Failed to remove block', error };
      }
    },

    setActivePage(pageIndex, blockIndex = 0) {
      this.activePageIndex = pageIndex;
      this.activeBlockIndex = blockIndex;
    },

    setActiveBlock(blockIndex = 0) {
      this.activeBlockIndex = blockIndex;
    },

    setActiveElementIndex(index) {
      this.activeElementIndex = index;
    },

    setActiveElement(pageIndex = 0, blockIndex = 0, elementIndex = 0) {
      this.activePageIndex = pageIndex;
      this.activeBlockIndex = blockIndex;
      this.activeElementIndex = elementIndex;
    },

    unsetElements() {
      this.activeElementIndex = null;
    },

    updateActiveElementSettings(settings) {
      if (this.activeElement) {
        Object.assign(this.activeElement.props, settings);
        this.triggerUpdateForm(this.currentForm.id);
      }
    },

    editPageSettings(pageIndex) {
      this.inputEditing = {
        type: 'page',
        index: pageIndex,
        settings: { ...this.currentForm.pages[pageIndex] },
      };
    },

    // Update editElementSettings to work with blocks
    editElementSettings(pageIndex, blockIndex, elementIndex) {
      const block = this.currentForm.pages[pageIndex].blocks[blockIndex];
      this.inputEditing = {
        type: 'input',
        pageIndex,
        blockIndex,
        elementIndex,
        settings: { ...block.elements[elementIndex] },
      };
    },

    updateElementProps(pageIndex, blockIndex, elementIndex, settings) {
      Object.assign(
        this.currentForm.pages[pageIndex].blocks[blockIndex].elements[elementIndex].props,
        settings
      );
      this.triggerUpdateForm(this.currentForm.id);
    },

    async addElement(el) {
      try {
        if (this.activePageIndex === null || this.activeBlockIndex === null) {
          throw new Error('No active page or block selected');
        }
        this.currentForm.pages[this.activePageIndex].blocks[this.activeBlockIndex].elements.push(
          el
        );
        this.setActiveElement(
          this.activePageIndex,
          this.activeBlockIndex,
          this.currentForm.pages[this.activePageIndex].blocks[this.activeBlockIndex].elements
            .length - 1
        );
        await this.saveCurrentForm();
        return { status: 'success', message: 'Element added successfully' };
      } catch (error) {
        console.error('Failed to add element:', error);
        return { status: 'error', message: 'Failed to add element', error };
      }
    },

    async removeElement(blockIndex, elementIndex) {
      try {
        this.currentForm.pages[this.activePageIndex].blocks[blockIndex].elements.splice(
          elementIndex,
          1
        );
        // Adjust activeElementIndex if necessary
        if (elementIndex === this.activeElementIndex) {
          this.activeElementIndex =
            this.currentForm.pages[this.activePageIndex].blocks[blockIndex].elements.length > 0
              ? 0
              : null;
        } else if (elementIndex < this.activeElementIndex) {
          this.activeElementIndex--;
        }
        await this.saveCurrentForm();
        return { status: 'success', message: 'Element removed successfully' };
      } catch (error) {
        console.error('Failed to remove element:', error);
        return { status: 'error', message: 'Failed to remove element', error };
      }
    },

    getAllElements() {
      // Get all elements from all pages and blocks
      return this.currentForm?.pages.flatMap((page) =>
        page.blocks.flatMap((block) =>
          block.elements.map((element) => ({
            id: element.id,
            props: element.props,
            type: element.type,
            pageId: page.id,
            blockId: block.id,
          }))
        )
      );
    },

    saveSettings(settings) {
      if (this.inputEditing.type === 'page') {
        const page = this.currentForm.pages[this.inputEditing.index];
        Object.assign(page, settings);
      } else if (this.inputEditing.type === 'input') {
        const element =
          this.currentForm.pages[this.inputEditing.pageIndex].blocks[this.inputEditing.blockIndex]
            .elements[this.inputEditing.elementIndex];
        Object.assign(element, settings);
      }
      this.inputEditing = null;
    },

    async updatePage(pageIndex, data) {
      if (this.currentForm && this.currentForm.pages[pageIndex]) {
        // First merge the data and the edited page settings
        Object.assign(this.currentForm.pages[pageIndex], data);
      }
      // Then save the form
      await this.saveCurrentForm();
    },

    async publishForm(form) {
      try {
        await formService.updateForm(form.id, form);
        this.currentForm = form;
        return form;
      } catch (error) {
        console.error('Failed to publish form:', error);
        throw error;
      }
    },

    async saveCurrentForm() {
      const form = {
        ...this.currentForm,
      };
      await formService.updateForm(this.currentForm.id, form);
    },

    async loadForm(id) {
      const form = await formService.getFormById(id);
      this.$patch((state) => {
        state.currentForm = form;
        // If form has a designId, load that design
        if (form.designId) {
          const designStore = useDesignStore();
          designStore.loadDesign(form.designId);
        }
      });
    },

    async fetchForms() {
      try {
        const forms = await formService.getForms();
        this.forms = forms;
      } catch (error) {
        console.error('Failed to fetch forms:', error);
        throw error;
      }
    },

    async fetchFormById(id) {
      const form = await formService.getFormById(id);
      return form;
    },

    async createForm(data) {
      const userStore = useUserStore();
      const userId = userStore.user.uid;

      const response = await axios.post('/forms', {
        name: data.flow_title,
        pages: [],
        settings: {},
        designId: null,
        uid: userId,
      });
      this.$patch(async (state) => {
        state.forms.push(response.data);
        state.forms = await formService.getForms();
      });
      return response;
    },

    async createFormWithAI(data) {
      const userStore = useUserStore();
      const organizationId = userStore.currentOrganization.id;
      const userId = userStore.user.uid;

      const response = await axios.post('/ai/generate-form', {
        title: data.flow_title,
        description: data.description,
        organizationId,
        uid: userId,
      });
      this.$patch(async (state) => {
        state.forms.push(response.data);
        state.forms = await formService.getForms();
      });
      return response;
    },

    async deleteForm(id) {
      try {
        const deletedForm = await formService.deleteForm(id);
        if (deletedForm.status === 204) {
          this.forms = this.forms.filter((form) => form.id !== id);
        }
      } catch (error) {
        console.error('Error deleting form:', error);
      }
    },

    // Debounced update function
    debouncedUpdateForm: debounce(async function (id, form) {
      try {
        await formService.updateForm(id, form);
      } catch (error) {
        console.error('Error updating form:', error);
      }
    }, 300),

    // Function to trigger the debounced update
    triggerUpdateForm(id) {
      const form = {
        name: this.currentForm.name,
        pages: this.pages,
        settings: this.currentForm.settings,
      };
      this.debouncedUpdateForm(id, form);
    },

    updateElementProps(pageIndex, elementIndex, settings) {
      Object.assign(this.currentForm.pages[pageIndex].elements[elementIndex].props, settings);
      this.triggerUpdateForm(this.currentForm.id);
    },
    async setFormDesign(designId) {
      if (this.currentForm) {
        this.currentForm.designId = designId;
        const designStore = useDesignStore();
        await designStore.loadDesign(designId);
        await this.saveCurrentForm();
      }
    },

    async updateFormSettings(formId, settings) {
      try {
        const response = await axios.patch(`/forms/${formId}/settings`, { settings });
        this.currentForm.settings = response.data.settings;
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to update form settings');
      }
    },

    async getFormSubmissions(formId, page = 1, limit = 50) {
      try {
        const response = await axios.get(`/submissions`, {
          params: {
            formId,
            page,
            limit,
          },
        });
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to get form submissions');
      }
    },

    async getFormSubmission(submissionId) {
      try {
        const response = await axios.get(`/submissions/${submissionId}`);
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to get form submissions');
      }
    },

    async updateSubmissionStatus(submissionId, newStatus) {
      try {
        const response = await axios.patch(`/submissions/${submissionId}/status`, {
          status: newStatus,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to update submission status');
      }
    },

    async toggleIntegration(integrationId) {
      try {
        if (!this.currentForm.enabledIntegrations) {
          this.currentForm.enabledIntegrations = [];
        }

        const isEnabled = this.isIntegrationEnabled(integrationId);

        // Toggle the integration
        if (isEnabled) {
          this.currentForm.enabledIntegrations = this.currentForm.enabledIntegrations.filter(
            (id) => id !== integrationId
          );
        } else {
          this.currentForm.enabledIntegrations.push(integrationId);
        }

        // Save the form
        await this.saveCurrentForm();
      } catch (error) {
        console.error('Failed to toggle integration:', error);
        throw error;
      }
    },

    async fetchFormIntegrations(formId) {
      try {
        const response = await axios.get(`/forms/${formId}/integrations`);

        // Update local state
        if (!this.formIntegrations[formId]) {
          this.formIntegrations[formId] = {};
        }
        this.formIntegrations[formId] = response.data.reduce((acc, integration) => {
          acc[integration.integrationId] = {
            enabled: integration.enabled,
            settings: integration.settings || {},
          };
          return acc;
        }, {});

        return response.data;
      } catch (error) {
        console.error('Failed to fetch form integrations:', error);
        throw error;
      }
    },
  },
});

// // Watch for changes in the currentForm and trigger the debounced update
// const formStore = useFormStore();
// watch(() => formStore.currentForm, (newForm) => {
//   if (newForm.id) {
//     formStore.triggerUpdateForm(newForm.id);
//   }
// }, { deep: true });
