<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :value="modelValue"
      @input="onInput"
      @blur="onBlur"
      :placeholder="placeholder"
    ></textarea>
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { validateInput } from '@/utils/validation'; // Make sure this is imported

  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const onInput = (event) => {
        touched.value = true;
        emit('update:modelValue', event.target.value);
        if (props.realTimeValidation) {
          validate();
        }
      };
      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        onInput,
        onBlur,
        validate,
        localError,
      };
    },
  };
</script>

<style scoped></style>
