<template>
  <div class="avatar">
    {{ title }}
  </div>
</template>

<script>
  export default {
    name: 'AvatarElement',
    props: {
      title: {
        type: String,
      },
      backgroundImage: {
        type: String,
      },
      variantColor: {
        type: String,
        default: 'light',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .avatar {
    border-radius: 50%;
    background-color: var(--brand-500);
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
  }
</style>
