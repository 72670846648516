import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/userStore';

import LoginPage from '@/pages/authentication/LoginPage.vue';
import RegisterPage from '@/pages/authentication/RegisterPage.vue';
import FormEditorPage from '@/pages/flow/FormEditorPage.vue';
import FormViewerPage from '@/pages/public/FormViewerPage.vue';

import EditView from '@/pages/flow/EditView.vue';
import IntegrationsView from '@/pages/flow/IntegrationsView.vue';
import FormIntegrationsView from '@/pages/flow/FormIntegrationsView.vue';
import PerformanceView from '@/pages/flow/PerformanceView.vue';
import SubmissionView from '@/pages/flow/SubmissionView.vue';
import PublishView from '@/pages/flow/PublishView.vue';
import SettingsView from '@/pages/flow/SettingsView.vue';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AllFormsPage from '@/pages/dashboard/AllFormsPage.vue';
import InsightsPage from '@/pages/dashboard/InsightsPage.vue';
import AutomationsView from '@/pages/dashboard/AutomationsPage.vue';
import AutomationEditor from '@/pages/dashboard/AutomationEditor.vue';
import { checkAuth } from '@/middleware/authMiddleware';

const routes = [
  { path: '/login', component: LoginPage },
  { path: '/register', component: RegisterPage },
  {
    path: '/flow/:id',
    component: FormEditorPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Edit',
        component: EditView,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: SettingsView,
      },
      {
        path: 'form-integrations',
        name: 'FormIntegrations',
        component: FormIntegrationsView,
      },
      {
        path: 'performance',
        name: 'Performance',
        component: PerformanceView,
      },
      {
        path: 'submissions',
        name: 'Submissions',
        component: SubmissionView,
      },
      {
        path: 'publish',
        name: 'Publish',
        component: PublishView,
      },
    ],
  },
  { path: '/funnel/:id', component: FormViewerPage, props: true, meta: { requiresAuth: false } },
  {
    path: '/',
    component: DefaultLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '/all-flows', component: AllFormsPage, meta: { requiresAuth: true } },
      { path: '/integrations', component: IntegrationsView, meta: { requiresAuth: true } },
      { path: '/automations', component: AutomationsView, meta: { requiresAuth: true } },
      {
        path: '/automations/:id',
        component: AutomationEditor,
        meta: { requiresAuth: true },
      },
      { path: '/insights', component: InsightsPage, meta: { requiresAuth: true } },
    ],
  },
  { path: '/', redirect: '/login' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   const userStore = useUserStore();
//   // Ensure that the store is initialized
//   if (!userStore.user) {
//     await userStore.init();
//   }
//   const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

//   if (requiresAuth) {
//     if (!userStore.user) {
//       console.log('User not authenticated, redirecting to login...');
//       next('/login');
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });
// Apply authentication middleware
router.beforeEach((to, from, next) => checkAuth(to, from, next));
export default router;
