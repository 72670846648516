<template>
<div v-show="isActive">
    <slot />
</div>
</template>

<script>
import { inject, ref, watchEffect, onMounted } from 'vue';

export default {
    name: 'TabItem',
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const addTab = inject('addTab');
        const activeTab = inject('activeTab');
        const isActive = ref(false);

        onMounted(() => {
            addTab({
                title: props.title,
                name: props.name,
            });
        });

        watchEffect(() => {
            isActive.value = activeTab.value === props.name;
        });

        return {
            isActive,
        };
    },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>