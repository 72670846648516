<template>
  <div class="form-viewer-page">
    <FormViewer />
  </div>
</template>

<script>
  import FormViewer from '@/components/publish/FormViewer.vue';

  export default {
    components: { FormViewer },
    setup() {},
  };
</script>

<style scoped lang="scss">
  .form-viewer-page {
    // Your styles here
  }
</style>
