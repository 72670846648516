<template>
  <div class="sf-el multi-choice-input">
    <div class="sf-multi-choice">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      <div
        class="sf-multi-choice__grid"
        :class="{ [`sf-multi-choice__grid--per-row-${per_row}`]: per_row }"
      >
        <div
          v-for="(choice, index) in choices"
          :key="index"
          class="choice"
          :class="{ 'is-selected': selectedChoices.includes(choice.id) }"
        >
          <input
            type="checkbox"
            :name="name"
            :value="choice.id"
            v-model="selectedChoices"
            class="checkbox-button"
            :id="choice.id"
          />
          <label :for="choice.id">
            <img :src="choice.image" class="choice__img" alt="Choice Image" v-if="choice.image" />
            <div class="choice__label">
              <span>
                <h4 class="choice__title">
                  {{ choice.content }}
                </h4>
                <p class="choice__description" v-if="choice.description">
                  {{ choice.description }}
                </p>
              </span>
              <div class="choice__checkbox">
                <BaseIcon name="check" size="16" />
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import { watch, ref } from 'vue';
  export default {
    name: 'MultiChoiceElement',
    components: {
      BaseIcon,
    },
    props: {
      label: String,
      description: String,
      choices: Array,
      name: String,
      modelValue: String,
      per_row: [String, Number],
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const selectedChoices = ref(props.modelValue || []);
      // Find the initial selected choice by content
      const findChoiceById = (id) => {
        return props.choices.find((choice) => choice.id === id);
      };

      watch(selectedChoices, (newValues) => {
        emit('update:modelValue', newValues);
      });
      const toggleChoice = (id) => {
        const index = selectedChoices.value.indexOf(id);
        if (index === -1) {
          selectedChoices.value.push(id);
        } else {
          selectedChoices.value.splice(index, 1);
        }
      };
      return {
        selectedChoices,
        toggleChoice,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .sf-el {
  }

  .multi-choice-input {
    display: grid;
    flex-direction: column;
  }

  .sf-multi-choice {
    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 10px;
      &--per-row-1 {
        grid-template-columns: repeat(1, 1fr);
      }
      &--per-row-2 {
        grid-template-columns: repeat(2, 1fr);
      }
      &--per-row-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &--per-row-4 {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .choice {
    position: relative;
    border: 1px solid var(--uc-radio-border-color);
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    text-align: left;
    transition: border-color 0.3s ease;
    background-color: var(--uc-radio-background-color);
    background-color: var(--choice-default-background);
    color: var(--choice-default-text);
    display: flex;
    flex-direction: column;
    @include hover;
    min-height: 50px;
    &__img {
      width: 100%;
      height: auto;
      aspect-ratio: 3/2;
      background-color: var(--white);
      object-fit: cover;
      object-position: center;
    }
    label {
      flex-grow: 1;
      cursor: pointer;
    }
    span {
      display: block;
      // padding: 1rem;
    }
    .checkbox-button {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0;
      pointer-events: none;
    }
    &.is-selected {
      border-color: var(--uc-radio-checked-border-color);
    }
    input[type='checkbox']:checked + label .choice__checkbox {
      border-color: var(--uc-radio-checked-border-color);
      color: var(--uc-radio-checked-border-color);
    }
    @include hover {
      border-color: var(--uc-radio-hover-border-color);
    }
    &__label {
      position: relative;
      padding: 1rem 3rem 1rem 1rem;
    }
    &__title {
      color: inherit;
    }
    &__checkbox {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5rem;
      background-color: var(--white);
      background-color: var(--choice-default-text);
      color: var(--slate-300);
      color: var(--choice-default-background);
      border: 1px solid var(--slate-200);
    }
  }
</style>
