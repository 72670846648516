<template>
  <div class="form-builder-page">Publish view</div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';

  export default {
    components: {},
    setup() {
      const route = useRoute();
      const formStore = useFormStore();
      const formId = route.params.id;

      const currentForm = ref(formStore.currentForm);
      const loadForm = async () => {
        try {
          await formStore.loadForm(formId);
        } catch (error) {
          console.error('Failed to load form:', error);
        }
      };

      onMounted(loadForm);

      return {
        currentForm,
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-builder-page {
    display: flex;
  }
</style>
