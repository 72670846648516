<template>
  <div :class="iconClass" :style="iconStyle" v-html="iconContent"></div>
</template>

<script>
  import { defineComponent, ref, watch } from 'vue';

  export default defineComponent({
    name: 'BaseIcon',
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: [String, Number],
        default: '24',
      },
    },
    setup(props) {
      const iconContent = ref('');

      const loadIcon = async (iconName) => {
        try {
          // Use dynamic imports
          const icon = await import(`@/assets/icons/${iconName}.svg?raw`);
          iconContent.value = icon.default;
        } catch (error) {
          console.error(error);
          iconContent.value = '';
        }
      };

      watch(
        () => props.name,
        (newName) => {
          loadIcon(newName);
        },
        { immediate: true }
      );

      const iconClass = ref('icon');
      const iconStyle = ref({
        width: `${props.size}px`,
        height: `${props.size}px`,
      });

      return {
        iconContent,
        iconClass,
        iconStyle,
      };
    },
  });
</script>

<style scoped>
  .icon {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    svg {
      display: inline-block;
      fill: currentColor;
    }
    /* Optional: Add other default styles for your icons here */
  }
</style>
