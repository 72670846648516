// src/useDayjs.js
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/nl'; // import Dutch locale

// Extend Day.js with the plugins
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

// Update the Dutch locale to use the desired format
dayjs.updateLocale('nl', {
  formats: {
    LLLL: 'MMM D, YYYY', // Custom format
  },
});

// Set the locale to Dutch
dayjs.locale('nl');

export default function useDayjs() {
  return dayjs;
}