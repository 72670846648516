import { computed } from 'vue';
import { useDesignStore } from '@/store/designStore';

export function useDesignStyles() {
  const designStore = useDesignStore();

  const getBorderRadiusValue = (size) => {
    const radiusMap = {
      none: '0px',
      small: '4px',
      medium: '12px',
      large: '24px',
      full: '9999px',
    };
    return radiusMap[size] || '8px';
  };

  const designStyles = computed(() => {
    const design = designStore.currentDesign;
    return {
      // Background colors
      '--cs-background-primary': design?.colors?.background?.primary || '#E6EBFE',
      '--cs-background-secondary': design?.colors?.background?.secondary || '#300430',

      // Button colors
      '--cs-button-default-background': design?.colors?.button?.default?.background || '#D03734',
      '--cs-button-default-text': design?.colors?.button?.default?.text || '#FFFFFF',
      '--cs-button-hover-background': design?.colors?.button?.hover?.background || '#D03734',
      '--cs-button-hover-text': design?.colors?.button?.hover?.text || '#FFFFFF',

      // Text colors
      '--cs-text-heading': design?.colors?.text?.heading || '#300430',
      '--cs-text-body': design?.colors?.text?.body || '#300430',

      // Choice colors
      '--cs-choice-default-background': design?.colors?.choice?.default?.background || '#4252AF',
      '--cs-choice-default-text': design?.colors?.choice?.default?.text || '#FFFFFF',
      '--cs-choice-default-border': design?.colors?.choice?.default?.border || '#4252AF',
      '--cs-choice-hover-background': design?.colors?.choice?.hover?.background || '#4252AF',
      '--cs-choice-hover-text': design?.colors?.choice?.hover?.text || '#FFFFFF',
      '--cs-choice-hover-border': design?.colors?.choice?.hover?.border || '#4252AF',

      // Font and border radius
      '--cs-font-family': `${design?.font || 'Inter'}, sans-serif`,
      '--cs-border-radius': getBorderRadiusValue(design?.borderRadius || 'medium'),
    };
  });

  return {
    designStyles,
  };
}
