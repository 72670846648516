<template>
    <Toolbar />
    <router-view />
</template>

<script>
import Toolbar from '@/components/layout/Toolbar.vue';

export default {
    components: {
        Toolbar,
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>