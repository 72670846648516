import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

import './config/axiosConfig';
import './assets/scss/main.scss';

// Create the app instance
const app = createApp(App);

// Add global error handler
app.config.errorHandler = (err, instance, info) => {
  console.error('Global error:', err);
  console.error('Error info:', info);
};

window.onerror = function (message, source, lineno, colno, error) {
  console.error('Window error:', { message, source, lineno, colno, error });
};

// Create a fresh Pinia instance
const pinia = createPinia();

// Install plugins
app.use(pinia);
app.use(router);
app.config.devtools = true;
app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto-end',
      allowHTML: true,
    },
  }
);

// Import store after Pinia is installed
import { useUserStore } from '@/store/userStore';
import { useFormStore } from '@/store/formStore';
import { useIntegrationStore } from '@/store/integrationStore';
// Initialize the app
const init = async () => {
  try {
    const userStore = useUserStore();
    await userStore.init();
    // Only fetch forms if user is authenticated
    if (userStore.isAuthenticated) {
      const formStore = useFormStore();
      await formStore.fetchForms();
      const integrationStore = useIntegrationStore();
      await integrationStore.fetchIntegrations();
    }

    app.mount('#app');
  } catch (error) {
    console.error('Failed to initialize app:', error);
  }
};

init();
