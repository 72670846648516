<template>
  <transition name="modal-fade" @after-leave="afterLeave">
    <div v-if="isVisible" class="modal-backdrop" @click="closeModal">
      <transition name="modal-slide">
        <div class="modal" :class="[props?.size && `modal--size-${props?.size}`]" @click.stop>
          <div class="modal__heading">
            <div v-if="props.title" class="modal__title">
              {{ props.title }}
            </div>
            <BaseIcon @click="closeModal" name="xmark" size="16" />
          </div>
          <div class="modal__body">
            <p v-if="props?.description" class="mb-4">{{ props?.description }}</p>
            <slot></slot>
          </div>
          <div v-if="props?.onSubmit && !hasComponent" class="modal__actions">
            <Btn
              :text="props?.submitText || 'Confirm'"
              @click="handleSubmit"
              :variant="buttonVariant"
            />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
  import { ref, watch, computed, onMounted, onUnmounted } from 'vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'ModalEl',
    components: {
      BaseIcon,
      Btn,
    },
    props: {
      onClose: {
        type: Function,
        required: false,
      },
      onSubmit: {
        type: Function,
        required: false,
      },
      submitText: {
        type: String,
        default: 'Confirm',
      },
      show: {
        type: Boolean,
        default: false,
      },
      props: {
        type: Object,
      },
      hasComponent: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['after-leave', 'close'],
    setup(props, { emit, slots }) {
      const isVisible = ref(props.show);
      const hasDefaultSlot = computed(() => {
        return slots.default && slots.default().length > 0;
      });

      const buttonVariant = computed(() => {
        return props.props?.status || 'secondary';
      });

      const handleEscape = (e) => {
        if (e.key === 'Escape' && isVisible.value) {
          closeModal();
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleEscape);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleEscape);
      });

      watch(
        () => props.show,
        (newVal) => {
          isVisible.value = newVal;
        }
      );
      const closeModal = () => {
        emit('close');
      };

      const afterLeave = () => {
        emit('after-leave');
      };

      const handleSubmit = () => {
        if (props.props?.onSubmit) {
          props.props.onSubmit();
        }
      };

      return {
        closeModal,
        isVisible,
        afterLeave,
        handleSubmit,
        hasDefaultSlot,
        buttonVariant,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(17, 24, 39, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
  }

  .modal {
    background: white;
    border-radius: 0.5rem;
    max-width: 500px;
    width: 100%;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);

    &--size- {
      &sm {
        max-width: 300px;
      }
      &md {
        max-width: 500px;
      }
      &lg {
        max-width: 700px;
      }
    }
    &__title {
      font-size: 1.125rem;
      font-weight: 600;
    }
    &__heading {
      border-bottom: 1px solid var(--slate-200);
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        color: var(--slate-400);
        @include hover {
          color: var(--slate-700);
        }
      }
    }
    &__body {
      padding: 1rem 1.5rem;
      p {
        line-height: 1.5;
        margin-bottom: 0;
      }
    }
    &__actions {
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;

      .btn:only-child {
        margin-left: auto; // This will push a single button to the right
      }
    }
  }

  .modal-fade-enter-active .modal-backdrop,
  .modal-fade-leave-active .modal-backdrop {
    opacity: 1;
  }

  .modal-fade-enter-active .modal,
  .modal-fade-leave-active .modal {
    transform: translateY(0);
  }
</style>
