import { defineStore } from 'pinia';
import { useUserStore } from './userStore';
import axios from 'axios';

// Define integration types and their capabilities
const INTEGRATION_TYPES = {
  webhook: {
    name: 'Webhook',
    canAutoTrigger: true, // Can be triggered automatically
    canUseAutomation: true, // Can be used in custom automations
    supportedTriggers: ['form_submission', 'status_change'],
    settingsSchema: {
      webhookUrl: { type: 'string', required: true, label: 'Webhook URL' },
    },
  },
  zapier: {
    name: 'Zapier',
    canAutoTrigger: true, // Can be triggered automatically
    canUseAutomation: true, // Can be used in custom automations
    supportedTriggers: ['form_submission', 'status_change'],
    settingsSchema: {
      webhookUrl: { type: 'string', required: true, label: 'Webhook URL' },
    },
  },
  google_tag_manager: {
    name: 'Google Tag Manager',
    canAutoTrigger: true, // Automatically tracks events
    canUseAutomation: false, // Cannot be used in custom automations
    autoTrackEvents: ['form_page_view', 'form_page_change', 'form_submission'],
    settingsSchema: {
      containerId: { type: 'string', required: true, label: 'Container ID' },
    },
  },
  facebook_pixel: {
    name: 'Meta Pixel (Facebook & Instagram)',
    canAutoTrigger: true, // Automatically tracks events
    canUseAutomation: false, // Cannot be used in custom automations
    autoTrackEvents: ['form_page_view', 'form_page_change', 'form_submission'],
    settingsSchema: {
      pixelId: { type: 'string', required: true, label: 'Pixel ID' },
    },
  },
  linkedin_tag: {
    name: 'LinkedIn Insight Tag',
    canAutoTrigger: true, // Automatically tracks events
    canUseAutomation: false, // Cannot be used in custom automations
    autoTrackEvents: ['form_page_view', 'form_submission'],
    settingsSchema: {
      partnerId: { type: 'string', required: true, label: 'Partner ID' },
    },
  },
};

export const useIntegrationStore = defineStore('integration', {
  state: () => ({
    integrations: [],
    formIntegrationMappings: {}, // { formId: { integrationId: { enabled: true, settings: {} } } }
    loading: false,
    error: null,
    integrationTypes: INTEGRATION_TYPES,
  }),

  getters: {
    // Get integration settings for a specific form
    getIntegrationSettings: (state) => (formId, integrationId) => {
      return state.formIntegrationMappings[formId]?.[integrationId]?.settings || {};
    },

    // Check if integration is enabled for a form
    isIntegrationEnabled: (state) => (formId, integrationId) => {
      return !!state.formIntegrationMappings[formId]?.[integrationId]?.enabled;
    },

    // Get all integrations with their form-specific status and settings
    getFormIntegrations: (state) => (formId) => {
      return state.integrations.map((integration) => ({
        ...integration,
        enabled: !!state.formIntegrationMappings[formId]?.[integration.id]?.enabled,
        formSettings: state.formIntegrationMappings[formId]?.[integration.id]?.settings || {},
      }));
    },

    // Get only enabled integrations for a form
    getEnabledIntegrations: (state) => (formId) => {
      return state.integrations.filter(
        (integration) => state.formIntegrationMappings[formId]?.[integration.id]?.enabled
      );
    },
  },

  actions: {
    async fetchIntegrations() {
      try {
        this.loading = true;
        const response = await axios.get('/integrations');
        this.integrations = response.data.data;

        // Update formIntegrationMappings from the included formConnections
        this.formIntegrationMappings = {};
        this.integrations.forEach((integration) => {
          Object.entries(integration.formConnections).forEach(([formId, connection]) => {
            if (!this.formIntegrationMappings[formId]) {
              this.formIntegrationMappings[formId] = {};
            }
            this.formIntegrationMappings[formId][integration.id] = {
              enabled: connection.enabled,
              settings: connection.settings || {},
            };
          });
        });

        return response.data;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async fetchFormIntegrations(integrationId) {
      try {
        const response = await axios.get(`/integrations/${integrationId}/forms`);
        // Update the formIntegrationMappings state
        const mappings = response.data.reduce((acc, mapping) => {
          if (!acc[mapping.formId]) acc[mapping.formId] = {};
          acc[mapping.formId][mapping.integrationId] = {
            enabled: mapping.enabled,
            settings: mapping.settings || {},
          };
          return acc;
        }, {});
        this.formIntegrationMappings = mappings;
        return response.data;
      } catch (error) {
        console.error('Failed to fetch form integrations:', error);
        throw error;
      }
    },

    async createIntegration(integrationData, formConnections) {
      try {
        this.loading = true;
        const integrationType = INTEGRATION_TYPES[integrationData.type];
        const userStore = useUserStore();

        const payload = {
          ...integrationData,
          formConnections: Object.entries(formConnections || {})
            .filter(([_, enabled]) => enabled)
            .reduce((acc, [formId]) => {
              acc[formId] = { enabled: true };
              return acc;
            }, {}),
        };

        const response = await axios.post('/integrations', payload);
        const newIntegration = response.data.data;
        this.integrations.push(newIntegration);

        // Then create form connections if any
        Object.entries(formConnections).forEach(([formId, enabled]) => {
          if (!this.formIntegrationMappings[formId]) {
            this.formIntegrationMappings[formId] = {};
          }
          if (enabled) {
            this.formIntegrationMappings[formId][newIntegration.id] = {
              enabled: true,
              settings: {},
            };
          }
        });
        return response;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateIntegration(integrationId, updateData) {
      try {
        this.loading = true;
        const response = await axios.put(`/integrations/${integrationId}`, updateData);

        const index = this.integrations.findIndex((i) => i.id === integrationId);
        if (index !== -1) {
          this.integrations[index] = response.data.data;
        }

        return response;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteIntegration(integrationId) {
      try {
        const response = await axios.delete(`/integrations/${integrationId}`);
        if (response.status === 200) {
          this.integrations = this.integrations.filter((i) => i.id !== integrationId);
        }
        return response;
      } catch (error) {
        this.error = error.message;
        throw error;
      }
    },

    // Add/remove automatic form triggers
    async updateAutoTriggerForms(integrationId, { formId, enabled }) {
      try {
        const integration = this.integrations.find((i) => i.id === integrationId);
        if (!integration) throw new Error('Integration not found');

        if (enabled) {
          // Add form to auto-trigger list
          integration.autoTriggerForms = [...new Set([...integration.autoTriggerForms, formId])];
        } else {
          // Remove form from auto-trigger list
          integration.autoTriggerForms = integration.autoTriggerForms.filter((id) => id !== formId);
        }

        await axios.put(`/integrations/${integrationId}`, integration);
        return integration;
      } catch (error) {
        this.error = error.message;
        throw error;
      }
    },

    async toggleIntegration(formId, integrationId, enabled) {
      try {
        const response = await axios.post(`/integrations/${integrationId}/forms/${formId}/toggle`, {
          enabled: enabled,
        });

        // Update local state
        if (!this.formIntegrationMappings[formId]) {
          this.formIntegrationMappings[formId] = {};
        }

        if (enabled) {
          // Enable integration
          this.formIntegrationMappings[formId][integrationId] = {
            enabled: true,
            settings: {},
          };

          // Also update the integration's formConnections
          const integration = this.integrations.find((i) => i.id === integrationId);
          if (integration) {
            if (!integration.formConnections) integration.formConnections = {};
            integration.formConnections[formId] = { enabled: true, settings: {} };
          }
        } else {
          // Disable integration
          delete this.formIntegrationMappings[formId][integrationId];

          // Also update the integration's formConnections
          const integration = this.integrations.find((i) => i.id === integrationId);
          if (integration?.formConnections) {
            delete integration.formConnections[formId];
          }
        }
        return response.data;
      } catch (error) {
        console.error('Failed to toggle integration:', error);
        throw error;
      }
    },

    async updateIntegrationFormSettings(formId, integrationId, settings) {
      try {
        const response = await axios.put(
          `/integrations/${integrationId}/forms/${formId}/settings`,
          {
            settings,
          }
        );

        // Update local state
        if (!this.formIntegrationMappings[formId]) {
          this.formIntegrationMappings[formId] = {};
        }
        if (!this.formIntegrationMappings[formId][integrationId]) {
          this.formIntegrationMappings[formId][integrationId] = { enabled: true };
        }
        this.formIntegrationMappings[formId][integrationId].settings = settings;

        return response.data;
      } catch (error) {
        console.error('Failed to update integration form settings:', error);
        throw error;
      }
    },
  },
});
