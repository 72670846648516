<template>
  <div class="submission-kanban">
    <div class="submission-kanban__columns">
      <div v-for="status in statuses" :key="status.value" class="submission-kanban__column">
        <h3 class="submission-kanban__title">{{ status.label }}</h3>
        <draggable
          :list="submissions[status.value]"
          group="submissions"
          item-key="id"
          @change="(evt) => handleDragChange(evt, status.value)"
        >
          <template #item="{ element }">
            <div class="submission-kanban__card-wrapper">
              <div class="submission-kanban__card" @click="$emit('card-click', element)">
                <p class="submission-kanban__card-id">{{ element.leadId }}</p>
                <p class="submission-kanban__card-date">
                  {{ formatDate(element.metadata.submittedAt) }}
                </p>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate } from '@/utils/date';
  import draggable from 'vuedraggable';

  export default {
    components: {
      draggable,
    },

    props: {
      submissions: {
        type: Object,
        required: true,
      },
      statuses: {
        type: Array,
        required: true,
      },
    },

    emits: ['card-click', 'status-change'],

    setup(props, { emit }) {
      const handleDragChange = (evt, newStatus) => {
        if (!evt.added && !evt.moved) return;

        const submission = evt.added?.element || evt.moved?.element;
        if (submission) {
          emit('status-change', submission, newStatus);
        }
      };

      return {
        handleDragChange,
        formatDate,
      };
    },
  };
</script>

<style scoped lang="scss">
  .submission-kanban {
    overflow-x: auto;

    &__columns {
      display: flex;
      gap: 20px;
      min-height: 500px;
    }

    &__column {
      flex: 1;
      width: 100%;
      max-width: 350px;
      border-radius: 8px;
      padding: 16px;
      background: var(--slate-200);
      border-radius: 0.5rem;
    }

    &__title {
      margin-bottom: 16px;
    }
    &__card-wrapper {
      &.sortable-ghost {
        .submission-kanban__card {
          opacity: 0.8;
        }
      }
      &.sortable-drag {
        .submission-kanban__card {
          transform: rotate(-3deg);
          opacity: 0.8;
        }
      }
    }

    &__card {
      background: white;
      padding: 1.5rem;
      margin-bottom: 8px;
      border-radius: 0.5rem;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      &.sortable-ghost {
        transform: rotate(3deg);
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    &__card-id {
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--slate-300);
    }
    &__card-date {
      font-size: 0.8125rem;
      padding-top: 1rem;
    }
  }
</style>
