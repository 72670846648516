import { watch } from 'vue';
import { debounce } from 'lodash';
import { useValidation } from '@/composables/useValidation';

export function useSettingsForm(props, emit, inputsConfig) {
  const { inputs, validateForm, onValidate, markTouched } = useValidation(inputsConfig);

  let programmaticUpdate = false;

  const saveDebounced = debounce((newInputs) => {
    if (!programmaticUpdate) {
      const allValid = validateForm();
      Object.keys(inputs.value).forEach(key => markTouched(key));

      if (allValid) {
        const newSettings = Object.keys(newInputs).reduce((acc, key) => {
          acc[key] = newInputs[key].binding;
          return acc;
        }, {});
        emit('save-settings', newSettings);
      }
    }
  }, 300);

  // Watch for changes and prop syncing
  watch(inputs, saveDebounced, { deep: true });
  
  watch(props.element, (newElement) => {
    programmaticUpdate = true;
    Object.keys(inputs.value).forEach(key => {
      if (newElement.props[key] !== undefined) {
        inputs.value[key].binding = newElement.props[key];
      }
    });
    programmaticUpdate = false;
  });

  return {
    inputs,
    onValidate,
    validateForm,
    handleValidation: (field, error) => {
      inputs.value[field].error = error;
    },
    updateInputs: (newInputs) => {
      Object.assign(inputs, newInputs);
    }
  };
}