<template>
  <div class="name-settings">
    <BaseSettings
      :inputs="inputs"
      variant="simple"
      @validate="handleValidation"
      @update:inputs="updateInputs"
    />
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BaseSettings from './BaseSettings.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useSettingsForm } from '@/composables/useSettingsForm';
  import { createInputConfig, INPUT_FEATURES } from '@/config/baseInputConfig';

  export default defineComponent({
    name: 'NameSettings',

    components: {
      BaseSettings,
      TextInput,
    },

    props: {
      element: {
        type: Object,
        required: true,
      },
    },

    emits: ['save-settings'],

    setup(props, { emit }) {
      // Get base config with desired features
      const baseConfig = createInputConfig(props, [INPUT_FEATURES.PLACEHOLDER]);

      // Add NameSettings specific fields
      const inputsConfig = {
        ...baseConfig,
      };

      const { inputs, handleValidation, updateInputs } = useSettingsForm(props, emit, inputsConfig);

      return {
        inputs,
        handleValidation,
        updateInputs,
      };
    },
  });
</script>
