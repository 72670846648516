<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError && touched,
      'form-group--has-success': !localError && touched,
      'form-group--disabled': disabled,
    }"
  >
    <div class="sf-input sf-input--toggle">
      <div class="toggle">
        <input
          type="checkbox"
          :id="id"
          :checked="modelValue"
          @change="onChange"
          @blur="onBlur"
          :disabled="disabled"
        />
        <label :for="id">
          <span class="toggle__dot"></span>
          <span class="toggle__area">Switch label</span>
        </label>
      </div>
      <span class="sf-input__label">{{ label }}</span>
    </div>
    <span v-if="localError && touched" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { validateInput } from '@/utils/validation';

  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: Boolean,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const onChange = (event) => {
        touched.value = true;
        emit('update:modelValue', event.target.checked);
        if (props.realTimeValidation) {
          validate();
        }
      };

      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        onChange,
        onBlur,
        validate,
        localError,
        touched,
      };
    },
  };
</script>

<style scoped lang="scss">
  .toggle {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 44px;
    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
      &:checked + label {
        background-color: var(--brand-500);
      }
    }
    label {
      display: block;
      height: 1.5rem;
      cursor: pointer;
      overflow: hidden;
      border-radius: 9999px;
      background-color: var(--slate-200);
      & > span {
        margin: 0 !important;
      }
      & > span:first-child {
        position: absolute;
        display: block;
        border-radius: 9999px;
        width: 20px;
        height: 20px;
        top: 2px;
        left: 2px;
        right: 50%;
        transition: all 0.15s ease-out;
        background-color: var(--white);
      }
      & > span:nth-child(2) {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
    }
    input[type='checkbox']:checked {
      & + label {
        background-color: var(--brand-500);
      }
      & + label > span:first-child {
        left: 22px;
      }
    }
  }
</style>
