<template>
    <div class="loading" :class="{['loading--color-'+color]: color}">
            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" /></svg>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: {
        size: {
            type: String,
            default: 'md'
        },
        color: {
            type: String,
            default: 'brand'
        }
    }
};
</script>

<style lang="scss" scoped>
.loading {
    width: 1rem;
    height: 1rem;
    position: relative;
    color: var(--brand-500);
    &--color- {
        &light {
            color: var(--slate-200);
        }
    }
    svg {
        animation: spin 1s linear infinite;
        fill: currentColor;
        width: 1rem;
        height: 1rem;
    }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>