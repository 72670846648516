import { ref } from 'vue';
import { validateInput } from '@/utils/validation'; // Assume this is your validation function

export function useValidation(initialValues) {
  const inputs = ref(initialValues);

  const onValidate = (key, errorMessage) => {
    inputs.value[key].error = errorMessage;
  };

  const validateForm = () => {
    let isValid = true;
    Object.keys(inputs.value).forEach((key) => {
      const input = inputs.value[key];
      // Skip validation if field has conditional validation and condition is false
      if (input.conditionalValidation && !input.conditionalValidation()) {
        return;
      }
      if (!validateField(key)) {
        isValid = false;
      }
    });
    return isValid;
  };

  const validateField = (field) => {
    const input = inputs.value[field];
    // Skip validation if field has conditional validation and condition is false
    if (input.conditionalValidation && !input.conditionalValidation()) {
      input.error = ''; // Clear any existing errors
      return true;
    }
    const errorMessage = validateInput(input.binding, input.rules);
    input.error = errorMessage;
    return !errorMessage;
  };

  const markTouched = (field) => {
    if (inputs.value[field]) {
      inputs.value[field].touched = true;
    }
  };

  return {
    inputs,
    validateForm,
    onValidate,
    validateField,
    markTouched,
  };
}
