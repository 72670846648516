<template>
  <div class="form-element">
    <component
      :is="getElementComponent(element.type)"
      v-bind="element.props"
      :modelValue="formData[element.id]"
      :error="errors ? errors[element.id] : null"
      :touched="touched[element.id]"
      @update:modelValue="(value) => handleInput(element, value)"
      @blur="handleBlur(element.id)"
    />
    <div class="form-element__error" v-if="errors">
      {{ errors }}
    </div>
  </div>
</template>

<script>
  import EmailElement from '@/components/form/elements/EmailElement.vue';
  import SingleChoiceElement from '@/components/form/elements/SingleChoiceElement.vue';
  import MultiChoiceElement from '@/components/form/elements/MultiChoiceElement.vue';
  import ShortAnswerElement from '@/components/form/elements/ShortAnswerElement.vue';
  import NameElement from '@/components/form/elements/NameElement.vue';
  import PhoneElement from '@/components/form/elements/PhoneElement.vue';
  import NumberElement from '@/components/form/elements/NumberElement.vue';

  export default {
    name: 'FormElement',

    components: {
      EmailElement,
      SingleChoiceElement,
      MultiChoiceElement,
      ShortAnswerElement,
      NameElement,
      PhoneElement,
      NumberElement,
    },

    props: {
      element: {
        type: Object,
        required: true,
      },
      formData: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        default: null,
      },
      touched: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: ['update:formData', 'field-blur'],

    setup(props, { emit }) {
      const getElementComponent = (type) => {
        const components = {
          email: EmailElement,
          'single-choice': SingleChoiceElement,
          'multiple-choice': MultiChoiceElement,
          name: NameElement,
          number: NumberElement,
          phone: PhoneElement,
          'short-answer': ShortAnswerElement,
        };
        return components[type] || ShortAnswerElement;
      };
      const handleInput = (element, value) => {
        emit('update:formData', {
          ...props.formData,
          [element.id]: value,
        });
      };

      const handleBlur = (elementId) => {
        emit('field-blur', elementId);
      };

      return {
        getElementComponent,
        handleInput,
        handleBlur,
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-element {
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;

    &:last-child {
      margin-bottom: 0;
    }

    // Add error state styling if needed
    &--error {
      border-color: var(--error-500);
    }

    // Add touched state styling if needed
    &--touched {
      // Your styles here
    }

    &__error {
      color: var(--red-500);
      font-size: 0.875rem;
      margin-top: 0.75rem;
    }
  }
</style>
