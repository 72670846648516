export const TRIGGER_TYPES = [
  {
    label: 'Form Submission',
    value: 'form_submission',
  },
  {
    label: 'Lead Status Change',
    value: 'status_change',
  },
  { label: 'Scheduled Event', value: 'scheduled_event' },
];
