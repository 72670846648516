<template>
  <div class="block-conditions-form">
    <form action="#" @submit.prevent="">
      <div class="block-conditions-form__operator">
        <select
          v-model="currentBlockForm.operator.binding"
          :error="currentBlockForm.operator.error"
          class="form-select"
        >
          <option value="any">Any of these conditions are met</option>
          <option value="all">All of these conditions are met</option>
        </select>
      </div>
      <div class="block-conditions-form__rules">
        <div
          v-for="(rule, index) in currentBlockForm.rules"
          :key="index"
          class="block-conditions-form__rule"
        >
          <select
            v-model="rule.elementId.binding"
            :error="rule.elementId.error"
            class="form-select"
          >
            <option value="">Select field</option>
            <option v-for="element in availableElements" :key="element.id" :value="element.id">
              {{ element.props.label }}
            </option>
          </select>

          <select
            v-model="rule.operation.binding"
            :error="rule.operation.error"
            class="form-select"
          >
            <option value="equals">Equals</option>
            <option value="not_equals">Does not equal</option>
            <option value="contains">Contains</option>
            <option value="greater_than">Greater than</option>
            <option value="less_than">Less than</option>
          </select>

          <template
            v-if="
              getElementType(rule.elementId.binding) === 'multiple-choice' ||
              getElementType(rule.elementId.binding) === 'single-choice'
            "
          >
            <select v-model="rule.value.binding" :error="rule.value.error" class="form-select">
              <option value="">Select option</option>
              <option
                v-for="option in getElementOptions(rule.elementId.binding)"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </template>
          <input
            v-else
            v-model="rule.value.binding"
            :error="rule.value.error"
            placeholder="Enter value"
            class="form-input"
          />

          <BaseIcon
            name="trash"
            @click="removeRule(index)"
            size="16"
            class="block-conditions-form__rule-remove"
          />
        </div>

        <button type="button" class="block-conditions-form__add" @click="addRule">
          <BaseIcon name="plus" size="16" />
          Add condition
        </button>
      </div>

      <div class="block-conditions-form__actions">
        <Btn
          type="submit"
          text="Save!"
          :loading="isLoading"
          :disabled="isDeleting"
          @click.prevent="handleSubmit"
          variant="secondary"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { validateInput } from '@/utils/validation';

  export default {
    name: 'BlockConditionsModalForm',

    components: {
      BaseIcon,
      Btn,
    },

    props: {
      block: {
        type: Object,
        required: true,
      },
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },

    setup(props, { emit }) {
      const formStore = useFormStore();
      const currentBlockForm = ref(null);
      const isLoading = ref(false);
      const availableElements = computed(() => {
        const allElements = formStore.getAllElements();
        return allElements.filter((element) => element.blockId !== props.block.id);
      });

      watch(
        () => props.block,
        (newBlock) => {
          if (newBlock) {
            currentBlockForm.value = {
              operator: {
                binding: newBlock.conditions?.operator || 'any',
                error: '',
                rules: ['required'],
              },
              rules: (newBlock.conditions?.rules || []).map((rule) => ({
                elementId: {
                  binding: rule.elementId || '',
                  error: '',
                  rules: ['required'],
                },
                operation: {
                  binding: rule.operation || 'equals',
                  error: '',
                  rules: ['required'],
                },
                value: {
                  binding: rule.value || '',
                  error: '',
                  rules: ['required'],
                },
              })),
            };
          }
        },
        { immediate: true }
      );
      console.log(currentBlockForm.value.rules);
      const validateForm = () => {
        let isValid = true;

        // Validate operator
        currentBlockForm.value.operator.error = validateInput(
          currentBlockForm.value.operator.binding,
          currentBlockForm.value.operator.rules
        );
        if (currentBlockForm.value.operator.error) isValid = false;

        // Validate rules
        currentBlockForm.value.rules.forEach((rule) => {
          Object.keys(rule).forEach((key) => {
            rule[key].error = validateInput(rule[key].binding, rule[key].rules);
            if (rule[key].error) isValid = false;
          });
        });

        return isValid;
      };

      const addRule = () => {
        currentBlockForm.value.rules.push({
          elementId: {
            binding: '',
            error: '',
            rules: ['required'],
          },
          operation: {
            binding: 'equals',
            error: '',
            rules: ['required'],
          },
          value: {
            binding: '',
            error: '',
            rules: ['required'],
          },
        });
      };

      const removeRule = (index) => {
        currentBlockForm.value.rules.splice(index, 1);
      };

      const handleSubmit = async () => {
        isLoading.value = true;
        const allFieldsValid = validateForm();

        if (allFieldsValid) {
          const formData = {
            operator: currentBlockForm.value.operator.binding,
            rules: currentBlockForm.value.rules.map((rule) => ({
              elementId: rule.elementId.binding,
              operation: rule.operation.binding,
              value: rule.value.binding,
            })),
          };
          props.onSubmit(formData);
        } else {
          const errors = {};
          if (currentBlockForm.value.operator.error) {
            errors.operator = currentBlockForm.value.operator.error;
          }
          currentBlockForm.value.rules.forEach((rule, index) => {
            Object.keys(rule).forEach((key) => {
              if (rule[key].error) {
                errors[`rule_${index}_${key}`] = rule[key].error;
              }
            });
          });
          props.onError(errors);
        }
        isLoading.value = false;
      };

      const getElementType = (elementId) => {
        const element = formStore.getAllElements().find((el) => el.id === elementId);
        return element?.type || '';
      };

      const getElementOptions = (elementId) => {
        const element = formStore.getAllElements().find((el) => el.id === elementId);
        return (element?.props?.choices || []).map((choice) => ({
          label: choice.content,
          value: choice.id,
        }));
      };

      return {
        currentBlockForm,
        isLoading,
        availableElements,
        addRule,
        removeRule,
        handleSubmit,
        getElementType,
        getElementOptions,
      };
    },
  };
</script>

<style scoped lang="scss">
  .block-conditions-form {
    &__operator {
      margin-bottom: 1.5rem;
    }

    &__rules {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1.5rem;
    }

    &__rule {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr auto;
      gap: 0.5rem;
      align-items: center;

      &-remove {
        cursor: pointer;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }

    &__add {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: var(--brand-500);
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        opacity: 0.8;
      }
    }

    &__actions {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  .form-select,
  .form-input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.375rem;
    border: 1px solid var(--slate-200);
    background-color: var(--white);
  }
</style>
