<template>
  <div class="elements-sidebar" v-if="activePage">
    <div class="elements-sidebar__inner">
      <div v-if="!activeElement">
        <h2>Available Elements</h2>
        <div class="elements-category" v-for="category in categorizedElements" :key="category.slug">
          <h4>{{ category.label }}</h4>
          <ul class="elements-grid">
            <li
              v-for="element in category.elements"
              :key="element.slug"
              class="elements-choice"
              :class="{ 'elements-choice--coming-soon': element.comingSoon }"
              @click="addElement(element)"
            >
              <div class="elements-choice__icon">
                <!-- <BaseIcon name="elements_text" size="32" /> -->
                <BaseIcon :name="`elements_${element.slug}`" />
              </div>
              <div class="elements-choice__label">{{ element.label }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <h2>Settings for {{ activeElement.label }}</h2>
        <component
          :is="settingsComponent"
          :element="activeElement"
          @save-settings="updateElementSettings"
          :key="activeElement.id"
        />

        <Btn @click="closeElementSettings" variant="outlined" class="mt-8">Close edit sidebar</Btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { uid } from 'uid';

  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import EmailSettings from '@/components/form/settings/EmailSettings.vue';
  import SingleChoiceSettings from '@/components/form/settings/SingleChoiceSettings.vue';
  import MultiChoiceSettings from '@/components/form/settings/MultiChoiceSettings.vue';
  import NameSettings from '@/components/form/settings/NameSettings.vue';
  import PhoneSettings from '@/components/form/settings/PhoneSettings.vue';
  import ShortAnswerSettings from '@/components/form/settings/ShortAnswerSettings.vue';
  import NumberSettings from '@/components/form/settings/NumberSettings.vue';

  export default {
    props: {
      activePage: {
        type: Object,
      },
      activeElementIndex: {
        type: Number,
      },
    },
    components: {
      BaseIcon,
      Btn,
      SingleChoiceSettings,
      MultiChoiceSettings,
      NameSettings,
      PhoneSettings,
      ShortAnswerSettings,
      EmailSettings,
      NumberSettings,
    },
    emits: ['save-settings', 'edit-element-settings'],
    setup(props) {
      const formStore = useFormStore();
      const availableElements = ref([
        {
          type: 'single-choice',
          label: 'Single Choice',
          slug: 'single_choice',
          category: 'choices',
        },
        {
          type: 'multiple-choice',
          label: 'Multiple Choice',
          slug: 'multiple_choice',
          category: 'choices',
        },
        {
          type: 'dropdown',
          label: 'Dropdown',
          slug: 'dropdown',
          category: 'choices',
          comingSoon: true,
        },
        {
          type: 'short-answer',
          label: 'Short Answer',
          slug: 'short_answer',
          category: 'collecting_data',
        },
        {
          type: 'long-answer',
          label: 'Long Answer',
          slug: 'long_answer',
          category: 'collecting_data',
          comingSoon: true,
        },
        { type: 'number', label: 'Number', slug: 'number', category: 'collecting_data' },
        {
          type: 'currency',
          label: 'Currency',
          slug: 'currency',
          category: 'collecting_data',
          comingSoon: true,
        },
        { type: 'name', label: 'Name', slug: 'name', category: 'contact_data' },
        { type: 'phone', label: 'Phone', slug: 'phone', category: 'contact_data' },
        { type: 'email', label: 'Email', slug: 'email', category: 'contact_data' },
        {
          type: 'address',
          label: 'Address',
          slug: 'address',
          category: 'contact_data',
          comingSoon: true,
        },
      ]);

      const categories = [
        { label: 'Choices', slug: 'choices' },
        { label: 'Collecting data', slug: 'collecting_data' },
        { label: 'Contact data', slug: 'contact_data' },
      ];

      const categorizedElements = computed(() => {
        return categories.map((category) => ({
          ...category,
          elements: availableElements.value.filter(
            (elements) => elements.category === category.slug
          ),
        }));
      });

      // Get the active element
      const activeElement = computed(() => formStore.activeElement);

      // Update settings
      const updateElementSettings = (newSettings) => {
        formStore.updateActiveElementSettings(newSettings);
      };

      const addElement = (elements) => {
        const newElement = {
          ...elements,
          id: uid(),
          props: {
            label: 'New element',
          },
        };
        formStore.addElement(newElement);
      };

      // The settings part, resolve the current settings form
      const settingsComponent = computed(() => {
        switch (activeElement.value.type) {
          case 'email':
            return EmailSettings;
          case 'single-choice':
            return SingleChoiceSettings;
          case 'multiple-choice':
            return MultiChoiceSettings;
          case 'name':
            return NameSettings;
          case 'phone':
            return PhoneSettings;
          case 'short-answer':
            return ShortAnswerSettings;
          case 'number':
            return NumberSettings;
          // Add other settings components...
          default:
            return null;
        }
      });

      const closeElementSettings = () => {
        formStore.unsetElements();
      };

      return {
        availableElements,
        categorizedElements,
        categories,
        activeElement,
        addElement,
        updateElementSettings,
        closeElementSettings,
        settingsComponent,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .elements-sidebar {
    width: 450px;
    background: var(--white);
    // height: 100%;
    overflow-y: auto;

    &__inner {
      padding: 2rem 1rem 5rem 1rem;
    }
    .elements-category {
      margin: 2rem 0;
      h4 {
        margin-bottom: 1rem;
      }
      .elements-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        list-style: none;
        margin: 0;
        list-style: none;
        .elements-choice {
          display: flex;
          flex-direction: column;
          padding: 0.75rem;
          border: 1px solid var(--slate-300);
          background-color: var(--slate-50);
          border-radius: 0.5rem;
          text-align: center;
          align-items: center;
          @include hover {
            border-color: var(--brand-500);
          }
          &__icon {
            width: 100%;
            aspect-ratio: 1/1;
            max-width: 2rem;
            max-height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-bottom: 0.5rem;
            color: var(--brand-600);
          }
          &__label {
            word-break: break-word;
            @include fontSize('sm');
          }
          &--coming-soon {
            pointer-events: none;
            border-color: var(--slate-200);
            color: var(--slate-500);
            .elements-choice__icon {
              opacity: 0.35;
            }
          }
        }
      }
    }
  }
</style>
