import { defineStore } from 'pinia';
import { useDesignStore } from '@/store/designStore';

import formIntegrationService from '@/services/formIntegrationService';

// import { watch } from 'vue';
import axios from 'axios';
import { debounce } from 'lodash';
import { uid } from 'uid';

export const usePublicStore = defineStore('public', {
  state: () => ({
    currentForm: { id: null, name: '', pages: [], settings: {}, designId: null },
    formIntegrations: [],
  }),
  getters: {},

  actions: {
    async fetchFormById(id) {
      try {
        const form = await axios.get(`/forms/${id}`);
        if (!form) {
          throw new Error('Form not found');
        }
        if (form.status === 200) {
          this.currentForm = form.data;
          this.formIntegrations = form.data.enabledIntegrations;

          // If form has a designId, load that design
          if (form.data.designId) {
            const designStore = useDesignStore();
            designStore.loadDesign(form.data.designId);
          }
        }

        return this.currentForm;
      } catch (error) {
        if (error.code === 'permission-denied') {
          throw new Error('This form is private or you do not have access to it');
        }
        throw new Error(`Error loading form: ${error.message}`);
      }
    },
    async submitForm(formId, formData) {
      try {
        // Submit form data to your backend
        const result = await axios.post(`/public/forms/${formId}/submit`, formData);
        // Trigger client-side integrations
        await formIntegrationService.trackClientEvent('form_submission', formData);

        return result;
      } catch (error) {
        console.error('Error submitting form:', error);
        throw error;
      }
    },
  },
});
