<template>
    <div class="form-group" :class="{'form-group--has-error': localError, 'form-group--has-success': !localError && touched}">
        <label :for="id">{{ label }}</label>
        <div class="file-upload">
            <!-- Image Preview with Change and Remove Buttons -->
            <div v-if="previewUrl && isImage" class="file-upload__preview">
                <img :src="previewUrl" alt="Preview" class="image-preview"/>
                <Btn @click="changeFile" icon="edit" variant="plain">Change</Btn>
                <Btn @click="removeFile" icon="trash" variant="plain">Remove</Btn>
            </div>

            <!-- File Name with Remove Button for non-image files -->
            <div v-else-if="previewUrl && !isImage" class="file-upload__file-info">
                <span>{{ fileName }}</span>
                <Btn @click="removeFile" icon="trash" variant="plain">Remove</Btn>
            </div>

            <!-- Upload Button if no file is uploaded -->
            <div v-else class="file-upload__upload">
                <Btn @click="triggerFileInput" icon="upload" variant="outlined">Upload</Btn>
            </div>

            <!-- Hidden File Input -->
            <input
                ref="fileInput"
                type="file"
                :id="id"
                @change="onFileChange"
                :accept="accept"
                style="display: none;"
            />
        </div>
        <span v-if="localError" class="form-group__error">{{ localError }}</span>
    </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { validateInput } from '@/utils/validation'; 
import axios from 'axios';
import { useUserStore } from '@/store/userStore';
import Btn from "@/components/partials/Btn.vue";

export default {
    components: {
        Btn
    },
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: String,
            required: true
        },
        error: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        realTimeValidation: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: 'image/*,.pdf' // Allow images and PDFs
        },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
        const userStore = useUserStore();
        const localError = ref(props.error);
        const touched = ref(false);
        const previewUrl = ref(props.modelValue);
        const fileName = ref('');
        const fileInput = ref(null);

        // Determine if the uploaded file is an image
        const isImage = computed(() => {
            return previewUrl.value && props.accept.includes('image') && previewUrl.value.match(/\.(jpeg|jpg|gif|png)$/) !== null;
        });

        const onFileChange = async (event) => {
            const file = event.target.files[0];
            if (!file) {
                return;
            }

            touched.value = true;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('userId', userStore.user.uid);
            formData.append('organizationId', userStore.currentOrganization.id);

            try {
                const response = await axios.post('/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data.url) {
                    previewUrl.value = response.data.url; // Show the image preview
                    emit('update:modelValue', response.data.url);
                } else {
                    localError.value = 'Upload failed';
                    emit('validate', localError.value);
                }
            } catch (error) {
                console.error("Upload failed:", error);
                localError.value = 'Upload failed';
                emit('validate', localError.value);
            }
        };

        const onBlur = () => {
            touched.value = true;
            validate();
        };

        const validate = () => {
            const errorMessage = validateInput(props.modelValue, props.rules);
            localError.value = errorMessage;
            emit('validate', errorMessage);
        };

        const changeFile = () => {
            fileInput.value.click();
        };

        const removeFile = () => {
            previewUrl.value = '';
            fileName.value = '';
            emit('update:modelValue', '');
        };

        const triggerFileInput = () => {
            fileInput.value.click();
        };

        watch(() => props.error, (newError) => {
            localError.value = newError;
        }, { immediate: true });

        return {
            onFileChange,
            onBlur,
            validate,
            localError,
            previewUrl,
            fileName,
            fileInput,
            isImage,
            changeFile,
            removeFile,
            triggerFileInput
        };
    }
};
</script>

<style scoped lang="scss">
.file-upload {

    &__preview {
        display: flex;
        align-items: center;
    }

    &__file-info {
        display: flex;
        align-items: center;
    }

    &__upload {
        display: flex;
        align-items: center;
    }

    .image-preview {
        max-width: 100px;
        max-height: 100px;
        object-fit: cover;
        margin-right: 10px;
    }
}
</style>