<template>
  <div class="automation-builder">
    <!-- <div class="automation-builder__header">
      <h2>Build your automation</h2>
      <div class="automation-builder__actions">
        <Btn @click="$emit('cancel')">Cancel</Btn>
        <Btn variant="primary" @click="handleSave" :loading="isSaving">Save</Btn>
      </div>
    </div> -->

    <div class="automation-builder__content">
      <VueFlow
        v-model="elements"
        @update:model-value="onElementsChange"
        :default-zoom="1.5"
        :min-zoom="0.2"
        :max-zoom="4"
        :auto-connect="true"
        class="automation-builder__flow"
      >
        <template #node-trigger="nodeProps">
          <TriggerNode v-bind="nodeProps" @add-node="addAction" />
        </template>
        <template #node-action="nodeProps">
          <ActionNode v-bind="nodeProps" @add-node="addAction" :currentAutomation="modelValue" />
        </template>

        <Controls />
        <Background pattern-color="#aaa" gap="8" />
        <Controls />
        <MiniMap />
      </VueFlow>
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch } from 'vue';
  import { VueFlow, useVueFlow } from '@vue-flow/core';
  import { Background } from '@vue-flow/background';
  import { Controls } from '@vue-flow/controls';
  import { MiniMap } from '@vue-flow/minimap';
  import '@vue-flow/core/dist/style.css';
  import '@vue-flow/core/dist/theme-default.css';
  import TriggerNode from './nodes/TriggerNode.vue';
  import ActionNode from './nodes/ActionNode.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useFormStore } from '@/store/formStore';
  import { debounce } from 'lodash-es'; // or your preferred debouncing utility

  export default {
    name: 'AutomationBuilder',

    components: {
      VueFlow,
      Background,
      Controls,
      MiniMap,
      TriggerNode,
      ActionNode,
      Btn,
    },

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
    },

    emits: ['update:modelValue', 'save', 'cancel'],

    setup(props, { emit }) {
      const formStore = useFormStore();
      const { addNodes, addEdges } = useVueFlow();
      const isSaving = ref(false);

      // Handle element changes with debounce
      const onElementsChange = debounce((newElements) => {
        internalElements.value = newElements;
        elements.value = newElements;

        // Convert elements back to automation format and emit update
        const trigger = newElements.find((el) => el.type === 'trigger');
        const steps = newElements.filter((el) => el.type === 'action');

        const updatedAutomation = {
          ...props.modelValue,
          name: props.modelValue?.name || 'New Automation',
          trigger: trigger || null,
          steps: steps || [],
          isActive: props.modelValue?.isActive ?? false,
        };

        // Only emit if there's an actual change
        if (JSON.stringify(updatedAutomation) !== JSON.stringify(props.modelValue)) {
          emit('update:modelValue', updatedAutomation);
        }
      }, 100); // 100ms debounce

      // Convert automation format to elements array for VueFlow
      const convertToElements = (automation) => {
        if (!automation) {
          return [
            {
              id: 'trigger-default',
              type: 'trigger',
              position: { x: 290, y: 50 },
              data: {
                type: 'form_submission',
                config: {
                  formId: null,
                },
              },
            },
          ];
        }

        const elements = [];

        if (automation.trigger) {
          elements.push({
            ...automation.trigger,
            type: 'trigger',
          });
        }

        if (automation.steps) {
          // First add all nodes
          const nodes = Object.values(automation.steps).map((step) => ({
            ...step,
            type: 'action',
          }));
          elements.push(...nodes);

          // Then create edges based on previousSteps
          nodes.forEach((node) => {
            if (node.data?.previousSteps?.length) {
              node.data.previousSteps.forEach((prevId) => {
                elements.push({
                  id: `e${prevId}-${node.id}`,
                  source: prevId,
                  target: node.id,
                });
              });
            }
          });
        }

        return elements;
      };

      // Initialize elements
      const elements = ref(convertToElements(props.modelValue));
      const internalElements = ref(convertToElements(props.modelValue));

      // Watch for prop changes
      watch(
        () => props.modelValue,
        (newValue) => {
          if (
            newValue &&
            JSON.stringify(convertToElements(newValue)) !== JSON.stringify(elements.value)
          ) {
            internalElements.value = convertToElements(newValue);
            elements.value = convertToElements(newValue);
          }
        },
        { deep: true }
      );

      const handleSave = async () => {
        isSaving.value = true;
        try {
          const trigger = internalElements.value.find((el) => el.type === 'trigger');
          const steps = internalElements.value.filter((el) => el.type === 'action');

          const updatedAutomation = {
            ...props.modelValue,
            name: props.modelValue?.name || 'New Automation',
            trigger: trigger || null,
            steps: steps || [],
            isActive: props.modelValue?.isActive ?? false,
          };

          await emit('save', updatedAutomation);
        } finally {
          isSaving.value = false;
        }
      };

      const addAction = (action) => {
        const previousNode = elements.value.find((el) => el.id === action.afterId);
        const previousNodeHeight = previousNode?.__vf?.height || 0; // Get node height from VueFlow internal data
        const newNodeId = `action-${Date.now()}`;

        previousNode.data.nextSteps = [newNodeId];
        const newNode = {
          id: newNodeId,
          type: 'action',
          position: {
            x: 290,
            y: previousNode
              ? previousNode.position.y + previousNode.dimensions.height + 30 // Position below previous node
              : 50,
          },
          data: {
            type: action.action,
            config: {},
            previousSteps: [action.afterId],
          },
        };
        addNodes(newNode);

        // If there's a previous node, create an edge to connect them
        if (previousNode) {
          const newEdge = {
            id: `e${previousNode.id}-${newNodeId}`,
            source: previousNode.id,
            target: newNodeId,
          };

          // Add the edge to the flow
          addEdges(newEdge);
        }
      };

      return {
        elements,
        onElementsChange,
        addAction,
        handleSave,
        isSaving,
        handleSave,
        isSaving,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .automation-builder {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);

    &__header {
      padding: 20px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin: 0;
      }
    }

    &__actions {
      display: flex;
      gap: 10px;
    }

    &__content {
      flex: 1;
      position: relative;
    }

    &__flow {
      height: 100%;
    }
  }

  :deep(.vue-flow) {
    background: #f8f8f8;
  }
  :deep(.vue-flow__panel) {
    background: #ffffff;
  }
</style>
