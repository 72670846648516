<template>
  <div class="organization-switcher-modal">
    <div class="organization-options">
      <div
        v-for="org in userStore.organizations"
        :key="org.id"
        class="organization-option"
        @click="handleOrgSwitch(org.id)"
        :class="{ 'organization-option--active': org.id === userStore.currentOrganization.id }"
        v-if="!isLoading"
      >
        <div class="organization-option__icon">
          <img v-if="org.avatar" :src="org.avatar" :alt="org.name" />
          <AvatarElement v-else :title="org.name ? org.name.substring(0, 2).toUpperCase() : ''" />
        </div>
        <h3>{{ org.name }}</h3>
      </div>
      <LoadingSpinner v-else />
      <Btn text="Add another organization" variant="secondary" @click="handleAddOrg" />
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useUserStore } from '@/store/userStore';
  import AvatarElement from '@/components/partials/Avatar.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useModalStore } from '@/store/modalStore';
  import AddOrganizationForm from './AddOrganizationForm.vue';
  export default {
    name: 'OrganizationSwitcherModal',

    components: {
      AvatarElement,
      LoadingSpinner,
      Btn,
      AddOrganizationForm,
    },
    setup(props, { emit }) {
      const userStore = useUserStore();
      const isLoading = ref(false);
      const modalStore = useModalStore();

      async function handleOrgSwitch(orgId) {
        isLoading.value = true;
        await userStore.switchOrganization(orgId);
        window.location.reload();
        isLoading.value = false;
        emit('submit');
      }

      function handleAddOrg() {
        const modalId = modalStore.addModal(AddOrganizationForm, {
          title: 'Add a new organization',
          description: 'Add a new organization to your account',
          size: 'md',
          onSubmit: async (data) => {
            modalStore.closeModal(modalId);
            emit('submit');
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      }

      return {
        userStore,
        handleOrgSwitch,
        handleAddOrg,
        isLoading,
      };
    },
  };
</script>

<style scoped lang="scss">
  .organization-switcher-modal {
    .organization-options {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .organization-option {
      padding: 20px;
      border: 1px solid var(--slate-200);
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover {
        border-color: var(--brand-500);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
      }

      &--active {
        background-color: var(--slate-50);
        border-color: var(--brand-500);
      }

      &__icon {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
          border-radius: 4px;
        }
      }
    }
  }
</style>
