<template>
  <div class="condition-config">
    <div v-if="isFormSubmission">
      <div v-if="showFormWarning" class="alert alert--warning">
        This automation requires a form submission trigger. Please add a form submission trigger
        before adding a condition.
      </div>
      <template v-else>
        <SelectInput
          :id="'field-select'"
          label="Select Field"
          :options="availableFields.map((f) => ({ label: f.label, value: f.value }))"
          v-model="localConfig.field"
          @update:model-value="emitUpdate"
        />

        <SelectInput
          :id="'operator-select'"
          label="Select Operator"
          :options="operators"
          v-model="localConfig.operator"
          @update:model-value="emitUpdate"
        />

        <TextInput
          id="compare-value"
          label="Compare Value"
          v-model="localConfig.value"
          placeholder="Enter value to compare against"
          @update:model-value="emitUpdate"
        />
      </template>
    </div>
    <div v-else class="alert alert--info">
      Conditions are only available for form submission triggers
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch } from 'vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useFormStore } from '@/store/formStore';

  export default {
    name: 'ConditionConfig',

    components: {
      SelectInput,
      TextInput,
    },

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
      currentAutomation: {
        type: Object,
        required: true,
      },
    },

    setup(props, { emit }) {
      const operators = [
        { label: 'Equals', value: 'equals' },
        { label: 'Not Equals', value: 'not_equals' },
        { label: 'Contains', value: 'contains' },
        { label: 'Greater Than', value: 'gt' },
        { label: 'Less Than', value: 'lt' },
      ];

      // Initialize local config
      const localConfig = ref({
        field: props.modelValue.field || '',
        operator: props.modelValue.operator || 'equals',
        value: props.modelValue.value || '',
      });

      const isFormSubmission = computed(
        () => props.currentAutomation.trigger.data.type === 'form_submission'
      );

      const formStore = useFormStore();
      const currentForm = computed(() => {
        const formId = props.currentAutomation.trigger.data.config.formId;
        if (!formId) return null;
        return formStore.forms.find((f) => f.id === formId);
      });

      const showFormWarning = computed(() => {
        return isFormSubmission.value && !currentForm.value;
      });

      const availableFields = computed(() => {
        if (!currentForm.value) return [];

        return currentForm.value.pages.flatMap((page) =>
          page.blocks.flatMap((block) =>
            block.elements.map((element) => ({
              label: element.props.label,
              value: element.id,
              type: element.type,
              pageId: page.id,
              blockId: block.id,
            }))
          )
        );
      });

      // Event handlers
      const emitUpdate = () => {
        emit('update:modelValue', { ...localConfig.value });
      };

      // Watch for external changes
      watch(
        () => props.modelValue,
        (newVal) => {
          localConfig.value = {
            field: newVal.field || '',
            operator: newVal.operator || 'equals',
            value: newVal.value || '',
          };
        },
        { deep: true }
      );

      return {
        localConfig,
        operators,
        isFormSubmission,
        emitUpdate,
        showFormWarning,
        currentForm,
        availableFields,
      };
    },
  };
</script>

<style scoped lang="scss">
  .condition-config {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .alert {
      font-style: italic;
      font-size: 0.875rem;
      a {
        text-decoration: underline;
      }
    }
  }
</style>
