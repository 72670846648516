<template>
  <div class="submission-table">
    <EasyDataTable
      :headers="headers"
      :items="submissions"
      :loading="loading"
      buttons-pagination
      @click-row="$emit('row-click', $event)"
    >
      <template #item-metadata.submittedAt="{ submittedAt }">
        {{ formatDate(submittedAt) }}
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
  import { formatDate } from '@/utils/date';
  import EasyDataTable from 'vue3-easy-data-table';
  import 'vue3-easy-data-table/dist/style.css';

  export default {
    components: {
      EasyDataTable,
    },

    props: {
      submissions: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['row-click'],

    setup() {
      const headers = [
        { text: 'Lead ID', value: 'leadId' },
        { text: 'Submitted At', value: 'metadata.submittedAt' },
        { text: 'Status', value: 'status' },
      ];

      return {
        headers,
        formatDate,
      };
    },
  };
</script>
