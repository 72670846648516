<template>
  <div class="message">{{ message }}</div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        required: true,
      },
    },
    setup() {},
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .message {
    padding: 0.25rem 0.5rem;
    background-color: var(--brand-100);
    border-radius: 4px;
    color: var(--brand-600);
    border: 1px solid var(--brand-500);
    @include fontSize('md');
  }
</style>
