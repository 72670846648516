<template>
  <div class="short-answer-settings">
    <BaseSettings :inputs="inputs" @validate="handleValidation" @update:inputs="updateInputs" />
    <LogicSettings
      :logicSettings="inputs.logic"
      @update:logicSettings="handleLogicSettingsUpdate"
    />
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  import { debounce } from 'lodash';

  import BaseSettings from './BaseSettings.vue';

  import { useValidation } from '@/composables/useValidation';

  export default {
    components: {
      BaseSettings,
      // TextInput,
      // CheckboxInput,
    },
    props: {
      element: {
        type: Object,
        required: true,
      },
    },
    emits: ['save-settings'],
    setup(props, { emit }) {
      // Combine localSettings and inputs into a single structure using useValidation
      const { inputs, validateForm, onValidate, markTouched } = useValidation({
        label: {
          id: 'label',
          label: 'Label',
          binding: props.element.props.label || '',
          error: '',
          rules: ['required', 'maxLength:255'],
          touched: false,
        },
        required: {
          id: 'required',
          label: 'Answer is required',
          binding: props.element.props.required || false,
          error: ref(''),
          touched: false,
        },
        description: {
          id: 'description',
          label: 'Description',
          binding: ref(''),
          error: ref(''),
          rules: [],
          touched: false,
        },
        logic: {
          logicEnabled: {
            id: 'logic-enabled',
            label: 'Enable Logic',
            binding: props.element.props.logic?.logicEnabled || false,
            error: ref(''),
            rules: [],
            touched: false,
          },
          logicType: {
            id: 'logic-type',
            label: 'Logic Type',
            binding: props.element.props.logic?.logicType || 'show_if',
            error: ref(''),
            rules: [],
            touched: false,
          },
          conditionLogic: {
            id: 'condition-logic',
            label: 'Condition Logic (AND/OR)',
            binding: props.element.props.logic?.conditionLogic || 'AND',
            error: ref(''),
            rules: [],
            touched: false,
          },
          conditions: {
            id: 'conditions',
            label: 'Conditions',
            binding: props.element.props.logic?.conditions || [
              {
                elementId: null,
                type: 'is',
                value: '',
              },
            ],
            error: ref(''),
            rules: [],
            touched: false,
          },
        },
      });

      const handleValidation = (field, error) => {
        // Update the error in the inputs object
        inputs.value[field].error = error;
      };

      const updateInputs = (newInputs) => {
        Object.assign(inputs, newInputs);
      };

      const handleLogicSettingsUpdate = (newLogicSettings) => {
        // inputs.value.logic = newLogicSettings;
        console.log('Updated Logic Settings:', newLogicSettings);
      };

      let programmaticUpdate = false;

      const saveDebounced = debounce((newInputs) => {
        if (!programmaticUpdate) {
          // Validate all inputs before saving
          const allValid = validateForm();
          Object.keys(inputs.value).forEach((key) => markTouched(key));
          if (allValid) {
            const newSettings = Object.keys(newInputs).reduce((acc, key) => {
              acc[key] = newInputs[key].binding;
              return acc;
            }, {});
            emit('save-settings', newSettings);
          } else {
            console.warn('Validation failed. Fix errors before saving.');
          }
        }
      }, 300);

      const saveSettings = () => {
        saveDebounced(inputs.value);
      };
      // Watch for changes in inputs and emit save event
      watch(
        inputs,
        (newInputs) => {
          saveDebounced(newInputs);
        },
        { deep: true }
      );

      // Sync inputs with prop changes
      watch(props.element, (newElement) => {
        programmaticUpdate = true;
        Object.keys(inputs.value).forEach((key) => {
          if (newElement.props[key] !== undefined) {
            inputs.value[key].binding = newElement.props[key];
          }
        });
        programmaticUpdate = false;
      });

      return {
        inputs,
        onValidate,
        saveSettings,
        validateForm,
        handleValidation,
        updateInputs,
        handleLogicSettingsUpdate,
      };
    },
  };
</script>

<style scoped>
  .single-choice-settings {
    display: flex;
    flex-direction: column;
  }

  .single-choice-settings label {
    margin-top: 10px;
    font-weight: bold;
  }

  .answer-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .answer-item textarea {
    flex: 1;
  }

  .answer-item button {
    margin-left: 10px;
  }

  button {
    margin-top: 10px;
    padding: 10px;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #36a572;
  }
</style>
