<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError && touched,
      'form-group--has-success': !localError && touched,
    }"
  >
    <div class="sf-input sf-input--select">
      <label :for="id">{{ label }}</label>
      <select :id="id" :value="modelValue" @change="onChange" :disabled="disabled" @blur="onBlur">
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
      <span v-if="localError && touched" class="form-group__error">{{ localError }}</span>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { validateInput } from '@/utils/validation'; // Make sure this is imported

  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: [String, Number],
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        required: true,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const onChange = (event) => {
        touched.value = true;
        emit('update:modelValue', event.target.value);
        if (props.realTimeValidation) {
          validate();
        }
      };

      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        onChange,
        onBlur,
        validate,
        localError,
        touched,
      };
    },
  };
</script>

<style scoped></style>
