<template>
  <div class="action-node">
    <div class="action-node__header">
      <div class="action-node__label">
        <BaseIcon
          :name="actionTypes.find((t) => t.value === nodeData.type)?.icon"
          size="12"
          class="icon"
        />
        <span class="label">{{ actionTypes.find((t) => t.value === nodeData.type)?.label }}</span>
      </div>
      <h3>{{ actionTypes.find((t) => t.value === nodeData.type)?.label }}</h3>
    </div>

    <div class="action-node__content">
      <ConditionConfig
        v-if="nodeData.type === 'condition'"
        v-model="nodeData.config"
        :currentAutomation="currentAutomation"
      />
      <DelayConfig v-if="nodeData.type === 'delay'" v-model="nodeData.config" />
      <TaskConfig v-if="nodeData.type === 'task'" v-model="nodeData.config" />
      <template v-if="nodeData.type === 'delay'">
        <i>Coming soon</i>
      </template>
    </div>

    <div class="action-node__footer" v-if="!hasConnections">
      <button class="add-node-btn" @click="addNewNode">
        <BaseIcon name="plus" size="12" />
      </button>
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch } from 'vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';

  import TaskConfig from '../configs/TaskConfig.vue';
  import ConditionConfig from '../configs/ConditionConfig.vue';
  import ActionPickerModal from '../modals/ActionPickerModal.vue';
  import { useModalStore } from '@/store/modalStore';
  import { useVueFlow } from '@vue-flow/core';

  export default {
    name: 'ActionNode',

    components: {
      BaseIcon,
      SelectInput,
      TaskConfig,
      ConditionConfig,
    },

    props: {
      id: {
        type: String,
        required: true,
      },
      data: {
        type: Object,
        required: true,
      },
      currentAutomation: {
        type: Object,
        required: true,
      },
    },

    emits: ['update', 'add-node'],

    setup(props, { emit }) {
      const nodeData = ref({ ...props.data });
      const modalStore = useModalStore();
      const { getOutgoers } = useVueFlow();

      const hasConnections = computed(() => {
        return getOutgoers({ id: props.id }).length > 0;
      });

      const actionTypes = [
        { value: 'condition', label: 'Condition', icon: 'split' },
        { value: 'task', label: 'Task', icon: 'bullseye' },
        { value: 'delay', label: 'Delay', icon: 'calendar-clock' },
      ];

      watch(
        nodeData,
        (newVal) => {
          emit('update', { id: props.id, data: newVal });
        },
        { deep: true }
      );

      const handleActionTypeChange = () => {
        nodeData.value.config = {};
      };

      const addNewNode = () => {
        const modalId = modalStore.addModal(ActionPickerModal, {
          title: 'Add Step',
          description: 'Add a new step to the automation',
          width: 'lg',
          onSubmit: (action) => {
            emit('add-node', { afterId: props.id, action });
            modalStore.closeModal(modalId);
          },
        });
      };

      return {
        nodeData,
        actionTypes,
        handleActionTypeChange,
        addNewNode,
        hasConnections,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';

  .action-node {
    background: #ffffff;
    border: 1px solid var(--slate-200);
    border-radius: 12px;
    padding: 1rem;
    width: 300px;
    color: var(--slate-900);

    &__header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
      @include fontSize('md');
      h3 {
        font-size: inherit;
      }
    }

    &__label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: var(--slate-50);
      border: 1px solid var(--slate-200);
      border-radius: 6px;
      padding: 0.25rem 0.5rem;
      @include fontSize('sm');

      .icon {
        color: var(--green-500);
      }

      .label {
        color: #666;
      }
    }

    &__badge {
      background: #333;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      font-size: 0.875rem;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__footer {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;

      .add-node-btn {
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        justify-content: center;

        @include hover {
          background: var(--slate-50);
          border-color: var(--brand-500);
        }
      }
    }
  }
</style>
