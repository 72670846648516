<template>
  <header class="page-header">
    <div class="page-header__content">
      <h1 class="page-header__title">{{ title }}</h1>
      <p v-if="description" class="page-header__description">{{ description }}</p>
    </div>
    <div class="page-header__actions">
      <slot name="actions"></slot>
    </div>
  </header>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style scoped lang="scss">
  .page-header {
    padding: 24px 0 48px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__content {
      flex: 1;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      color: var(--color-text-primary);
      margin-bottom: 8px;
    }

    &__description {
      font-size: 14px;
      color: var(--color-text-secondary);
      line-height: 1.5;
    }

    &__actions {
      margin-left: 24px;
    }
  }
</style>
