import axios from 'axios';
import { auth } from '@/services/firebase';

// Set the default baseURL
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

//Set up interceptor
axios.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
