<template>
  <div class="sf-el single-choice-input">
    <div class="sf-single-choice">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      <div
        class="sf-single-choice__grid"
        :class="{ [`sf-single-choice__grid--per-row-${per_row}`]: per_row }"
      >
        <div
          v-for="(choice, index) in choices"
          :key="index"
          class="choice"
          @click="selectChoice(index)"
          :class="{ 'is-selected': selectedChoice === choice.content }"
        >
          <input
            type="radio"
            :name="name"
            :value="choice.content"
            v-model="selectedChoice"
            class="radio-button"
            :id="'choice-' + index"
          />
          <label :for="'choice-' + index">
            <img :src="choice.image" class="choice__img" alt="Choice Image" v-if="choice.image" />
            <div class="choice__label">
              <span>
                {{ choice.content }}
              </span>
              <div class="choice__radio">
                <BaseIcon name="check" size="16" />
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import { watch, ref } from 'vue';
  export default {
    name: 'SingleChoiceElement',
    components: {
      BaseIcon,
    },
    props: {
      label: String,
      description: String,
      choices: Array,
      name: String,
      modelValue: String,
      per_row: [String, Number],
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const selectedChoice = ref(props.modelValue);
      watch(selectedChoice, (newValue) => {
        emit('update:modelValue', newValue);
      });
      const selectChoice = (index) => {
        selectedChoice.value = props.choices[index].content;
      };
      return {
        selectedChoice,
        selectChoice,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .sf-el {
  }

  .single-choice-input {
    display: grid;
    flex-direction: column;
  }

  .sf-single-choice {
    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 10px;
      &--per-row-1 {
        grid-template-columns: repeat(1, 1fr);
      }
      &--per-row-2 {
        grid-template-columns: repeat(2, 1fr);
      }
      &--per-row-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &--per-row-4 {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .choice {
    position: relative;
    border: 1px solid var(--uc-radio-border-color);
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    transition: border-color 0.3s ease;
    background-color: var(--uc-radio-background-color);
    display: flex;
    flex-direction: column;
    @include hover;
    min-height: 50px;
    &__img {
      width: 100%;
      height: auto;
      aspect-ratio: 3/2;
      background-color: var(--white);
      object-fit: cover;
      object-position: center;
    }
    label {
      flex-grow: 1;
      cursor: pointer;
    }
    span {
      display: block;
      // padding: 1rem;
    }
    .radio-button {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0;
      pointer-events: none;
    }
    &.is-selected {
      border-color: var(--uc-radio-checked-border-color);
    }
    input[type='radio']:checked + label .choice__radio {
      border-color: var(--uc-radio-checked-border-color);
      color: var(--uc-radio-checked-border-color);
    }

    @include hover {
      border-color: var(--uc-radio-hover-border-color);
    }
    &__label {
      position: relative;
      padding: 1rem 3rem;
      cursor: pointer;
    }
    &__radio {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5rem;
      background-color: var(--white);
      color: var(--slate-300);
      border: 1px solid var(--slate-200);
    }
  }
</style>
