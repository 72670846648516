<template>
  <div class="toolbar">
    <div class="container">
      <div class="toolbar__inner">
        <nav class="toolbar__nav">
          <div class="toolbar__organization">
            <div class="company-switcher" @click="toggleOrganizationSwitcher">
              <div class="company-switcher__header">
                <div class="company-switcher__logo">
                  <AvatarElement
                    :title="
                      currentOrganization.name
                        ? currentOrganization.name.substring(0, 2).toUpperCase()
                        : ''
                    "
                  />
                </div>
                <div class="company-switcher__name">{{ currentOrganization.name }}</div>
                <div class="company-switcher__chevrons">
                  <BaseIcon name="chevron" size="10" />
                  <BaseIcon name="chevron" size="10" />
                </div>
              </div>
            </div>
          </div>
          <ul class="toolbar__nav-list">
            <li>
              <router-link to="/all-flows">
                <BaseIcon name="flows" size="14" />
                All funnels
              </router-link>
            </li>
            <li>
              <router-link to="/integrations">
                <BaseIcon name="cubes" size="14" />
                Integrations
              </router-link>
            </li>
            <li>
              <router-link to="/automations">
                <BaseIcon name="wand" size="14" />
                Automations
              </router-link>
            </li>
            <li>
              <router-link to="/insights">
                <BaseIcon name="insights" size="14" />
                Insights
              </router-link>
            </li>
          </ul>
        </nav>
        <nav class="toolbar__nav">
          <ul class="toolbar__nav-list">
            <li><button @click="logOut">Sign out</button></li>
            <li class="toolbar__account">
              <AvatarElement :title="userInitial" />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useUserStore } from '@/store/userStore';
  import AvatarElement from '@/components/partials/Avatar.vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import { useModalStore } from '@/store/modalStore';
  import OrganizationSwitcherModal from '@/components/organizationSwitch/OrganizationSwitcherModal.vue';
  export default {
    name: 'ToolbarNav',
    components: {
      AvatarElement,
      BaseIcon,
      OrganizationSwitcherModal,
    },
    setup() {
      const userStore = useUserStore();
      const router = useRouter();
      const currentOrganization = computed(() => userStore.currentOrganization);
      const currentUser = computed(() => userStore.userData);
      const userInitial = computed(() => {
        if (userStore.userData && userStore.userData.firstName) {
          return userStore.userData.firstName.charAt(0) + userStore.userData.lastName.charAt(0);
        }
        return '';
      });

      const modalStore = useModalStore();
      function toggleOrganizationSwitcher() {
        const modalId = modalStore.addModal(OrganizationSwitcherModal, {
          title: 'Choose your organization',
          description: 'Choose which organization you would like to switch to',
          size: 'sm',
          onSubmit: async (data) => {
            modalStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      }

      async function logOut() {
        try {
          await userStore.logout();
          router.push('/login');
        } catch (error) {
          console.error('Error signing out: ', error);
        }
      }
      return { userInitial, logOut, currentOrganization, currentUser, toggleOrganizationSwitcher };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .toolbar {
    width: 100%;
    position: relative;
    height: 80px;
    background-color: #ffffff;
    border-bottom: 1px solid var(--slate-200);
    &__nav {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &__nav-list {
      display: flex;
      align-items: center;
      gap: 1rem;
      li {
        a {
          padding: 7px 10px;
          border-radius: 0.5rem;
          border: 1px solid transparent;
          color: var(--slate-500);
          display: flex;
          align-items: center;
          gap: 0.5rem;
          @include hover {
            cursor: pointer;
            background-color: var(--slate-50);
          }
          &.router-link-active {
            background-color: var(--slate-100);
            border-color: var(--slate-200);
            color: var(--slate-900);
          }
        }
        @include fontSize('md');
        font-weight: 500;
      }
    }
    &__inner {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
    }
  }
  .company-switcher {
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    margin-right: 3rem;
    @include hover {
      border-color: var(--slate-200);
      cursor: pointer;
      background-color: var(--slate-50);
      .company-switcher__chevrons {
        color: var(--slate-500);
      }
    }
    &__header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &__chevrons {
      display: flex;
      flex-direction: column;
      color: var(--slate-300);
      .icon {
        &:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
