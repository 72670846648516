<template>
    <div class="form-group" :class="{'form-group--has-error': localError && touched, 'form-group--has-success': !localError && touched}">
        <div class="sf-input sf-input--checkbox">
            <label :for="id">
                <input
                    type="checkbox"
                    :id="id"
                    :checked="modelValue"
                    @change="onChange"
                    @blur="onBlur"
                />
                <span>{{ label }}</span>
            </label>
        </div>
        <span v-if="localError && touched" class="form-group__error">{{ localError }}</span>
    </div>
  </template>
  
<script>
import { ref, watch } from 'vue';
import { validateInput } from '@/utils/validation'; 
  
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      modelValue: {
        type: Boolean,
        required: true
      },
      error: {
        type: String,
        default: ''
      },
      rules: {
        type: Array,
        default: () => []
      },
      realTimeValidation: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);
  
      const onChange = (event) => {
        touched.value = true;
        emit('update:modelValue', event.target.checked);
        if (props.realTimeValidation) {
          validate();
        }
      };
  
      const onBlur = () => {
        touched.value = true;
        validate();
      };
  
      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };
  
      watch(() => props.error, (newError) => {
        localError.value = newError;
      }, { immediate: true });
  
      return {
        onChange,
        onBlur,
        validate,
        localError,
        touched
      };
    }
};
</script>
  
<style scoped>

</style>
