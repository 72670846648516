<template>
  <div class="trigger-node">
    <div class="trigger-node__header">
      <div class="trigger-node__label">
        <BaseIcon name="zap" size="12" />
        <span>Trigger</span>
      </div>
      <h3>{{ triggerTypes.find((t) => t.value === nodeData.type)?.label || nodeData.type }}</h3>
    </div>

    <div class="trigger-node__content">
      <SelectInput
        :id="`trigger-type`"
        :label="'Trigger type'"
        v-model="nodeData.type"
        :options="triggerTypes"
        :disabled="hasConnections"
        @update:modelValue="handleTypeChange"
      />

      <template v-if="nodeData.type === 'form_submission'">
        <template v-if="forms.length">
          <SelectInput
            id="form-select"
            label="Select funnel"
            v-model="nodeData.config.formId"
            :options="
              forms.map((form) => ({
                value: form.id,
                label: form.name,
              }))
            "
          />
        </template>
        <template v-else>
          <div class="alert alert--info">
            No funnels found. First add a funnel at the
            <router-link :to="{ path: '/all-flows' }">funnels page</router-link>
            .
          </div>
        </template>
      </template>

      <template v-if="nodeData.type === 'status_change'">
        <SelectInput
          id="from-status"
          label="From status"
          v-model="nodeData.config.fromStatus"
          :options="dealStatuses"
        />
        <SelectInput
          id="to-status"
          label="To status"
          v-model="nodeData.config.toStatus"
          :options="dealStatuses.filter((status) => status.value !== 'new')"
        />
      </template>
      <template v-if="nodeData.type === 'scheduled_event'">
        <SelectInput
          id="frequency"
          label="Frequency"
          v-model="nodeData.config.frequency"
          :options="[
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
          ]"
        />

        <template v-if="nodeData.config.frequency === 'daily'">
          <SelectInput
            id="time"
            label="Time of Day"
            v-model="nodeData.config.timeOfDay"
            :options="[
              { value: '08:00', label: '8:00' },
              { value: '08:30', label: '8:30' },
              { value: '09:00', label: '9:00' },
              { value: '09:30', label: '9:30' },
              { value: '10:00', label: '10:00' },
              { value: '10:30', label: '10:30' },
              { value: '11:00', label: '11:00' },
              { value: '11:30', label: '11:30' },
              { value: '12:00', label: '12:00' },
              { value: '12:30', label: '12:30' },
              { value: '13:00', label: '13:00' },
              { value: '13:30', label: '13:30' },
              { value: '14:00', label: '14:00' },
              { value: '14:30', label: '14:30' },
              { value: '15:00', label: '15:00' },
              { value: '15:30', label: '15:30' },
              { value: '16:00', label: '16:00' },
              { value: '16:30', label: '16:30' },
              { value: '17:00', label: '17:00' },
              { value: '17:30', label: '17:30' },
              { value: '18:00', label: '18:00' },
              { value: '18:30', label: '18:30' },
              { value: '19:00', label: '19:00' },
              { value: '19:30', label: '19:30' },
              { value: '20:00', label: '20:00' },
              { value: '20:30', label: '20:30' },
              { value: '21:00', label: '21:00' },
              { value: '21:30', label: '21:30' },
              { value: '22:00', label: '22:00' },
              { value: '22:30', label: '22:30' },
              { value: '23:00', label: '23:00' },
              { value: '23:30', label: '23:30' },
              { value: '00:00', label: '00:00' },
              { value: '00:30', label: '00:30' },
              { value: '01:00', label: '01:00' },
              { value: '01:30', label: '01:30' },
              { value: '02:00', label: '02:00' },
              { value: '02:30', label: '02:30' },
              { value: '03:00', label: '03:00' },
              { value: '03:30', label: '03:30' },
              { value: '04:00', label: '04:00' },
              { value: '04:30', label: '04:30' },
              { value: '05:00', label: '05:00' },
              { value: '05:30', label: '05:30' },
              { value: '06:00', label: '06:00' },
              { value: '06:30', label: '06:30' },
              { value: '07:00', label: '07:00' },
              { value: '07:30', label: '07:30' },
            ]"
          />
        </template>

        <template v-if="nodeData.config.frequency === 'weekly'">
          <SelectInput
            id="days"
            label="Days of Week"
            v-model="nodeData.config.daysOfWeek"
            :options="[
              { value: 'monday', label: 'Monday' },
              { value: 'tuesday', label: 'Tuesday' },
              { value: 'wednesday', label: 'Wednesday' },
              { value: 'thursday', label: 'Thursday' },
              { value: 'friday', label: 'Friday' },
              { value: 'saturday', label: 'Saturday' },
              { value: 'sunday', label: 'Sunday' },
            ]"
            multiple
          />
          <SelectInput
            id="time"
            label="Time of Day"
            type="time"
            v-model="nodeData.config.timeOfDay"
            :options="[
              { value: '08:00', label: '8:00' },
              { value: '08:30', label: '8:30' },
              { value: '09:00', label: '9:00' },
              { value: '09:30', label: '9:30' },
              { value: '10:00', label: '10:00' },
              { value: '10:30', label: '10:30' },
              { value: '11:00', label: '11:00' },
              { value: '11:30', label: '11:30' },
              { value: '12:00', label: '12:00' },
              { value: '12:30', label: '12:30' },
              { value: '13:00', label: '13:00' },
              { value: '13:30', label: '13:30' },
              { value: '14:00', label: '14:00' },
              { value: '14:30', label: '14:30' },
              { value: '15:00', label: '15:00' },
              { value: '15:30', label: '15:30' },
              { value: '16:00', label: '16:00' },
              { value: '16:30', label: '16:30' },
              { value: '17:00', label: '17:00' },
              { value: '17:30', label: '17:30' },
              { value: '18:00', label: '18:00' },
              { value: '18:30', label: '18:30' },
              { value: '19:00', label: '19:00' },
              { value: '19:30', label: '19:30' },
              { value: '20:00', label: '20:00' },
              { value: '20:30', label: '20:30' },
              { value: '21:00', label: '21:00' },
              { value: '21:30', label: '21:30' },
              { value: '22:00', label: '22:00' },
              { value: '22:30', label: '22:30' },
              { value: '23:00', label: '23:00' },
              { value: '23:30', label: '23:30' },
              { value: '00:00', label: '00:00' },
              { value: '00:30', label: '00:30' },
              { value: '01:00', label: '01:00' },
              { value: '01:30', label: '01:30' },
              { value: '02:00', label: '02:00' },
              { value: '02:30', label: '02:30' },
              { value: '03:00', label: '03:00' },
              { value: '03:30', label: '03:30' },
              { value: '04:00', label: '04:00' },
              { value: '04:30', label: '04:30' },
              { value: '05:00', label: '05:00' },
              { value: '05:30', label: '05:30' },
              { value: '06:00', label: '06:00' },
              { value: '06:30', label: '06:30' },
              { value: '07:00', label: '07:00' },
              { value: '07:30', label: '07:30' },
            ]"
          />
        </template>

        <template v-if="nodeData.config.frequency === 'monthly'">
          <SelectInput
            id="day"
            label="Day of Month"
            v-model="nodeData.config.dayOfMonth"
            :options="
              Array.from({ length: 31 }, (_, i) => ({
                value: i + 1,
                label: `${i + 1}${['st', 'nd', 'rd'][i] || 'th'}`,
              }))
            "
          />
          <SelectInput
            id="time"
            label="Time of Day"
            type="time"
            v-model="nodeData.config.timeOfDay"
            :options="[
              { value: '08:00', label: '8:00' },
              { value: '08:30', label: '8:30' },
              { value: '09:00', label: '9:00' },
              { value: '09:30', label: '9:30' },
              { value: '10:00', label: '10:00' },
              { value: '10:30', label: '10:30' },
              { value: '11:00', label: '11:00' },
              { value: '11:30', label: '11:30' },
              { value: '12:00', label: '12:00' },
              { value: '12:30', label: '12:30' },
              { value: '13:00', label: '13:00' },
              { value: '13:30', label: '13:30' },
              { value: '14:00', label: '14:00' },
              { value: '14:30', label: '14:30' },
              { value: '15:00', label: '15:00' },
              { value: '15:30', label: '15:30' },
              { value: '16:00', label: '16:00' },
              { value: '16:30', label: '16:30' },
              { value: '17:00', label: '17:00' },
              { value: '17:30', label: '17:30' },
              { value: '18:00', label: '18:00' },
              { value: '18:30', label: '18:30' },
              { value: '19:00', label: '19:00' },
              { value: '19:30', label: '19:30' },
              { value: '20:00', label: '20:00' },
              { value: '20:30', label: '20:30' },
              { value: '21:00', label: '21:00' },
              { value: '21:30', label: '21:30' },
              { value: '22:00', label: '22:00' },
              { value: '22:30', label: '22:30' },
              { value: '23:00', label: '23:00' },
              { value: '23:30', label: '23:30' },
              { value: '00:00', label: '00:00' },
              { value: '00:30', label: '00:30' },
              { value: '01:00', label: '01:00' },
              { value: '01:30', label: '01:30' },
              { value: '02:00', label: '02:00' },
              { value: '02:30', label: '02:30' },
              { value: '03:00', label: '03:00' },
              { value: '03:30', label: '03:30' },
              { value: '04:00', label: '04:00' },
              { value: '04:30', label: '04:30' },
              { value: '05:00', label: '05:00' },
              { value: '05:30', label: '05:30' },
              { value: '06:00', label: '06:00' },
              { value: '06:30', label: '06:30' },
              { value: '07:00', label: '07:00' },
              { value: '07:30', label: '07:30' },
            ]"
          />
        </template>
      </template>
    </div>
    <div class="trigger-node__footer" v-if="canAddNewNode">
      <button class="add-node-btn" @click="addNewNode">
        <BaseIcon name="plus" size="12" />
      </button>
    </div>
  </div>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import ActionPickerModal from '../modals/ActionPickerModal.vue';
  import { useFormStore } from '@/store/formStore';
  import { useModalStore } from '@/store/modalStore';
  import { TRIGGER_TYPES } from '../constants/triggers';
  import { DEAL_STATUSES } from '@/constants/statuses';
  import { useVueFlow } from '@vue-flow/core';
  import { useValidation } from '@/composables/useValidation';

  export default {
    name: 'TriggerNode',

    props: {
      id: {
        type: String,
        required: true,
      },
      data: {
        type: Object,
        required: true,
      },
    },

    emits: ['update', 'add-node'],
    components: { BaseIcon, SelectInput, ActionPickerModal },
    setup(props, { emit }) {
      const nodeData = ref({ ...props.data });
      const { getOutgoers } = useVueFlow();
      const formStore = useFormStore();
      const forms = computed(() => formStore.forms);
      const modalStore = useModalStore();

      // Add computed property to check for connections
      const hasConnections = computed(() => {
        return getOutgoers({ id: props.id }).length > 0;
      });

      watch(
        nodeData,
        (newVal) => {
          emit('update', { id: props.id, data: newVal });
        },
        { deep: true }
      );

      const handleTypeChange = () => {
        nodeData.value.config = {};
      };

      const addNewNode = () => {
        const modalId = modalStore.addModal(ActionPickerModal, {
          title: 'Add Step',
          description: 'Add a new step to the automation',
          width: 'lg',
          onSubmit: (action) => {
            emit('add-node', { afterId: props.id, action });
            modalStore.closeModal(modalId);
          },
        });
      };

      const canAddNewNode = computed(() => {
        return (
          !hasConnections.value &&
          !(nodeData.value.type === 'form_submission' && forms.value.length === 0)
        );
      });

      return {
        nodeData,
        forms,
        handleTypeChange,
        triggerTypes: TRIGGER_TYPES,
        dealStatuses: DEAL_STATUSES,
        forms,
        addNewNode,
        hasConnections,
        canAddNewNode,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .trigger-node {
    background: #ffffff;
    border: 1px solid var(--slate-200);
    border-radius: 12px;
    padding: 1rem;
    width: 300px;
    color: var(--slate-900);

    &__header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
      @include fontSize('md');
      h3 {
        font-size: inherit;
      }
    }
    &__label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: var(--slate-50);
      border: 1px solid var(--slate-200);
      border-radius: 6px;
      padding: 0.25rem 0.5rem;
      @include fontSize('sm');
      .icon {
        color: var(--green-500);
      }

      .label {
        color: #666;
      }
    }
    &__badge {
      background: #333;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      font-size: 0.875rem;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__content {
    }

    &__footer {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;
      .add-node-btn {
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        justify-content: center;
        @include hover {
          background: var(--slate-50);
          border-color: var(--brand-500);
        }
      }
    }
    .alert {
      margin-top: 1rem;
      font-size: 0.875rem;
      font-style: italic;
      a {
        text-decoration: underline;
      }
    }
  }
</style>
