<template>
  <div class="submission-details">
    <div class="submission-details__metadata">
      <div class="metadata-item">
        <label>Lead ID</label>
        <p>{{ submission.leadId }}</p>
      </div>
      <div class="metadata-item">
        <label>Submitted At</label>
        <p>{{ formatDate(submission.metadata.submittedAt) }}</p>
      </div>
      <div class="metadata-item">
        <label>IP Address</label>
        <p>{{ submission.metadata.ipAddress }}</p>
      </div>
      <div class="metadata-item">
        <label>User Agent</label>
        <p>{{ submission.metadata.userAgent }}</p>
      </div>
    </div>

    <div class="submission-details__data">
      <h3>Form Data</h3>
      <div class="submission-details__fields">
        <div v-for="field in submission.data" :key="field.fieldId" class="field-item">
          <label>{{ field.fieldName }}</label>
          <p>{{ field.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import dayjs from 'dayjs';

  export default defineComponent({
    name: 'SubmissionDetails',

    props: {
      submission: {
        type: Object,
        required: true,
      },
    },

    setup() {
      const formatDate = (date) => {
        return dayjs(date).format('MMM D, YYYY h:mm A');
      };

      return {
        formatDate,
      };
    },
  });
</script>

<style scoped lang="scss">
  .submission-details {
    padding: 1rem;

    &__metadata {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
      margin-bottom: 2rem;

      .metadata-item {
        label {
          font-size: 0.875rem;
          color: var(--slate-600);
          margin-bottom: 0.25rem;
        }

        p {
          font-size: 1rem;
          color: var(--slate-900);
          margin: 0;
        }
      }
    }

    &__data {
      h3 {
        font-size: 1.125rem;
        margin-bottom: 1rem;
      }

      .data-preview {
        background: var(--slate-100);
        border-radius: 0.5rem;
        padding: 1rem;
        overflow: auto;
        max-height: 400px;

        pre {
          margin: 0;
          font-family: monospace;
          font-size: 0.875rem;
        }
      }
    }
  }
</style>
