<template>
<div class="base-settings">
    <TextInput
        :id="localInputs.label.id"
        :label="localInputs.label.label"
        v-model="localInputs.label.binding"
        :error="localInputs.label.error"
        :rules="localInputs.label.rules"
        :realTimeValidation="true"
        @validate="onValidate('label', $event)"
    />

    <ToggleInput
        class="mt-2"
        :id="localInputs.required.id"
        :label="localInputs.required.label"
        v-model="localInputs.required.binding"
        :error="localInputs.required.error"
        :rules="localInputs.required.rules"
        @validate="onValidate('required', $event)"
    />

    <template v-if="'placeholder' in localInputs">
        <TextInput
            :id="localInputs.placeholder.id"
            :label="localInputs.placeholder.label"
            v-model="localInputs.placeholder.binding"
            :error="localInputs.placeholder.error"
            :rules="localInputs.placeholder.rules"
            :realTimeValidation="true"
            @validate="onValidate('placeholder', $event)"
        />
    </template>
    <template v-if="'description' in localInputs">
        <ToggleInput
            class="mt-2"
            id="description-checkbox"
            label="Add Description"
            v-model="showDescription"
        />

        <TextAreaInput
            v-if="showDescription"
            class="mt-2"
            id="description"
            label="Description"
            v-model="localInputs.description.binding"
            :error="localInputs.description.error"
            :rules="localInputs.description.rules"
            :realTimeValidation="true"
            @validate="onValidate('description', $event)"
        />
    </template>

    
    
</div>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import TextInput from "@/components/inputs/TextInput.vue";
import TextAreaInput from "@/components/inputs/TextAreaInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";

export default {
    name: 'BaseSettings',
    components: {
        TextInput,
        TextAreaInput,
        ToggleInput
    },
    props: {
        inputs: Object,
        variant: {
            default: 'default',
            type: String
        }
    },
    setup(props, { emit }) {
        const localInputs = reactive({ ...props.inputs });
        const showDescription = ref(false);

        const onValidate = (field, error) => {
            localInputs[field].error = error;
        };

        // watch(
        //     () => props.inputs,
        //     (newInputs) => {
        //         Object.assign(localInputs, newInputs);
        //     }
        // );
        // Watch for changes in localInputs and emit an update event
        watch(localInputs, (newInputs) => {
            emit('update:inputs', newInputs);
        }, { deep: true });

        return {
            localInputs,
            showDescription,
            onValidate,
        };
    },
};
</script>

<style scoped>
/* .base-settings {
    display: flex;
    flex-direction: column;
} */
</style>