<template>
  <div class="edit-page-form">
    <form action="#" @submit.prevent="">
      <TextInput
        :id="currentPageForm.title.id"
        :label="currentPageForm.title.label"
        v-model="currentPageForm.title.binding"
        :error="currentPageForm.title.error"
        :rules="currentPageForm.title.rules"
        @validate="onValidate('title', $event)"
      />
      <div class="edit-page-form__actions">
        <Btn
          type="submit"
          text="Save!"
          :loading="isLoading"
          :disabled="isDeleting"
          @click.prevent="handleSubmit"
          variant="secondary"
        />
        <Btn
          text="Delete"
          @click="deletePage"
          variant="danger"
          :loading="isDeleting"
          :disabled="isLoading"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { validateInput } from '@/utils/validation';

  export default {
    components: {
      TextInput,
      Btn,
    },
    props: {
      pageIndex: {
        type: Object,
        required: true,
      },
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },
    emits: ['close'],
    setup(props, { emit }) {
      const formStore = useFormStore();
      const currentPageForm = ref(null);
      const isLoading = ref(false);
      const isDeleting = ref(false);

      watch(
        () => props.pageIndex,
        (newIndex) => {
          if (newIndex !== null && formStore.pages[newIndex]) {
            currentPageForm.value = {
              title: {
                binding: formStore.pages[newIndex].title,
                label: 'Step title',
                error: '',
                rules: ['required', 'maxLength'],
              },
            };
          }
        },
        { immediate: true }
      );
      const onValidate = (key, errorMessage) => {
        currentPageForm.value[key].error = errorMessage;
      };

      const validateForm = () => {
        Object.keys(currentPageForm.value).forEach((key) => {
          const input = currentPageForm.value[key];
          input.error = validateInput(input.binding, input.rules);
        });
        return Object.values(currentPageForm.value).every((input) => !input.error);
      };

      const handleSubmit = async () => {
        isLoading.value = true;
        const allFieldsValid = validateForm();

        if (allFieldsValid) {
          const formData = Object.keys(currentPageForm.value).reduce((acc, key) => {
            acc[key] = currentPageForm.value[key].binding;
            return acc;
          }, {});
          saveChanges(formData);
        } else {
          const errors = Object.keys(currentPageForm.value).reduce((acc, key) => {
            if (currentPageForm.value[key].error) {
              acc[key] = currentPageForm.value[key].error;
            }
            isLoading.value = false;
            return acc;
          }, {});
          console.log(errors);
          props.onError(errors);
        }
      };

      const closeModal = () => {
        emit('close');
      };

      // Actions to set the changes in the form store and then submit the form
      const saveChanges = async (data) => {
        try {
          await formStore.updatePage(props.pageIndex, data);
          props.onSubmit();
        } catch (error) {
          console.error('Failed to save changes:', error);
          alert('Failed to save changes');
          isLoading.value = false;
        }
      };

      const deletePage = async () => {
        isDeleting.value = true;
        setTimeout(async () => {
          try {
            await formStore.removePage(props.pageIndex);
            props.onSubmit();
          } catch (error) {
            console.error('Failed to delete step:', error);
            alert('Failed to delete step');
            isDeleting.value = false;
          }
        }, 2000);
      };

      return {
        currentPageForm,
        onValidate,
        closeModal,
        saveChanges,
        deletePage,
        handleSubmit,
        isLoading,
        isDeleting,
      };
    },
  };
</script>

<style scoped lang="scss">
  .edit-page-form {
    &__actions {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
