<template>
  <div class="single-choice-settings">
    <TextInput
      :id="inputs.label.id"
      :label="inputs.label.label"
      v-model="inputs.label.binding"
      :error="inputs.label.error"
      :rules="inputs.label.rules"
      @validate="onValidate('label', $event)"
    />

    <CheckboxInput
      class="mt-4"
      :id="inputs.required.id"
      :label="inputs.required.label"
      v-model="inputs.required.binding"
      :error="inputs.required.error"
      :rules="inputs.required.rules"
      :realTimeValidation="true"
      @validate="onValidate('required', $event)"
    />

    <ChoiceList
      class="mt-4"
      v-model="inputs.choices.binding"
      @validate="onValidate('choices', $event)"
    />

    <TextInput
      class="mt-4"
      :id="inputs.per_row.id"
      :label="inputs.per_row.label"
      v-model="inputs.per_row.binding"
      :error="inputs.per_row.error"
      :rules="inputs.per_row.rules"
      @validate="onValidate('per_row', $event)"
      type="number"
      :min="1"
    />
    <!-- <SelectInput
    class="mt-4"
      :id="inputs.logic.id"
      :label="inputs.logic.label"
      v-model="inputs.logic.binding"
      :error="inputs.logic.error"
      :rules="inputs.logic.rules"
      :options="[
        { value: 'no_click_behavior', text: 'No Click Behavior' },
        { value: 'next_page', text: 'Continue to Next Page' },
        { value: 'specific_page', text: 'Continue to Specific Page' }
      ]"
      :realTimeValidation="false"
      @validate="onValidate('logic', $event)"
    /> -->
  </div>
</template>

<script>
  import { watch } from 'vue';
  import { debounce } from 'lodash';

  import TextInput from '@/components/inputs/TextInput.vue';
  import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
  // import SelectInput from "@/components/inputs/SelectInput.vue";
  import ChoiceList from '@/components/inputs/ChoiceList.vue';

  import { useValidation } from '@/composables/useValidation';
  import { uid } from 'uid';

  export default {
    components: {
      TextInput,
      CheckboxInput,
      // SelectInput,
      ChoiceList,
    },
    props: {
      element: {
        type: Object,
        required: true,
      },
    },
    emits: ['save-settings'],
    setup(props, { emit }) {
      // Combine localSettings and inputs into a single structure using useValidation
      const { inputs, validateForm, onValidate, markTouched } = useValidation({
        label: {
          id: 'label',
          label: 'Label',
          binding: props.element.props.label || '',
          error: '',
          rules: ['required', 'maxLength:255'],
          touched: false,
        },
        required: {
          id: 'required',
          label: 'Answer is required',
          binding: props.element.props.required || false,
          error: '',
          touched: false,
        },
        choices: {
          id: 'choices',
          binding: props.element.props.choices || [
            {
              id: uid(),
              content: '',
              image: '',
              logic: '',
              error: '',
            },
          ],
        },
        per_row: {
          id: 'per_row',
          label: 'Amount of answers per row',
          binding: props.element.props.per_row || 1,
          error: '',
          rules: ['required', 'min:1', 'max:4'],
          touched: false,
        },
        logic: {
          id: 'logic',
          label: 'Logic',
          binding: props.element.props.logic || 'no_click_behavior',
          rules: ['required'],
        },
      });

      let programmaticUpdate = false;

      const saveDebounced = debounce((newInputs) => {
        if (!programmaticUpdate) {
          // Validate all inputs before saving
          const allValid = validateForm();
          Object.keys(inputs.value).forEach((key) => markTouched(key));

          if (allValid) {
            const newSettings = Object.keys(newInputs).reduce((acc, key) => {
              acc[key] = newInputs[key].binding;
              return acc;
            }, {});
            emit('save-settings', newSettings);
          } else {
            console.warn('Validation failed. Fix errors before saving.');
          }
        }
      }, 300);

      const saveSettings = () => {
        saveDebounced(inputs.value);
      };
      // Watch for changes in inputs and emit save event
      watch(
        inputs,
        (newInputs) => {
          saveDebounced(newInputs);
        },
        { deep: true }
      );

      // Sync inputs with prop changes
      watch(props.element, (newElement) => {
        programmaticUpdate = true;
        Object.keys(inputs.value).forEach((key) => {
          if (newElement.props[key] !== undefined) {
            inputs.value[key].binding = newElement.props[key];
          }
        });
        programmaticUpdate = false;
      });

      // Handle adding and removing answers
      const addAnswer = () => {
        inputs.choices.binding.push('');
      };

      const removeAnswer = (index) => {
        inputs.choices.binding.splice(index, 1);
      };

      return {
        inputs,
        onValidate,
        saveSettings,
        validateForm,
        addAnswer,
        removeAnswer,
      };
    },
  };
</script>

<style scoped>
  .single-choice-settings {
    display: flex;
    flex-direction: column;
  }

  .single-choice-settings label {
    margin-top: 10px;
    font-weight: bold;
  }

  .answer-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .answer-item textarea {
    flex: 1;
  }

  .answer-item button {
    margin-left: 10px;
  }

  button {
    margin-top: 10px;
    padding: 10px;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #36a572;
  }
</style>
