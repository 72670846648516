<template>
  <div class="flow-editor">
    <div v-if="form" class="flow-editor__inner">
      <SidebarGeneral
        :form="form"
        :activePageIndex="activePageIndex"
        @add-page="addPage"
        @remove-page="removePage"
        @edit-page-settings="editPageSettings"
        @set-active-page="setActivePage"
      />
      <FormEditor
        :activePage="activePage"
        :activeElementIndex="activeElementIndex"
        @set-active-element="setActiveElement"
        @remove-element="removeElement"
        @add-new-input="setActiveElement(null)"
        @add-page="addPage"
      />
      <SidebarElements :activePage="activePage" :activeElementIndex="activeElementIndex" />
    </div>
    <div v-else class="flow-editor__loading">Loading...</div>
  </div>
</template>

<script>
  import { ref, watchEffect, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';

  import SidebarGeneral from '@/components/editor/sidebar/general/SidebarGeneral.vue';
  import FormEditor from '@/components/editor/FormEditor.vue';
  import SidebarElements from '@/components/editor/sidebar/elements/SidebarElements.vue';

  export default {
    components: {
      SidebarGeneral,
      FormEditor,
      SidebarElements,
    },
    setup() {
      const route = useRoute();
      const formStore = useFormStore();
      const form = ref(null);

      watchEffect(() => {
        if (route.params.id) {
          formStore.loadForm(route.params.id).then(() => {
            form.value = formStore.currentForm;
            if (form.value.pages.length > 0) {
              formStore.setActivePage(0); // Set the first page as active by default
            }
          });
        }
      });

      const activePage = computed(() => formStore.activePage);
      const activePageIndex = computed(() => formStore.activePageIndex);
      const activeElementIndex = computed(() => formStore.activeElementIndex);

      const addPage = async () => {
        const response = await formStore.addPage();
        if (response.status === 'success') {
          if (form.value.pages.length === 1) {
            formStore.setActivePage(0); // Set the first page as active if it's the only page
          }
        } else {
          console.error('Failed to add page:', response.error);
          alert('Failed to add page');
        }
      };

      const setActivePage = (pageIndex) => {
        formStore.setActivePage(pageIndex);
      };

      // When selecting an element
      const setActiveElement = (blockIndex, elementIndex) => {
        formStore.setActiveElement(activePageIndex.value, blockIndex, elementIndex);
      };

      const removeElement = async (blockIndex, elementIndex) => {
        await formStore.removeElement(blockIndex, elementIndex);
      };

      return {
        form,

        activePage,
        activePageIndex,
        activeElementIndex,
        removeElement,
        setActiveElement,
        addPage,
        setActivePage,
      };
    },
  };
</script>

<style scoped lang="scss">
  .flow-editor {
    // display: flex;
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    &__inner {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
</style>
