<template>
  <div class="empty-state">
    <div class="empty-state__inner">
      <div v-if="icon" class="empty-state__icon">
        <BaseIcon :name="icon" size="48" />
      </div>
      <h3 class="empty-state__title">{{ title }}</h3>
      <p class="empty-state__description">{{ description }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/partials/BaseIcon.vue';

  export default {
    name: 'EmptyState',
    components: {
      BaseIcon,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .empty-state {
    padding: 2rem;
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1rem;
      padding: 2rem;
      border: 1px dashed var(--slate-200);
      border-radius: 0.5rem;
      background-color: var(--slate-50);
    }
    &__icon {
      color: var(--slate-400);
      width: 96px;
      height: 96px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--slate-200);
    }

    &__title {
      font-size: 1.125rem;
      font-weight: 500;
      color: var(--slate-900);
    }

    &__description {
      @include fontSize('md');
      color: var(--slate-500);
      max-width: 24rem;
      margin: 0 auto;
    }
  }
</style>
