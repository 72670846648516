<template>
  <div class="flow">
    <Toolbar :formName="formName" />
    <router-view />
  </div>
</template>

<script>
  import { watchEffect, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import Toolbar from '@/components/editor/EditorToolbar.vue';

  export default {
    components: { Toolbar },
    setup() {
      const route = useRoute();
      const formStore = useFormStore();

      watchEffect(() => {
        if (route.params.id) {
          formStore.loadForm(route.params.id);
        }
      });

      const formName = computed(() => (formStore.currentForm ? formStore.currentForm.name : ''));

      return {
        formName,
      };
    },
  };
</script>

<style scoped lang="scss">
  .flow {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 0;
    // overflow: hidden;
  }
</style>
