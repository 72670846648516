<template>
  <div class="form-page">
    <h2 v-if="formSettings?.displayPageTitle" class="form-page__title">{{ page.title }}</h2>
    <FormBlock
      v-if="page.blocks[currentBlockIndex] && isCurrentBlockVisible"
      :key="page.blocks[currentBlockIndex].id"
      :block="page.blocks[currentBlockIndex]"
      :formData="formData"
      :errors="
        Object.fromEntries(
          Object.entries(errors).filter(([id]) =>
            page.blocks[currentBlockIndex].elements.some((element) => element.id === id)
          )
        )
      "
      :formSettings="formSettings"
      @update:formData="$emit('update:formData', $event)"
    />
  </div>
</template>

<script>
  import FormBlock from './FormBlock.vue';

  export default {
    name: 'FormPage',
    components: {
      FormBlock,
    },
    props: {
      page: {
        type: Object,
        required: true,
      },
      formData: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      touched: {
        type: Object,
        default: () => ({}),
      },
      formSettings: {
        type: Object,
        default: null,
      },
      currentBlockIndex: {
        type: Number,
        required: true,
      },
      isCurrentBlockVisible: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['update:formData', 'field-blur'],
  };
</script>

<style scoped lang="scss">
  .form-page {
    margin-bottom: 2rem;

    &__title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
</style>
